import {
  Alert,
  type AlertProps,
  AlertTitle,
  Box,
  type BoxProps,
  Snackbar,
  type SnackbarOrigin
} from '@mui/material';
import React, {
  Component,
  type JSX,
  type ReactElement,
  type ReactNode
} from 'react';

type NotificationContextProps = {
  children: ReactNode[] | ReactNode;
};

type Severity = AlertProps['severity'];

type NotificationOptions = {
  title?: string;
  placement?: SnackbarOrigin;
  maxWidth?: BoxProps['maxWidth'];
  duration?: number;
};

export type NotificationContextState = {
  showNotification: boolean;
  notification: ReactElement | string;
  renderNotification: (
    severity: Severity,
    notification: ReactElement | string,
    options?: NotificationOptions
  ) => void;
  resetState: () => void;
  severity?: Severity;
  options?: NotificationOptions;
};

const initialState: NotificationContextState = {
  showNotification: false,
  notification: null,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: initial state
  renderNotification: () => {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: initial state
  resetState: () => {},
  options: {
    placement: { vertical: 'bottom', horizontal: 'right' },
    duration: 6000,
    maxWidth: '100%'
  }
};

export const NotificationContext = React.createContext(initialState);

/**
 * Provides a context for rendering notifications in the form of alerts in a
 * snackbar.
 */
export class NotificationProvider extends Component<
  NotificationContextProps,
  NotificationContextState
> {
  readonly state: NotificationContextState = {
    ...initialState,
    renderNotification: (severity, notification, options = {}) => {
      this.setState(() => ({
        showNotification: true,
        notification,
        severity,
        options
      }));
    },
    resetState: () => {
      this.setState(() => ({
        notification: initialState.notification,
        showNotification: initialState.showNotification,
        severity: undefined
      }));
    }
  };

  render(): JSX.Element {
    return (
      <>
        <NotificationContext.Provider value={this.state}>
          {this.props.children}
        </NotificationContext.Provider>
        <Snackbar
          autoHideDuration={this.state.options?.duration}
          anchorOrigin={this.state.options?.placement}
          open={this.state.showNotification}
          onClose={this.state.resetState}
        >
          {this.state.notification && (
            <Alert
              elevation={6}
              variant="outlined"
              sx={{ backgroundColor: 'white' }}
              severity={this.state.severity}
              onClose={this.state.resetState}
            >
              {this.state.options?.title && (
                <AlertTitle>{this.state.options?.title}</AlertTitle>
              )}
              <Box maxWidth={this.state.options?.maxWidth}>
                {this.state.notification}
              </Box>
            </Alert>
          )}
        </Snackbar>
      </>
    );
  }
}
