import { Box, Button } from '@mui/material';
import { Component } from 'react';
import {
  createSupportEmailUrl,
  markdownCodeBlock
} from '../../helpers/link.helper';
import { logError } from '../../helpers/logger.helper';
import { getTenantName } from '../../helpers/next.helpers';
import { DebugIcon } from '../../icons/icons';
import { CodeSnippet } from '../code-snippet/code-snippet.component';
import { CenteredBox } from '../styled-components/layouts/layouts.styled';
import {
  ErrorBoundaryProps,
  ErrorBoundaryState
} from '../utilities/error-boundary/page-error-boundary.component';

export class OrganizationsTableErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: { message: null, stack: null }
    };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render shows the fallback UI
    return {
      hasError: true
    };
  }

  componentDidCatch(
    error: Error | { name: string; message: string },
    errorInfo: never
  ): void {
    // You can also log the error to an error reporting service
    this.setState({
      hasError: true,
      error: typeof error === 'string' ? { message: error, stack: '' } : error
    });
    logError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <CenteredBox
          sx={{
            flexDirection: 'column'
          }}
        >
          <CodeSnippet copyButtonPlacement="top" color="light">
            {this.state.error.stack}
          </CodeSnippet>
          <Box
            sx={{
              my: 2
            }}
          >
            <Button
              href={createSupportEmailUrl({
                subject: `[🐛][${getTenantName()}] - ${this.state.error.message}`,
                body:
                  (this.state.error.stack
                    ? markdownCodeBlock(this.state.error.stack)
                    : '') +
                  '\n\n' +
                  window.location.href
              })}
              variant="contained"
              color="primary"
              startIcon={<DebugIcon />}
            >
              Report a bug
            </Button>
          </Box>
        </CenteredBox>
      );
    }
    return this.props.children;
  }
}
