import React, { Component, type ReactNode } from 'react';
import type {
  ErrorBoundaryProps,
  ErrorBoundaryState
} from '../error-boundary/page-error-boundary.component';

/**
 * If the markdown component throws an error, this boundary will catch it and
 * rerender the component but disabling html rendering in markdown.  Intended
 * to immediately wrap the <Markdown /> component from 'markdown-to-jsx'
 */
export class MarkdownErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  render(): ReactNode {
    if (this.state.hasError) {
      const fallback = React.cloneElement(this.props.children, {
        options: {
          disableParsingRawHTML: true,
          overrides: this.props.children.props.options.overrides
        }
      });
      return fallback;
    }

    return this.props.children;
  }
}
