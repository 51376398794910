import { type Theme, useMediaQuery } from '@mui/material';

type CommonBreakpoints = {
  // Small devices only (SM down)
  isMobileDevice: boolean;
  // Generally tablet & mobile  (MD down)
  isNarrowDevice: boolean;
  // Small devices only (LG down)
  isMobileOrTablet: boolean;
  // Large devices only (MD up)
  isLargeDevice: boolean;
  // Touch device
  isTouchDevice: boolean;
  isPortrait: boolean;
};
export const useCommonBreakpoints = (): CommonBreakpoints => {
  // |---- xs ---> sm (not including end point)
  const isMobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  // |---- xs ---- sm ---> md (not including end point)
  const isNarrowDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  // |---- xs ---- sm ---- md ---> lg (not including end point)
  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  // (includes end point) md ---- lg ---- xl ---> infinity
  const isLargeDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  // method that will check to see if the browser supports touch devices
  const isTouchDevice =
    'ontouchstart' in window || navigator.maxTouchPoints > 0;

  const isPortrait = useMediaQuery('(orientation: portrait)');

  return {
    isMobileDevice,
    isNarrowDevice,
    isMobileOrTablet,
    isLargeDevice,
    isTouchDevice,
    isPortrait
  };
};
