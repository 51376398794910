import { type FunctionComponent, useState } from 'react';

import { Button, type ButtonProps, IconButton } from '@mui/material';

import { ClipBoardIcon } from './clip-board-icon.component';
import { CopyToClipboardWithTooltip } from './copy-to-clipboard-with-tooltip.component';

export type CopyPastaProps =
  | {
      buttonGroupCompatible?: boolean;
      buttonProps?: ButtonProps;
      buttonText?: string;
      onCopy?: () => void;
      text: string;
      getText?: undefined;
      tooltip?: string;
    }
  | {
      buttonGroupCompatible?: boolean;
      buttonProps?: ButtonProps;
      buttonText?: string;
      onCopy?: () => void;
      text?: undefined;
      getText: () => string;
      tooltip?: string;
    };

export const CopyPasta: FunctionComponent<CopyPastaProps> = ({
  buttonProps = null,
  buttonText = null,
  buttonGroupCompatible = false,
  onCopy = undefined,
  text,
  getText,
  tooltip = 'Copy to clipboard',
  ...rest
}) => {
  const [copied, setCopied] = useState(false);
  const handleCopied = () => {
    if (onCopy) {
      onCopy();
    }
    setCopied(true);
    setTimeout(() => setCopied(false), 1600);
  };
  const testId = 'copy-button';

  if (!text && !getText) {
    return null;
  }

  const copyText = text || getText();

  if (buttonText) {
    return (
      <CopyToClipboardWithTooltip
        text={copyText}
        onCopy={handleCopied}
        copied={copied}
        tooltip={tooltip}
      >
        <Button
          data-testid={testId}
          {...buttonProps}
          {...rest}
          startIcon={<ClipBoardIcon isCopied={copied} />}
        >
          {buttonText}
        </Button>
      </CopyToClipboardWithTooltip>
    );
  }

  return (
    <CopyToClipboardWithTooltip
      text={copyText}
      onCopy={handleCopied}
      copied={copied}
      tooltip={tooltip}
    >
      {buttonGroupCompatible ? (
        <Button data-testid={testId} {...buttonProps} {...rest}>
          <ClipBoardIcon isCopied={copied} />
        </Button>
      ) : (
        <IconButton
          data-testid={testId}
          size="small"
          style={{
            color: 'inherit',
            backgroundColor: 'transparent'
          }}
          {...buttonProps}
        >
          <ClipBoardIcon isCopied={copied} />
        </IconButton>
      )}
    </CopyToClipboardWithTooltip>
  );
};
