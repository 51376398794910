import { useRouter } from 'next/router';

import type { ApolloError } from '@apollo/client';
import type {
  Base64EncodedString,
  FunctionComponentWithChildren
} from '@bonsai-components/utility-types';

import { useCallback, useContext, useEffect } from 'react';
import {
  useExchangeAzureDevOpsAuthorizationCodeMutation,
  useExchangeBitbucketAuthorizationVerifierMutation,
  useExchangeBitbucketCloudAuthorizationCodeMutation,
  useExchangeGitLabAuthorizationCodeMutation,
  useExchangeGithubAuthorizationCodeMutation
} from '../../../__generated__/apollo-hooks';
import { INVALID_TOKEN_ERROR_PATH } from '../../../constants/general';
import { NotificationContext } from '../../../contexts/notification.context';
import type { OAuthState } from '../../../helpers/authorization.helper';
import {
  decodeJSONBase64,
  fromBase64,
  toBase64
} from '../../../helpers/encoding.helper';
import { cleanUrlParams } from '../../../helpers/link.helper';
import { FullHeightBox } from '../../styled-components/layouts/layouts.styled';
import { Loading } from '../loading/loading.component';

export const AuthorizationControl: FunctionComponentWithChildren = ({
  children
}) => {
  const router = useRouter();
  const { query } = router;
  const { error } = router.query;
  const { renderNotification } = useContext(NotificationContext);
  const [exchangeGitLabCode] = useExchangeGitLabAuthorizationCodeMutation();
  const [exchangeGitHubCode] = useExchangeGithubAuthorizationCodeMutation();
  const [exchangeBitbucketCloudCode] =
    useExchangeBitbucketCloudAuthorizationCodeMutation();
  const [exchangeBitBucketCode] =
    useExchangeBitbucketAuthorizationVerifierMutation();
  const [exchangeAzureDevOpsCode] =
    useExchangeAzureDevOpsAuthorizationCodeMutation();

  const handleError = useCallback(
    (error: ApolloError) => {
      const urlParams = new URLSearchParams(cleanUrlParams());
      urlParams.set('error', toBase64(error.message));
      router.replace(
        {
          pathname: window.location.pathname,
          query: urlParams.toString()
        },
        undefined,
        { shallow: true }
      );
    },
    [router]
  );

  useEffect(() => {
    if (error) {
      renderNotification('error', fromBase64(error as Base64EncodedString), {
        duration: null
      });
    }
  }, [error, renderNotification]);

  useEffect(() => {
    if (query.code && query.state) {
      const oauthState = decodeJSONBase64<OAuthState>(
        query.state as Base64EncodedString
      );

      const { redirectUri, origin, provider } = oauthState;

      if (provider === 'azure-devops') {
        exchangeAzureDevOpsCode({
          variables: {
            origin,
            code: String(query.code),
            redirectUri: window.location.origin,
            clientId: oauthState.clientId,
            tenantId: oauthState.tenantId
          }
        })
          .then(() => router.replace(redirectUri))
          .catch(handleError);
      } else if (provider === 'gitlab') {
        exchangeGitLabCode({
          variables: {
            code: String(query.code),
            // GitLab does not accept wildcard redirect URIs beyond the TLD
            redirectUri: window.location.origin,
            codeVerifier: oauthState.codeVerifier,
            origin
          }
        })
          .then(() => router.replace(redirectUri))
          .catch(handleError);
      } else if (provider === 'github') {
        exchangeGitHubCode({
          variables: {
            code: String(query.code),
            redirectUri: window.location.origin,
            origin: String(query.origin)
          }
        })
          .then(({ data }) => {
            if (data?.exchangeScmCode) {
              const params = cleanUrlParams();
              router.replace(
                window.location.origin +
                  window.location.pathname +
                  (params ? `?${params}` : '')
              );
            } else {
              router.replace(INVALID_TOKEN_ERROR_PATH);
            }
          })
          .catch(handleError);
      } else if (provider === 'bitbucket-cloud') {
        exchangeBitbucketCloudCode({
          variables: {
            code: String(query.code),
            redirectUri: window.location.origin,
            origin: origin
          }
        })
          .then(() => router.replace(redirectUri))
          .catch(handleError);
      }
    } else if (query.oauth_token && query.oauth_verifier && query.origin) {
      exchangeBitBucketCode({
        variables: {
          origin: String(query.origin),
          requestToken: String(query.oauth_token),
          verifier: String(query.oauth_verifier)
        }
      })
        .then(({ data }) => {
          if (data?.exchangeScmCode) {
            const params = cleanUrlParams();
            router.replace(
              window.location.origin +
                window.location.pathname +
                (params ? `?${params}` : '')
            );
          } else {
            router.replace(INVALID_TOKEN_ERROR_PATH);
          }
        })
        .catch(handleError);
    }
  }, [
    exchangeAzureDevOpsCode,
    exchangeBitBucketCode,
    exchangeBitbucketCloudCode,
    exchangeGitHubCode,
    exchangeGitLabCode,
    handleError,
    query,
    router
  ]);

  // if (error) {
  //   return (
  //     <React.Fragment>
  //       <FlexBox>
  //         <Layout title="Authorization failed" isLoading={false}>
  //           <ModerneGraphQLError error={error} />
  //         </Layout>
  //       </FlexBox>
  //     </React.Fragment>
  //   );
  // } else
  if (query.code || (query.oauth_token && query.oauth_verifier)) {
    return (
      <FullHeightBox
        sx={{
          my: 4
        }}
      >
        <Loading caption="Authorizing" />
      </FullHeightBox>
    );
  } else {
    return <>{children}</>;
  }
};
