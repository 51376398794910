import type { useLazyQuery } from '@apollo/client';
import type { PureFunction } from '@bonsai-components/utility-types';
import React, { Component, type JSX, type ReactNode } from 'react';
import type { Job } from '../@types/repository-groups';
import type {
  CurrentRecipeJarsQuery,
  RecipeDeploymentState,
  RecipeRunResultsNotificationQuery,
  RecipeRunState,
  VisualizationDeploymentState,
  VisualizationDeploymentsQuery,
  VisualizationRunDetailQuery,
  VisualizationRunState
} from '../__generated__/apollo-hooks';
import { PollingJob } from '../components/polling-job/polling-job.component';
import { db } from '../helpers/indexedDb.helper';
import { logError } from '../helpers/logger.helper';
import { canShowNotification } from '../helpers/notification.helper';
import { type UUID, uuid } from '../helpers/uuid.helper';

type JobNotificationContextProps = {
  children: ReactNode[] | ReactNode;
};

type JobManifest = {
  message: string;
  terminalFunctionKey: string;
  polledQueryParameters: Parameters<typeof useLazyQuery>;
  url?: string;
};

type TerminalFunctions = {
  [key: string]: PureFunction<unknown, boolean>;
};

const terminalRecipeRunStates: RecipeRunState[] = [
  'FINISHED',
  'CANCELED',
  'AVAILABLE'
];

export const terminalFunctions: TerminalFunctions = {
  CurrentRecipeJarsQuery: (data: CurrentRecipeJarsQuery) => {
    const terminalRecipeJarStates: RecipeDeploymentState[] = [
      'FINISHED',
      'ERROR'
    ];
    return data?.latestRecipeDeployments.every((d) =>
      terminalRecipeJarStates.includes(d.state)
    );
  },
  RecipeRunResultsNotificationQuery: (
    data: RecipeRunResultsNotificationQuery
  ) => {
    return terminalRecipeRunStates.includes(data?.recipeRun?.state);
  },
  VisualizationRunDetailQuery: (data: VisualizationRunDetailQuery) => {
    const terminalVisualizationRunStates: VisualizationRunState[] = [
      'FINISHED',
      'FINISHED_EMPTY',
      'ERROR',
      'CANCELED'
    ];
    return terminalVisualizationRunStates.includes(
      data?.visualizationRun?.state
    );
  },
  VisualizationDeploymentsQuery: (data: VisualizationDeploymentsQuery) => {
    const terminalStates: VisualizationDeploymentState[] = [
      'FAILED',
      'SUCCESSFUL'
    ];
    return data?.visualizationDeployments.every((d) =>
      terminalStates.includes(d.state)
    );
  }
};

export type JobManifestRecord = {
  id: UUID;
} & JobManifest;

type JobNotificationContextState = {
  jobs: JobManifestRecord[];
  addJob: (Job: JobManifest) => Promise<UUID>;
  removeJob: (id: UUID) => void;
};

const initialState: JobNotificationContextState = {
  jobs: [],
  addJob: async () => '' as UUID,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: initial state
  removeJob: () => {}
};

export const JobNotificationContext = React.createContext(initialState);

/**
 * Provides a context for rendering notifications in the form of native browser
 * push notifications. The initial intent is that longer jobs that require us
 * to poll a particular query will be able to notify the user when the query has
 * reached a terminal state.
 */
export class JobNotificationProvider extends Component<
  JobNotificationContextProps,
  JobNotificationContextState
> {
  readonly state: JobNotificationContextState = {
    ...initialState,
    addJob: async (newJob: JobManifest) => {
      if (!terminalFunctions[newJob.terminalFunctionKey]) {
        logError(
          new Error(
            `No terminal function found for ${newJob.terminalFunctionKey}.`
          )
        );
        return null;
      }

      const canSend = await canShowNotification();
      if (!canSend) {
        return null;
      }
      const jobId = uuid();
      const record: JobManifestRecord = { id: jobId, ...newJob };
      this.setState((prevState) => ({
        jobs: [...prevState.jobs, record]
      }));
      return jobId;
    },
    removeJob: (jobId: UUID) => {
      this.setState((prevState) => ({
        jobs: prevState.jobs.filter((d) => d.id !== jobId)
      }));
    }
  };

  async componentDidMount(): Promise<void> {
    const jobsFromIndexedDB = await this.getJobsFromIndexedDB();

    this.setState(() => ({
      jobs: jobsFromIndexedDB
    }));
  }

  /**
   * On update we want the jobs state to be reflected in indexedDB
   */
  async componentDidUpdate(_prevProps, prevState): Promise<void> {
    if (prevState.jobs !== this.state.jobs) {
      await this.updateIndexedDB();
    }
  }

  private async updateIndexedDB(): Promise<void> {
    await db.jobs.clear();
    await db.jobs.bulkAdd(this.state.jobs.map(this.mapJobManifestRecordToJob));
  }

  private async getJobsFromIndexedDB(): Promise<JobManifestRecord[]> {
    // get all the jobs from indexedDB
    const jobs = await db.jobs.toArray();
    return jobs?.map(this.mapJobToJobManifestRecord);
  }

  private mapJobManifestRecordToJob = (job: JobManifestRecord): Job => ({
    id: job.id,
    message: job.message,
    terminalFunctionKey: job.terminalFunctionKey,
    polledQueryParameters: JSON.stringify(job.polledQueryParameters),
    url: job.url
  });

  private mapJobToJobManifestRecord = (job: Job): JobManifestRecord => ({
    id: job.id,
    message: job.message,
    terminalFunctionKey: job.terminalFunctionKey,
    polledQueryParameters: this.parseParameters(job.polledQueryParameters),
    url: job.url
  });

  private parseParameters(
    parameters: string
  ): JobManifestRecord['polledQueryParameters'] {
    try {
      return JSON.parse(parameters);
    } catch {
      return undefined;
    }
  }

  render(): JSX.Element {
    return (
      <>
        <JobNotificationContext.Provider value={this.state}>
          {window?.Notification?.permission === 'granted' &&
            this.state.jobs.map((job) => <PollingJob key={job.id} job={job} />)}
          {this.props.children}
        </JobNotificationContext.Provider>
      </>
    );
  }
}
