import type { FunctionComponent } from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import {
  type RepositoryFragment,
  type ScmConfiguration,
  isRepositoryAAzureDevOpsRepository,
  isRepositoryABitbucketCloudRepository,
  isRepositoryABitbucketRepository,
  isRepositoryAGitHubRepository,
  isRepositoryAGitLabRepository,
  isScmConfigurationAAzureDevOpsConfiguration,
  isScmConfigurationABitbucketCloudConfiguration,
  isScmConfigurationABitbucketConfiguration,
  isScmConfigurationAGitLabConfiguration,
  isScmConfigurationAGithubConfiguration
} from '../../../__generated__/apollo-hooks';
import type { WorkerRepositoryFragment } from '../../../__generated__/apollo-hooks-worker';
import {
  AzureDevOpsIcon,
  BitBucketIcon,
  GitHubIcon,
  GitLabIcon
} from '../../../icons/icons';

export type PossibleScmConfiguration = Pick<
  ScmConfiguration | RepositoryFragment | WorkerRepositoryFragment,
  '__typename'
>;

type ScmIconProps = {
  configuration: PossibleScmConfiguration;
  SvgIconProps?: SvgIconProps;
  overrideIconColor?: boolean;
};

export const ScmConfigurationIcon: FunctionComponent<ScmIconProps> = ({
  configuration,
  SvgIconProps = {},
  overrideIconColor
}) => {
  const sx = SvgIconProps?.sx || {};
  const iconColor =
    overrideIconColor && sx['color'] ? sx['color'] : 'common.black';

  switch (true) {
    case isScmConfigurationAGithubConfiguration(configuration):
    case isRepositoryAGitHubRepository(configuration):
      return (
        <GitHubIcon
          inheritViewBox
          {...SvgIconProps}
          sx={{ ...sx, color: iconColor }}
        />
      );
    case isScmConfigurationABitbucketConfiguration(configuration):
    case isScmConfigurationABitbucketCloudConfiguration(configuration):
    case isRepositoryABitbucketRepository(configuration):
    case isRepositoryABitbucketCloudRepository(configuration):
      return (
        <BitBucketIcon
          inheritViewBox
          {...SvgIconProps}
          sx={{ ...sx, color: iconColor }}
        />
      );
    case isScmConfigurationAGitLabConfiguration(configuration):
    case isRepositoryAGitLabRepository(configuration):
      return (
        <GitLabIcon
          inheritViewBox
          {...SvgIconProps}
          sx={{ ...sx, color: iconColor }}
        />
      );
    case isScmConfigurationAAzureDevOpsConfiguration(configuration):
    case isRepositoryAAzureDevOpsRepository(configuration):
      return (
        <SvgIcon
          inheritViewBox
          {...SvgIconProps}
          sx={{ ...sx, color: iconColor }}
        >
          <AzureDevOpsIcon />
        </SvgIcon>
      );
    default:
      return null;
  }
};
