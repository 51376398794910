import Head from 'next/head';
import { type FunctionComponent, useContext } from 'react';
import { SITE_TAG_LINE, SITE_TITLE } from '../../constants/general';
import { ConfigurationContext } from '../../contexts/config.context';
import { capitalize } from '../../helpers/string.helper';

export const SiteMetaData: FunctionComponent = () => {
  const { config } = useContext(ConfigurationContext);
  const getTitle = () => `Moderne 
  ${
    config?.name && config.name.toLowerCase() !== 'app'
      ? `& ${capitalize(config?.name)}`
      : ''
  } -
  Mass Auto-Fixing for Your Code`;

  return (
    <Head>
      <link
        rel="icon"
        href="/favicon.ico"
        type="image/x-icon"
        sizes="32x32"
        media="(prefers-color-scheme: light)"
      />
      <link
        rel="icon"
        href="/favicon_dark.ico"
        type="image/x-icon"
        sizes="32x32"
        media="(prefers-color-scheme: dark)"
      />
      {/**
       * Mobile / Tablet Icons
       */}
      <link
        rel="icon"
        href="/favicon/favicon_android_192.png"
        type="image/png"
        sizes="192x192"
      />
      <link
        rel="apple-touch-icon"
        href="/favicon/favicon_apple_touch_180.png"
      />

      <title>{getTitle()}</title>
      <meta name="description" content={SITE_TAG_LINE} />
      <meta name="robots" content="index, nofollow" />
      {/* open graph */}
      <meta property="og:title" content={SITE_TITLE} />
      <meta property="og:description" content={SITE_TAG_LINE} />
      <meta property="og:type" content="article" />
      <meta
        property="og:image"
        content="https://app.moderne.io/moderne-og.png"
      />
      <meta property="og:url" content="public.moderne.io" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="video.other" />
      <meta
        property="og:video:url"
        content="https://www.youtube.com/embed/BCWyMm7WFCI"
      />
      <meta
        property="og:video:secure_url"
        content="https://www.youtube.com/embed/BCWyMm7WFCI"
      />
      <meta property="og:video:type" content="text/html" />
      <meta property="og:video:width" content="1280" />
      <meta property="og:video:height" content="720" />

      {/* twitter crawler */}
      <meta name="twitter:card" content="player" />
      <meta name="twitter:site" content="@moderneinc" />
      <meta name="twitter:title" content={SITE_TITLE} />
      <meta name="twitter:description" content={SITE_TAG_LINE} />
      <meta
        name="twitter:image"
        content="https://app.moderne.io/moderne-og.png"
      />
      <meta
        name="twitter:player"
        content="https://www.youtube.com/embed/BCWyMm7WFCI"
      />
      <meta name="twitter:player:width" content="1280" />
      <meta name="twitter:player:height" content="720" />
    </Head>
  );
};
