import { useGridApiContext } from '@mui/x-data-grid-pro';
import type { FunctionComponent } from 'react';
import {
  OrganizationSelectorGridDocument,
  type OrganizationSelectorGridQueryVariables
} from '../../__generated__/apollo-hooks';
import { SeeHowToRunGraphQL } from '../graphql-explorer/see-how-to-run-graphql.component';

export const OrganizationsApiExample: FunctionComponent = () => {
  const { current } = useGridApiContext();

  const query =
    current.state.filter?.filterModel?.quickFilterValues.join(' ') || undefined;

  return (
    <SeeHowToRunGraphQL<OrganizationSelectorGridQueryVariables>
      examples={[
        {
          label: 'Organizations',
          operationDocument: OrganizationSelectorGridDocument,
          operationVariables: {
            search: query
          }
        }
      ]}
    />
  );
};
