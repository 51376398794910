import { Box } from '@mui/material';
import { type FunctionComponent } from 'react';

import { type ModerneTenantConfiguration } from '../../../contexts/config.context';

type CustomerBrandingProps = {
  config?: ModerneTenantConfiguration;
};

export const CustomerBranding: FunctionComponent<CustomerBrandingProps> = ({
  config = {}
}) => {
  if (!config?.brandingIcon) {
    return null;
  }

  return (
    <Box
      sx={{
        border: `none`,
        borderRadius: '4px',
        boxSizing: 'border-box',
        alignSelf: 'center',
        overflow: 'hidden',
        display: 'inline-flex',
        height: 60,
        width: 60,
        maxWidth: 60,
        maxHeight: 60
      }}
    >
      <img
        src={config?.brandingIcon || config.brandingColor}
        alt={config.name}
        style={{
          display: 'block',
          alignSelf: 'center',
          height: 60,
          width: 60,
          maxWidth: 60,
          maxHeight: 60
        }}
      />
    </Box>
  );
};
