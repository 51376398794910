import { SvgIcon } from '@mui/material';
import { FunctionComponent, ReactNode, useContext } from 'react';
import { useRecipeRunCountQuery } from '../../../__generated__/apollo-hooks';
import {
  BATCH_CHANGES_ORG_ID,
  BATCH_CHANGES_PATH,
  DEPLOYMENT_PATH,
  DEVCENTER_PATH,
  EXPLORE_API_PATH,
  MARKETPLACE_HOME_PATH,
  NEW_BUILDER_PATH,
  ORGANIZATION_REPOSITORIES_BASE_PATH,
  RECENT_ACTIVITY_PATH
} from '../../../constants/general';
import { ConfigurationContext } from '../../../contexts/config.context';
import { activityUrl, devCenterUrl } from '../../../helpers/link.helper';
import {
  BatchChangesIcon,
  BrowseRecipesIcon,
  DeployRecipesIcon,
  DevCenterIcon,
  ExplorerIcon,
  GraphQLIcon,
  HistoryIcon,
  RepositoryIcon
} from '../../../icons/icons';
import { useSelectedOrganizationStore } from '../../../stores/organization-store';
import { HelpMenu } from '../help-menu/help-menu.component';
import { AdminMenuItem } from '../left-nav-items/admin-menu-item.component';
import { HomeMenuItem } from '../left-nav-items/home-menu-item.component';
import { LeftNavigationMenuItem } from '../left-nav-items/left-nav-menu-item.component';
import { OrganizationSelectorMenuItem } from '../left-nav-items/organization-selector-menu-item.component';
import { SearchMenuItem } from '../left-nav-items/search-menu-item.component';
import { OverflowNavigationRail } from './overflow-navigation-rail.component';

export type NavItem = {
  icon: ReactNode;
  label: string;
  description: string;
  href?: string | (() => string);
  shortcut?: string;
  badge?: string;
};

export const LeftNavigation: FunctionComponent = () => {
  const { selectedOrganizationId } = useSelectedOrganizationStore();

  const { getFeature } = useContext(ConfigurationContext);

  const { data } = useRecipeRunCountQuery({
    variables: {
      filterBy: {
        organizationId: BATCH_CHANGES_ORG_ID
      }
    },
    fetchPolicy: 'cache-first',
    skip: !getFeature('batchChanges')
  });

  const showBatchChanges = data?.allRecipeRuns.count > 0;

  return (
    <OverflowNavigationRail>
      <HomeMenuItem />
      <SearchMenuItem />
      <OrganizationSelectorMenuItem />
      <LeftNavigationMenuItem
        registerCommand
        description="Holistic view of your organization health and goals"
        href={
          selectedOrganizationId
            ? devCenterUrl({ organizationId: selectedOrganizationId })
            : DEVCENTER_PATH
        }
        icon={<DevCenterIcon />}
        label="DevCenter"
        badge="Beta"
      />

      <LeftNavigationMenuItem
        registerCommand
        description="View available recipes for various technologies"
        href={MARKETPLACE_HOME_PATH}
        icon={<BrowseRecipesIcon />}
        label="Marketplace"
        shortcut="m"
      />
      {showBatchChanges ? (
        <LeftNavigationMenuItem
          registerCommand
          description="View recent batch changes"
          href={BATCH_CHANGES_PATH}
          icon={<BatchChangesIcon />}
          label="Batch Changes"
        />
      ) : null}
      <LeftNavigationMenuItem
        registerCommand
        description="Compose complex custom recipes"
        href={NEW_BUILDER_PATH}
        icon={<ExplorerIcon />}
        label="Builder"
        shortcut="b"
        badge="New"
      />
      <LeftNavigationMenuItem
        registerCommand
        description="Deploy recipes and visualizations"
        href={DEPLOYMENT_PATH}
        icon={<DeployRecipesIcon />}
        label="Deploy"
        shortcut="d"
      />
      <LeftNavigationMenuItem
        registerCommand
        description="View repositories"
        href={ORGANIZATION_REPOSITORIES_BASE_PATH}
        icon={<RepositoryIcon />}
        label="Repositories"
        shortcut="r"
      />
      <LeftNavigationMenuItem
        registerCommand
        description="See recent activity across your selected organization"
        href={
          selectedOrganizationId
            ? activityUrl(selectedOrganizationId)
            : RECENT_ACTIVITY_PATH
        }
        icon={<HistoryIcon />}
        label="Activity"
        shortcut="a"
      />
      <AdminMenuItem />
      <LeftNavigationMenuItem
        key={`api-explorer`}
        icon={<SvgIcon inheritViewBox component={GraphQLIcon} />}
        href={EXPLORE_API_PATH}
        label="API explorer"
        description="Find and use GraphQL APIs"
      />
      <HelpMenu />
    </OverflowNavigationRail>
  );
};
