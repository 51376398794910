import { Typography } from '@mui/material';
import { type FunctionComponent, useEffect, useState } from 'react';
import {
  formatRelativeTime,
  getDurationBetweenDates,
  millisecondsToDuration
} from '../../helpers/time.helper';

export const DownloadTimer: FunctionComponent<{
  startTimestamp: number;
}> = ({ startTimestamp }) => {
  const [_, setCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => prevCount + 1);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Typography
      variant="caption"
      sx={{
        color: 'text.secondary'
      }}
    >
      Started{' '}
      {formatRelativeTime(
        millisecondsToDuration(
          getDurationBetweenDates(new Date(startTimestamp), new Date())
        )
      ) || '0s'}{' '}
      ago
    </Typography>
  );
};
