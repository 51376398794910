import { useGridApiContext } from '@mui/x-data-grid-pro';
import {
  type DateRange,
  DateRangePicker,
  LocalizationProvider
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { type FunctionComponent, useState } from 'react';
import { FilterOperator } from '../../helpers/data-grid.helper';

export const DateRangeFilter: FunctionComponent = () => {
  const apiRef = useGridApiContext();
  const defaultDateRange = (apiRef.current.state.filter.filterModel.items.find(
    (item) => item.operator === FilterOperator.DateRange
  )?.value as DateRange<Date>) || [undefined, undefined];
  const [dateRange, setDateRange] = useState<DateRange<Date>>(defaultDateRange);

  const updateFilterModel = (newValue) => {
    const filterModel = apiRef.current.state.filter.filterModel;
    const updatedModel = {
      ...filterModel,
      items: filterModel.items.map((item) => {
        if (item.operator === FilterOperator.DateRange) {
          return { ...item, value: newValue };
        }
        return item;
      })
    };

    apiRef.current.setFilterModel(updatedModel, 'upsertFilterItem');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        value={dateRange}
        onChange={(newValue) => {
          updateFilterModel(newValue);
          setDateRange(newValue);
        }}
        slotProps={{
          fieldSeparator: {
            children: 'to'
          },
          textField: ({ position }) => ({
            label: position === 'start' ? 'Start date' : 'End date',
            size: 'small',
            variant: 'standard',
            sx: { minWidth: 100 }
          })
        }}
        sx={{ width: 210, marginLeft: 1 }}
      />
    </LocalizationProvider>
  );
};
