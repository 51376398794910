import {
  Box,
  type BoxProps,
  type ContainerProps,
  Fab,
  Fade,
  Typography
} from '@mui/material';
import Head from 'next/head';
import React, { type JSX, type ReactNode, useState } from 'react';

import type { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import { useInView } from 'react-intersection-observer';
import {
  DOHERTY_THRESHOLD,
  NAVIGATION_RAIL_WIDTH,
  TOP_BANNER_HEIGHT
} from '../../constants/general';
import { useCommonBreakpoints } from '../../hooks/use-breakpoints.hooks';
import { UpArrowIcon } from '../../icons/icons';
import {
  type Breadcrumb,
  BreadcrumbsComponent
} from '../navigation/breadcrumbs/breadcrumb.component';
import {
  CenteredBox,
  CenteredOpposingBoxes,
  UncenteredContainer
} from '../styled-components/layouts/layouts.styled';
import { Loading } from '../utilities/loading/loading.component';
import { WithAdministrative } from '../with-administrative/with-administrative.component';
import { StickyBox } from './paper.component';

type LayoutProps = {
  title: string;
  subheading?: ReactNode | string;
  disableContainerGutters?: boolean;
  breadcrumbs?: Breadcrumb[];
  pageTitle?: ReactNode | string;
  sticky?: boolean;
  isLoading: boolean;
  requiresAdministrativeRole?: boolean;
  maxWidth?: ContainerProps['maxWidth'];
  action?: JSX.Element;
  slots?: {
    banner?: ReactNode;
  };
} & BoxProps;

/**
 *
 * @param title {string} Applied to HTML document `<title>`
 * @param pageTitle {string|ReactNode} displayed after breadcrumbs within `<Typography>`
 */
export const Layout: FunctionComponentWithChildren<LayoutProps> = ({
  title,
  subheading,
  maxWidth,
  pageTitle,
  isLoading = false,
  requiresAdministrativeRole = false,
  children,
  disableContainerGutters,
  action = undefined,
  sticky = false,
  breadcrumbs,
  sx,
  slots,
  ...boxRest
}) => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  const { ref } = useInView({
    threshold: 0.1,
    delay: DOHERTY_THRESHOLD,
    onChange: (inView) => {
      setShowBackToTop(!inView);
    }
  });

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        // provides a small amount of spacing for scrolling and being able to see the bottom of the page
        marginBottom: 1,
        minHeight: 'calc(100vh - 100px)',
        maxWidth: `calc(100vw - ${NAVIGATION_RAIL_WIDTH}px)`,
        ...(sx || {})
      }}
      {...boxRest}
    >
      <Head>
        <title>Moderne - {title}</title>
      </Head>

      {slots?.banner}

      <UncenteredContainer ref={ref} maxWidth={false}>
        <BreadcrumbsComponent links={breadcrumbs || [{ label: title }]} />
      </UncenteredContainer>

      {/* Page title in its own container to remove vertical lines */}
      {pageTitle && (
        <PageTitle
          action={action}
          sticky={sticky}
          maxWidth={maxWidth}
          subheading={subheading}
        >
          {pageTitle}
        </PageTitle>
      )}
      <UncenteredContainer
        disableGutters={disableContainerGutters}
        maxWidth={maxWidth || false}
      >
        {/* Check for administrative restrictions */}
        {requiresAdministrativeRole ? (
          <WithAdministrative>
            <LoadingAndThenDisplay loading={isLoading}>
              {children}
            </LoadingAndThenDisplay>
          </WithAdministrative>
        ) : (
          <LoadingAndThenDisplay loading={isLoading}>
            {children}
          </LoadingAndThenDisplay>
        )}
      </UncenteredContainer>
      <Fade in={showBackToTop}>
        <Box
          sx={{
            // css to persistently in the bottom right corner of the screen even when scrolling
            position: 'fixed',
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2)
          }}
        >
          <Fab
            sx={{
              backgroundColor: (theme) => `${theme.palette.blue.main}20`,
              color: (theme) => theme.palette.blue.main
            }}
            title="Back to top"
            size="small"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            <UpArrowIcon />
          </Fab>
        </Box>
      </Fade>
    </Box>
  );
};

const PageTitle: FunctionComponentWithChildren<
  Pick<LayoutProps, 'action' | 'sticky' | 'maxWidth' | 'subheading'>
> = ({ children, action, sticky, maxWidth, subheading }) => {
  const { isLargeDevice } = useCommonBreakpoints();
  const pageTitleBox = (
    <CenteredOpposingBoxes
      sx={{
        marginTop: sticky ? 1 : 2.2,
        marginBottom: sticky ? 1 : 2.2,
        alignItems: 'center',
        flexWrap: 'wrap'
      }}
    >
      <Box gap={1.5}>
        <Typography variant="h3" component="h1">
          {children}
        </Typography>
        {subheading && React.isValidElement(subheading) ? (
          subheading
        ) : (
          <Typography variant="caption">{subheading}</Typography>
        )}
      </Box>
      {action}
    </CenteredOpposingBoxes>
  );

  return sticky ? (
    <StickyBox
      bgcolor="inherit"
      id="sticky-header"
      top={isLargeDevice ? 0 : TOP_BANNER_HEIGHT}
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        paddingTop: 0.5,
        paddingBottom: 0.25
      }}
    >
      <UncenteredContainer id="page-title" maxWidth={maxWidth || false}>
        {pageTitleBox}
      </UncenteredContainer>
    </StickyBox>
  ) : (
    <UncenteredContainer id="page-title" maxWidth={maxWidth || false}>
      {pageTitleBox}
    </UncenteredContainer>
  );
};

const LoadingAndThenDisplay: FunctionComponentWithChildren<{
  loading: boolean;
}> = ({ loading, children }) => {
  return loading ? (
    <CenteredBox
      sx={{
        minHeight: '50vh'
      }}
    >
      <Loading caption="Loading" wait={DOHERTY_THRESHOLD} />
    </CenteredBox>
  ) : (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        maxWidth: '100%'
      }}
    >
      {children}
    </Box>
  );
};
