import type { PureFunction } from '@bonsai-components/utility-types';
import type { GridColDef } from '@mui/x-data-grid-pro';
import {
  FilterOperator,
  dataGridColumnSize,
  getSupportedGridStringFilterOperators
} from '../../../helpers/data-grid.helper';
import { ScmOriginCell } from '../../data-grid/cells/scm-origin-cell.component';
import type { UserOrganizationEditorGridRow } from './user-organization-editor.rows';

const filterOperators = getSupportedGridStringFilterOperators([
  FilterOperator.Contains
]);

export const determineGridColumns: PureFunction<
  void,
  GridColDef<UserOrganizationEditorGridRow>[]
> = () => [
  {
    field: 'origin',
    headerName: 'Origin',
    headerAlign: 'center',
    align: 'center',
    ...dataGridColumnSize('xsmall'),
    renderCell: ({ row }) => (
      <ScmOriginCell origin={row.origin} scmType={row.__typename} />
    )
  },
  {
    field: 'organization',
    headerName: 'Organization',
    flex: 1,
    filterOperators
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    filterOperators
  },
  {
    field: 'branch',
    headerName: 'Branch',
    filterOperators
  }
];
