import { Stack, type StackProps } from '@mui/material';
import type { FunctionComponent, JSX, ReactNode } from 'react';

/**
 * A component that renders a horizontal stack of toolbar actions.
 * Include built-in wrapping of components on smaller screens.
 * Defaults to a row direction with a gap of 1.
 *
 * @param {StackProps} props - Props for the Stack component.
 * @param {ReactNode} props.children - The child elements to render within the stack.
 * @returns {JSX.Element} - The rendered component.
 */
export const ToolbarActions: FunctionComponent<StackProps> = ({
  children,
  ...props
}) => (
  <Stack
    direction={'row'}
    {...props}
    sx={[
      {
        gap: 1,
        flexWrap: { xs: 'wrap', sm: 'nowrap' }
      },
      ...(Array.isArray(props.sx) ? props.sx : [props.sx])
    ]}
  >
    {children}
  </Stack>
);
