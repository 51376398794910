import type { PureFunction } from '@bonsai-components/utility-types';
import {
  type BuilderState,
  ROOT_NODE_ID,
  useBuilderStore
} from '../../stores/builder.store';

/**
 * Given a callback that takes a function that updates the builder state,
 * runs the callback if the builder is editable.
 */
const checkIsEditable: PureFunction<
  (update: (update: Partial<BuilderState>) => void) => void,
  void
> = (callback) => {
  const { isEditable, update } = useBuilderStore.getState();
  if (isEditable) {
    callback(update);
  }
};

/**
 * Open the correct edit dialog if the builder is editable
 */
export const openEditDialog = () =>
  checkIsEditable((update) => {
    const { selectedRecipeNode } = useBuilderStore.getState();

    // open the edit dialog for the root node
    if (selectedRecipeNode?.id === ROOT_NODE_ID) {
      return update({ showEditRecipeDialog: true });
    }

    // open the edit options dialog if the selected node has options
    if (selectedRecipeNode?.val?.options?.length > 0) {
      return update({ showEditRecipeOptionsDialog: true });
    }

    // open the edit nested recipe dialog for nested custom recipes that aren't the root
    if (
      selectedRecipeNode?.id !== ROOT_NODE_ID &&
      selectedRecipeNode?.isCustomRecipe
    ) {
      return update({ showEditNestedRecipeDialog: true });
    }
  });

/**
 * Open the correct delete dialog if the builder is editable
 */
export const openDeleteDialog = () =>
  checkIsEditable((update) => {
    const { selectedRecipeNode } = useBuilderStore.getState();

    // don't open the delete dialog for the root node
    if (selectedRecipeNode?.id === ROOT_NODE_ID) {
      return;
    }

    update({ showDeleteRecipeDialog: true });
  });

/**
 * Open the add recipe dialog if the builder is editable
 */
export const openAddRecipeDialog = () =>
  checkIsEditable((update) => {
    update({ showRecipeFinderPanel: true });
  });

export const promptForRecipeOverride = async (id): Promise<boolean> => {
  await useBuilderStore.getState().fetchCustomRecipes();
  if (useBuilderStore.getState().customRecipes.some((r) => r.id === id)) {
    useBuilderStore.getState().update({ showOverrideRecipeDialog: true });
    return true;
  }
  return false;
};
