import { useSession } from 'next-auth/react';
import { type FunctionComponent, forwardRef } from 'react';

import { type AvatarProps, ListItemButton, ListItemIcon } from '@mui/material';

import { getAvatarSource } from '../../helpers/avatar.helper';
import { ModerneUserAvatar } from '../styled-components/avatars/avatars.styled';

export const UserAvatarButton: FunctionComponent = forwardRef((props, ref) => {
  const { data: session } = useSession();
  const avatarSource = getAvatarSource(session);
  const avatarInitials = (
    session?.user?.fullName || session?.user?.userName
  )?.charAt(0);

  const alt =
    session?.user?.userName || session?.user?.fullName || 'user avatar';

  return (
    <ListItemButton {...props}>
      <ListItemIcon>
        <ModerneUserAvatar
          {...ref}
          alt={alt}
          src={avatarSource}
          sx={{
            width: 32,
            height: 32
          }}
        >
          {avatarInitials}
        </ModerneUserAvatar>
      </ListItemIcon>
    </ListItemButton>
  );
});

export const UserAvatar: FunctionComponent<{
  size?: number;
  sx?: AvatarProps['sx'];
}> = forwardRef(({ size = 32, sx = {} }, ref) => {
  const { data: session } = useSession();
  const avatarSource = getAvatarSource(session);
  const avatarInitials = (
    session?.user?.fullName || session?.user?.userName
  )?.charAt(0);

  const alt =
    session?.user?.userName || session?.user?.fullName || 'user avatar';

  return (
    <ModerneUserAvatar
      {...ref}
      alt={alt}
      src={avatarSource}
      sx={{
        width: size,
        height: size,
        ...sx
      }}
    >
      {avatarInitials}
    </ModerneUserAvatar>
  );
});
