import {
  Box,
  Divider,
  Fade,
  MenuItem,
  MenuList,
  Typography
} from '@mui/material';
import { useRouter } from 'next/router';
import React, { type FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';

import { useAutoCompleteResultsQuery } from '../../__generated__/apollo-hooks';
import { DOHERTY_THRESHOLD } from '../../constants/general';
import {
  handleMiddleClickOpen,
  recipeDetailsUrl
} from '../../helpers/link.helper';
import { multipleTermsToCrossFieldQuery } from '../../helpers/search.helpers';
import { useUiFeaturesStore } from '../../stores/ui-features.store';
import {
  CenteredBox,
  OpposingBoxes
} from '../styled-components/layouts/layouts.styled';
import { KeyStroke } from '../styled-components/typography/typography.styled';
import { Loading } from '../utilities/loading/loading.component';
import { SearchDocs } from './ai-search-docs.component';
import { CompactRecipeSearchResult } from './compact-recipe-search-result.component';
import { RecipeListItemButton, SectionHeading } from './global-search.styled';

type SearchRecipesProps = {
  searchTerm: string;
  resetModal: React.Dispatch<React.SetStateAction<null>>;
  toggleMenu?: () => void;
};

export const SearchRecipes: FunctionComponent<SearchRecipesProps> = ({
  searchTerm,
  resetModal,
  toggleMenu
}) => {
  const { useAiSearch } = useUiFeaturesStore();

  const router = useRouter();

  const { resetField } = useFormContext();

  const resetState = () => {
    resetField('query');
    resetModal(null);
    if (toggleMenu) {
      toggleMenu();
    }
  };

  const { data, loading } = useAutoCompleteResultsQuery({
    variables: {
      query: useAiSearch
        ? searchTerm
        : multipleTermsToCrossFieldQuery(searchTerm),
      featureAi: useAiSearch
    },
    fetchPolicy: 'cache-first'
  });

  const handleRecipeNavigation = (id) => (e) => {
    if (e.type === 'click' || (e.type === 'keydown' && e.key === 'Enter')) {
      resetState();
      router.push(recipeDetailsUrl({ id }));
    }
  };

  return (
    <Box
      sx={{
        width: 'auto',
        minWidth: { xs: 300, sm: 400 }
      }}
    >
      <SectionHeading>
        <OpposingBoxes>
          <Box>Recipes:</Box>
          <Box>
            {data?.searchRecipes?.count && (
              <Typography variant="caption" color="textSecondary">
                {data.searchRecipes.count} results
              </Typography>
            )}
          </Box>
        </OpposingBoxes>
      </SectionHeading>
      <Divider />
      <Box
        sx={{
          overflow: 'scroll',
          height: 300
        }}
      >
        {!data && loading && (
          <CenteredBox
            sx={{
              height: '100%'
            }}
          >
            <Loading caption="Searching for recipes" wait={DOHERTY_THRESHOLD} />
          </CenteredBox>
        )}
        <MenuList>
          {data?.searchRecipes?.edges.length === 0 && (
            <MenuItem>No results found</MenuItem>
          )}

          {data?.searchRecipes.edges?.map(({ node: { id, name } }) => (
            <RecipeListItemButton
              key={id}
              onClick={handleRecipeNavigation(id)}
              onMouseDown={handleMiddleClickOpen(recipeDetailsUrl({ id }))}
              onKeyDown={handleRecipeNavigation(id)}
              tabIndex={0}
            >
              <CompactRecipeSearchResult id={id} name={name} />
            </RecipeListItemButton>
          ))}
        </MenuList>
      </Box>
      <Divider />
      <Box
        sx={{
          paddingY: 0.5,
          paddingX: 1,
          bgcolor: (theme) => theme.palette.blue[100],
          minHeight: data?.searchRecipes?.count ? '3.5rem' : '1.8rem'
        }}
      >
        <Fade in={Boolean(!loading)}>
          <Typography
            variant="caption"
            sx={{
              fontSize: 12,
              color: (theme) => theme.palette.grey[700]
            }}
          >
            {data?.searchRecipes?.count > 0 && (
              <React.Fragment>
                Press
                <KeyStroke>Enter</KeyStroke>
                to see more or navigate with up/down arrow keys
              </React.Fragment>
            )}
            <SearchDocs isAiSearch={useAiSearch} />
          </Typography>
        </Fade>
      </Box>
    </Box>
  );
};
