import { useEditorContext } from '@graphiql/react';
import { MenuItem } from '@mui/material';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useSession } from 'next-auth/react';
import { type FunctionComponent, useContext } from 'react';
import { NotificationContext } from '../../contexts/notification.context';
import { MenuBase } from '../utilities/menus/menu-base/menu-base.component';

export const ShareGraphQLCurl: FunctionComponent<{ url: string }> = ({
  url
}) => {
  const { queryEditor, variableEditor } = useEditorContext();
  const { renderNotification } = useContext(NotificationContext);
  const { data } = useSession();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'graphql-curl'
  });
  const handleCopy = (query: string, variables: string) => {
    const curlCommand = `curl -X POST ${url} \\
    -H 'Authorization: Bearer ${data.accessToken}' \\
    -H 'Content-Type: application/json' \\
    -d '{ "query": ${JSON.stringify(query)}${variables} }'`;

    navigator.clipboard.writeText(curlCommand.toString()).then(() => {
      renderNotification('success', 'Copied graphql curl command to clipboard');
    });
  };

  return queryEditor?.operations?.length > 1 ? (
    <MenuBase
      actionItem={<MenuItem {...bindTrigger(popupState)}>Share curl</MenuItem>}
      menuId={'operation-menu'}
    >
      {queryEditor.operations.map(
        ({ loc, name, variableDefinitions, operation }, idx) => {
          let variablesObject = {};
          try {
            variablesObject = JSON.parse(variableEditor.getValue());
          } catch {
            // do nothing;
          }
          const operationVariables = variableDefinitions.reduce(
            (acc, variable) => {
              const foundVariable =
                variablesObject[variable.variable.name.value];
              if (foundVariable) {
                acc[variable.variable.name.value] = foundVariable;
              }

              return acc;
            },
            {}
          );

          const variables =
            Object.keys(operationVariables).length > 0
              ? `, "variables": ${JSON.stringify(operationVariables)}`
              : '';

          const query = queryEditor.getValue()?.substring(loc.start, loc.end);

          return (
            <MenuItem key={idx} onClick={() => handleCopy(query, variables)}>
              {name?.value || `<Unnamed ${operation}>`}
            </MenuItem>
          );
        }
      )}
    </MenuBase>
  ) : (
    <MenuItem
      onClick={() => {
        const query =
          queryEditor.getValue() !== '' ? queryEditor.getValue() : '';

        const variables =
          query !== '' && variableEditor.getValue() !== ''
            ? `, "variables": ${JSON.stringify(variableEditor.getValue())}`
            : '';

        handleCopy(query, variables);
      }}
    >
      Share curl
    </MenuItem>
  );
};
