import { logError } from '../../helpers/logger.helper';
import type { GraphLink } from '../indexedDb.helper';

/**
 * clean issues:
 * - remove any links where the target and source are the same
 */
export const cleanLinks = (links: GraphLink[]): GraphLink[] => {
  return links?.filter((link) => link.source !== link.target);
};

/**
 * Look through links and find node IDs that are descendants of the start node
 */
export const findDescendantNodeIds = (
  startNodeId: number,
  links: GraphLink[] = []
): number[] => {
  const queue: number[] = [startNodeId];
  const visited: Set<number> = new Set();
  const descendants: number[] = [];

  visited.add(startNodeId);

  while (queue.length > 0) {
    const currentId = queue.shift(); // Remove the first element from the queue
    if (currentId === undefined) {
      continue;
    }

    // Find all links where the current node is the source
    const children = links
      .filter((link) => link.source === currentId)
      .map((link) => link.target);

    for (const childId of children) {
      if (!visited.has(childId)) {
        visited.add(childId);
        queue.push(childId);
        descendants.push(childId); // Collect descendant
      }
    }
  }

  return descendants;
};

/**
 * Look through links and find node IDs that are ancestors of the start node
 */
export const findParentNodeIDs = (nodeId, links: GraphLink[]): number[] => {
  const parents = [];
  let currentId = nodeId;

  while (currentId) {
    const link = links?.find((l) => l.target === currentId);
    if (!link) {
      break;
    }
    if (parents.includes(link.source)) {
      logError(
        new Error(
          `Circular reference detected or repeated source: ${link.source}`
        )
      );
      break;
    }
    parents.push(link.source);
    currentId = link.source;
  }

  return parents;
};
