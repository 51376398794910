import { useSession } from 'next-auth/react';
import { useContext } from 'react';

import {
  type AzureDevOpsConfiguration,
  type BitbucketCloudConfiguration,
  type BitbucketConfiguration,
  type GitLabConfiguration,
  type GithubConfiguration,
  isScmConfigurationAAzureDevOpsConfiguration,
  isScmConfigurationABitbucketCloudConfiguration,
  isScmConfigurationABitbucketConfiguration,
  isScmConfigurationAGitLabConfiguration,
  isScmConfigurationAGithubConfiguration,
  useCanDeployArtifactsQuery,
  useGetBitbucketRequestTokenMutation,
  useScmConfigurationLazyQuery
} from '../__generated__/apollo-hooks';
import { NotificationContext } from '../contexts/notification.context';
import {
  currentAuthorizationUrl,
  getAzureDevOpsAuthorizationOptions,
  getBitbucketCloudAuthorizationOptions,
  getGitHubAuthorizationOptions,
  getGitLabAuthorizationOptions
} from '../helpers/authorization.helper';

type UserRoles = {
  isAdministrator: boolean;
};
/**
 * Checks current session to see if the user is an administrator
 * @returns boolean
 */
export const useAuthorization = (): UserRoles => {
  const { data } = useSession();

  return {
    isAdministrator: data?.isAdmin ?? false
  };
};

type CanDeployArtifacts = {
  canDeployArtifacts: boolean;
};
export const useCanDeployArtifacts = (): CanDeployArtifacts => {
  const { data } = useCanDeployArtifactsQuery();
  const canDeployArtifacts = data?.canDeploy ?? false;

  return {
    canDeployArtifacts
  };
};

export const useScmAuthorization = () => {
  const [getScmConfig] = useScmConfigurationLazyQuery();
  const { renderNotification } = useContext(NotificationContext);
  const handleBitbucketServerOAuthRedirect =
    useHandleBitbucketServerOAuth1Redirect();

  const handleAuthorization = async (resourceId: string): Promise<void> => {
    try {
      const { data } = await getScmConfig({ variables: { resourceId } });

      let redirectURL;

      if (isScmConfigurationAGithubConfiguration(data?.scm)) {
        redirectURL = getGitHubOAuthRedirectURL(data?.scm);
      } else if (isScmConfigurationAGitLabConfiguration(data?.scm)) {
        redirectURL = getGitLabOAuthRedirectURL(data?.scm);
      } else if (isScmConfigurationABitbucketConfiguration(data?.scm)) {
        redirectURL = await handleBitbucketServerOAuthRedirect(data?.scm);
      } else if (isScmConfigurationABitbucketCloudConfiguration(data?.scm)) {
        redirectURL = getBitbucketCloudOAuthRedirectURL(data?.scm);
      } else if (isScmConfigurationAAzureDevOpsConfiguration(data?.scm)) {
        redirectURL = getAzureDevOpsOAuthRedirectURL(data?.scm);
      } else {
        throw new Error(`SCM configuration not found for: ${resourceId}`);
      }
      return window.location.assign(redirectURL);
    } catch (e) {
      let message = e.message;
      let title = 'Unable to authorize';
      if (message?.includes('getBitbucketRequestToken')) {
        title = 'Unable to connect to Bitbucket';
        message =
          'We could not successfully request a request token from the Bitbucket server';
      }
      renderNotification('error', message, {
        title,
        placement: {
          vertical: 'top',
          horizontal: 'center'
        },
        maxWidth: 'md'
      });
    }
  };
  return {
    handleAuthorization
  };
};

const getGitHubOAuthRedirectURL = (config: GithubConfiguration) => {
  const { resourceId, oauth } = config;

  if (!oauth) {
    throw new Error('Unable to find OAuth configuration for GitHub from Agent');
  }
  const { clientId } = oauth;

  const redirectURI = `${resourceId}/login/oauth/authorize?${getGitHubAuthorizationOptions(
    {
      clientId,

      origin: resourceId
    }
  )}`;
  return redirectURI;
};

const getGitLabOAuthRedirectURL = (config: GitLabConfiguration) => {
  const {
    resourceId,
    oauth: { clientId }
  } = config;

  const redirectURI = `${resourceId}/oauth/authorize?${getGitLabAuthorizationOptions(
    { clientId, origin: resourceId }
  )}`;
  return redirectURI;
};

const getBitbucketCloudOAuthRedirectURL = (
  config: BitbucketCloudConfiguration
) => {
  const {
    resourceId,
    oauth: { clientId }
  } = config;

  const redirectURI = `${resourceId}/site/oauth2/authorize?${getBitbucketCloudAuthorizationOptions(
    { clientId, origin: resourceId }
  )}`;
  return redirectURI;
};

const getAzureDevOpsOAuthRedirectURL = (config: AzureDevOpsConfiguration) => {
  const {
    resourceId,
    oauth: { clientId, tenantId }
  } = config;

  const redirectURI = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?${getAzureDevOpsAuthorizationOptions(
    { clientId, origin: resourceId, tenantId }
  )}`;
  return redirectURI;
};

const useHandleBitbucketServerOAuth1Redirect = () => {
  const [getRequestToken] = useGetBitbucketRequestTokenMutation();

  return async (config: BitbucketConfiguration) =>
    getRequestToken({
      variables: {
        callbackUrl: currentAuthorizationUrl({
          origin: config.resourceId
        }),
        origin: config.resourceId
      }
    }).then(({ data }) => {
      return `${config.resourceId}/plugins/servlet/oauth/authorize?oauth_token=${data.getBitbucketRequestToken}`;
    });
};
