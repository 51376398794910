import { Button } from '@mui/material';
import type { FunctionComponent } from 'react';

export const CancelDialogAction: FunctionComponent<{
  onClose: () => void;
  label?: string;
}> = ({ label = 'Cancel', onClose }) => (
  <Button variant="text" color="secondary" onClick={onClose}>
    {label}
  </Button>
);
