import React, { type FunctionComponent } from 'react';
import { useI18n } from '../../../hooks/use-i18n.hooks';

type NumberProps = {
  value: number;
  humanReadable?: boolean;
};

/**
 * Number component displays numbers either in a human readable format in a
 * locale-aware way or in a locale-agnostic way.
 */
export const LocaleNumber: FunctionComponent<NumberProps> = ({
  value,
  humanReadable = false
}) => {
  const { formatNumber } = useI18n();

  const localFormattedValue = formatNumber({ value });
  const humanReadableValue = formatNumber({ value, humanReadable: true });

  // Providing a tooltip for < 1000 does not add much value
  const humanReadableTitle = value > 999 ? localFormattedValue : undefined;

  return humanReadable ? (
    <span title={humanReadableTitle}>{humanReadableValue}</span>
  ) : (
    <React.Fragment>{localFormattedValue}</React.Fragment>
  );
};
