import { CircularProgress, Typography } from '@mui/material';
import { type FunctionComponent, useEffect, useRef, useState } from 'react';
import { CenteredBox } from '../../styled-components/layouts/layouts.styled';
export type LoadingProps = {
  width?: number;
  caption?: string;
  wait?: number;
  color?: string;
};

/**
 * Loading indicator that supports a caption and a wait time before showing
 */
export const Loading: FunctionComponent<LoadingProps> = ({
  width = 32,
  caption,
  wait = 0,
  color
}) => {
  const timer = useRef<NodeJS.Timeout>();
  const [show, setShow] = useState(wait === 0);

  useEffect(() => {
    if (wait) {
      timer.current = setTimeout(() => {
        setShow(true);
      }, wait);
    }
    return () => clearTimeout(timer.current);
  }, [wait]);

  return show ? (
    <CenteredBox
      sx={{
        flexDirection: 'column'
      }}
    >
      <CircularProgress size={width} aria-busy />
      {caption && (
        <Typography variant="subtitle2" sx={{ color, mt: 1 }}>
          {caption}
        </Typography>
      )}
    </CenteredBox>
  ) : null;
};
