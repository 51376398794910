import type { PureFunction } from '@bonsai-components/utility-types';
import type { OrganizationInput } from '../@types/repository-groups';
import {
  type AzureDevOpsRepository,
  type BitbucketCloudRepository,
  type BitbucketRepository,
  type GitHubRepository,
  type GitLabRepository,
  type RepositoryFragment,
  isRepositoryAAzureDevOpsRepository,
  isRepositoryABitbucketCloudRepository,
  isRepositoryABitbucketRepository,
  isRepositoryAGitHubRepository,
  isRepositoryAGitLabRepository
} from '../__generated__/apollo-hooks';
import { SESSION_STORAGE_SELECTED_REPOSITORY_GROUP } from '../constants/general';

const REPO_ID_DELIMITER = '~~';

export type RepositoryForUserOrganization = RepositoryFragment & {
  status?: string;
};

export const mapRepositoryFragmentToId: PureFunction<
  Partial<RepositoryFragment>,
  string
> = (repository) => {
  return [
    repository.__typename,
    repository.origin,
    repository.path,
    repository.branch
  ]
    .filter(Boolean)
    .join(REPO_ID_DELIMITER);
};

export const repositoryIdToRepositoryFragment: PureFunction<
  string,
  RepositoryFragment
> = (repositoryId) => {
  if (!repositoryId.includes(REPO_ID_DELIMITER)) {
    return null;
  }
  const [__typename, origin, path, branch] =
    repositoryId.split(REPO_ID_DELIMITER);

  const { organization, name } = getOrganizationAndNameFromPath(path);
  const repositoryIdFragment = {
    __typename: __typename as never,
    id: repositoryId,
    branch,
    origin,
    path,
    name,
    organization
  };

  return repositoryIdFragment;
};

export const minimizeRepositoryObject = (
  fullRepositoryObject:
    | RepositoryFragment
    | BitbucketRepository
    | BitbucketCloudRepository
    | GitHubRepository
    | GitLabRepository
    | AzureDevOpsRepository
): { origin: string; path: string; branch: string } => {
  const { origin, path, branch } = fullRepositoryObject;
  return { origin, path, branch };
};

type PrunedKeys = keyof (OrganizationInput & { __typename: string });
const PRUNED_KEYS: PrunedKeys[] = [
  '__typename',
  'id',
  'isUserOrganization',
  'commitOptions',
  'parent',
  'description'
];

/**
 * Recursively replace Repository objects with a RepositoryIdentityFragment
 */
export const minimalRepositoryReducer = <T>(param: T): T => {
  if (param === null || param === undefined) {
    return param;
  }

  if (Array.isArray(param)) {
    return param.map(minimalRepositoryReducer) as T;
  }

  if (typeof param === 'object') {
    if (
      isRepositoryAGitHubRepository(param) ||
      isRepositoryABitbucketRepository(param) ||
      isRepositoryABitbucketCloudRepository(param) ||
      isRepositoryAGitLabRepository(param) ||
      isRepositoryAAzureDevOpsRepository(param)
    ) {
      return minimizeRepositoryObject(param) as T;
    }

    const keys = Object.keys(param);
    return keys.reduce((result, key) => {
      if (PRUNED_KEYS.some((prunedKey) => prunedKey === key)) {
        return result;
      }
      const value = param[key];
      result[key] = minimalRepositoryReducer(value);
      return result;
    }, {} as T);
  }

  return param as T;
};

export const getOrganizationAndNameFromPath: PureFunction<
  string,
  { organization: string; name: string }
> = (path) => {
  if (path.indexOf('/') === -1) {
    return {
      organization: path,
      name: null
    };
  } else {
    const pathParts = path.split('/');
    return {
      organization: pathParts.slice(0, -1).join('/'),
      name: pathParts.at(-1)
    };
  }
};

export const getUniqueRepositoryIdentities: PureFunction<
  RepositoryFragment[],
  RepositoryFragment[]
> = (repositoryIdentities) => {
  return repositoryIdentities.reduce((acc, repo) => {
    const existing = acc.find(
      (r) => mapRepositoryFragmentToId(r) === mapRepositoryFragmentToId(repo)
    );
    if (!existing) {
      acc.push(repo);
    }
    return acc;
  }, [] as RepositoryFragment[]);
};

export const getUniqueOriginsFromRepositoryIdentities: PureFunction<
  RepositoryFragment[],
  string[]
> = (repositoryIdentities) => {
  return repositoryIdentities.reduce((acc, repo) => {
    if (!repo) {
      return acc;
    }

    const existing = acc.find((r) => r === repo?.origin);
    if (!existing) {
      acc.push(repo.origin);
    }
    return acc;
  }, [] as string[]);
};

export const getSelectedOrganizationIdFromBrowserStorage = () => {
  const id =
    window.sessionStorage.getItem(SESSION_STORAGE_SELECTED_REPOSITORY_GROUP) ||
    window.localStorage.getItem(SESSION_STORAGE_SELECTED_REPOSITORY_GROUP);
  return id;
};

export const setSelectedOrganizationIdInBrowserStorage = (id: string) => {
  window.sessionStorage.setItem(SESSION_STORAGE_SELECTED_REPOSITORY_GROUP, id);
  window.localStorage.setItem(SESSION_STORAGE_SELECTED_REPOSITORY_GROUP, id);
};
