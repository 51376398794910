import { Group } from '@tweenjs/tween.js';
import type { useRouter } from 'next/router';
import React from 'react';
import type * as THREE from 'three';
import type { NonFunctionProperties } from '../@types';
import type { RecipeDetailsFragment } from '../__generated__/apollo-hooks';
import { BUILDER_DEFAULT_RIGHT_SIDEBAR_WIDTH } from '../constants/builder';
import { promptForRecipeOverride } from '../helpers/builder/builder-dialog.helpers';
import {
  cleanLinks,
  findDescendantNodeIds
} from '../helpers/builder/builder-links.helpers';
import { removeNode } from '../helpers/builder/builder-nodes.helpers';
import {
  addRecipeDetailsFragmentToNode,
  makeParentsCustomRecipe,
  updateCurrentRecipeDetails,
  updateSelectedNodeOptions
} from '../helpers/builder/builder-recipe.helpers';
import {
  type CreateYamlOptions,
  createYamlFromNodesAndLinks
} from '../helpers/builder/builder-yaml.helpers';
import {
  type BuilderRecipe,
  type GraphLink,
  type GraphNodeData,
  db
} from '../helpers/indexedDb.helper';
import { createTemporalStore } from './store-creators';

export const ROOT_NODE_ID = 0;

export const TITLE_FONT_SIZE = [10, 10, 10, 12];

const nodePositions = React.createRef<
  Record<number, THREE.Vector3>
>() as React.MutableRefObject<Record<number, THREE.Vector3>>;
nodePositions.current = {};

const cameraControlsRef =
  React.createRef<THREE.CameraControls>() as React.MutableRefObject<THREE.CameraControls>;

/**
 * Wrap a function so that the loading state is set before the call and reset after
 */
const withLoading = <T extends (...args: unknown[]) => Promise<void>>(
  fn: T
): T => {
  return (async (...args: Parameters<T>): Promise<void> => {
    const setIsLoading = (isLoading: boolean) => {
      const currentLoading = useBuilderStore.getState().builderLoading;
      if (currentLoading !== isLoading) {
        useBuilderStore.getState().update({ builderLoading: isLoading });
      }
    };
    await setIsLoading(true);
    try {
      await fn(...args);
    } finally {
      setIsLoading(false);
    }
  }) as unknown as T;
};

type BuilderDialogStates = {
  /** For deleting an entire recipe */
  showDeleteCustomRecipeDialog: boolean;
  /** For deleting a recipe node */
  showDeleteRecipeDialog: boolean;
  showEditCurrentRecipeAsYamlDialog: boolean;
  showEditNestedRecipeDialog: boolean;
  showEditRecipeDialog: boolean;
  showEditRecipeOptionsDialog: boolean;
  showEditYamlDialog: boolean;
  showLoadCustomRecipePanel: boolean;
  showManageCustomRecipePanel: boolean;
  showNewRecipeDialog: boolean;
  showOverrideRecipeDialog: boolean;
  showPreconditionFinderPanel: boolean;
  showRecipeFinderPanel: boolean;
};

type BuilderUIStates = {
  builderLoading: boolean;
  builderSaving: boolean;
  /** Enables being able to use orbital controls from outside of the Canvas */
  cameraControlsRef: typeof cameraControlsRef;
  customCameraFunctions: {
    zoomToNode: (id: number) => void;
    zoomToFit: () => void;
  };
  debug: boolean;
  nodePositions: typeof nodePositions;
  rightSideBarWidth: string;
  searchTerm: string;
  showLegend: boolean;
  showSelectedNodeFloatingPane: boolean;
  treeExpandedItems: string[];
  tweenGroup: Group;
  router: ReturnType<typeof useRouter>;
};

type BuilderRecipeState = {
  currentRecipe: BuilderRecipe;
  currentRecipeDirtyState: BuilderRecipe;
  customRecipes: BuilderRecipe[];
  deletePendingCustomRecipeIds: string[];
  lastDeleteCount?: number;
  descendantNodeIds: number[];
  isEditable: boolean;
  isReplay: boolean;
  preconditionCount: number;
  recipeCount: number;
  recipeId: string;
  selectedRecipeNode: GraphNodeData;
};

type BuilderActionsState = {
  addRecipeToNode: (
    recipe: RecipeDetailsFragment,
    nodeId: number,
    isPrecondition?: boolean,
    selectAfter?: boolean,
    addToTop?: boolean
  ) => Promise<void>;
  createNewRecipe: (
    name: string,
    id: string,
    description?: string
  ) => BuilderRecipe;
  currentRecipeToYaml: (createYamlOptions?: CreateYamlOptions) => string;
  deleteCustomRecipes: (ids: string[]) => Promise<void>;
  fetchCustomRecipes: () => Promise<void>;
  loadCustomRecipe: (id: string) => Promise<void>;
  loadCustomRecipeFromPersistedState: () => Promise<void>;
  loadLastCheckpoint: () => Promise<void>;
  loadRecipeFromData: (
    name: string,
    id: string,
    nodes: GraphNodeData[],
    links: GraphLink[],
    forceSave?: boolean
  ) => Promise<void>;
  loadRecipeFromDataTemporarily: (
    name: string,
    id: string,
    nodes: GraphNodeData[],
    links: GraphLink[]
  ) => Promise<void>;
  removeRecipeNode: (nodeId: number) => Promise<void>;
  reset: () => void;
  resetTreeExpandedItems: () => void;
  saveCustomRecipe: (
    recipe: BuilderRecipe,
    forceSave?: boolean,
    createCheckpoint?: boolean
  ) => Promise<void>;
  startNewRecipe: () => Promise<void>;
  treeCollapseAll: () => void;
  treeExpandAll: () => void;
  updateCurrentRecipeDetails: (
    name: string,
    id: string,
    description: string,
    nodeId?: number
  ) => Promise<void>;
  updateRootRecipe: (
    nodes: GraphNodeData[],
    links: GraphLink[],
    selectedNode?: number
  ) => void;
  updateSelectedRecipe: (selectedRecipeNodeId: number) => void;
  updateSelectedRecipeOptions: (
    options: RecipeDetailsFragment['options']
  ) => void;
};

export type BuilderState = BuilderDialogStates &
  BuilderUIStates &
  BuilderRecipeState &
  BuilderActionsState;

const builderDialogDefaults: BuilderDialogStates = {
  showDeleteCustomRecipeDialog: false,
  showDeleteRecipeDialog: false,
  showEditCurrentRecipeAsYamlDialog: false,
  showEditNestedRecipeDialog: false,
  showEditRecipeDialog: false,
  showEditRecipeOptionsDialog: false,
  showEditYamlDialog: false,
  showLoadCustomRecipePanel: false,
  showManageCustomRecipePanel: false,
  showNewRecipeDialog: false,
  showOverrideRecipeDialog: false,
  showPreconditionFinderPanel: false,
  showRecipeFinderPanel: false
};

const builderUIStateDefaults: BuilderUIStates = {
  builderLoading: false,
  builderSaving: false,
  cameraControlsRef,
  customCameraFunctions: null,
  debug: false,
  nodePositions,
  rightSideBarWidth: BUILDER_DEFAULT_RIGHT_SIDEBAR_WIDTH,
  searchTerm: '',
  showLegend: false,
  showSelectedNodeFloatingPane: true,
  treeExpandedItems: ['0'],
  tweenGroup: new Group(),
  router: null
};

const builderRecipesStateDefaults: BuilderRecipeState = {
  currentRecipe: null,
  currentRecipeDirtyState: null,
  customRecipes: [],
  deletePendingCustomRecipeIds: null,
  descendantNodeIds: [],
  isEditable: true,
  isReplay: false,
  preconditionCount: 0,
  recipeCount: 0,
  recipeId: null,
  selectedRecipeNode: null
};

const builderStateDefaults: NonFunctionProperties<BuilderState> = {
  ...builderUIStateDefaults,
  ...builderRecipesStateDefaults,
  ...builderDialogDefaults
};

export const useBuilderStore = createTemporalStore<BuilderState>(
  (set, get, store) => {
    store.temporal.getState().clear();
    store.temporal.getState().pause();

    const setWithCheckpoint = (
      currentState: BuilderState | Partial<BuilderState>
    ) => {
      store.temporal.getState().resume();
      set((previousState) => {
        const newState = {
          ...previousState,
          ...builderDialogDefaults,
          ...currentState
        };
        return newState;
      });
      store.temporal.getState().pause();
    };

    return {
      ...builderStateDefaults,
      loadLastCheckpoint: withLoading(async () => {
        store.temporal.getState().undo();
        const historicalState = get().currentRecipe;
        await get().saveCustomRecipe(historicalState, true, false);
      }),
      addRecipeToNode: withLoading(
        async (
          recipe,
          nodeId,
          isPrecondition,
          selectAfter = true,
          addToTop = false
        ) => {
          const { newRecipeState, nextAvailableId } =
            addRecipeDetailsFragmentToNode(
              get().currentRecipe,
              recipe,
              nodeId,
              isPrecondition,
              addToTop
            );

          await get().saveCustomRecipe(
            makeParentsCustomRecipe(nextAvailableId, newRecipeState)
          );

          if (selectAfter) {
            await get().updateSelectedRecipe(nextAvailableId);
          }
        }
      ),
      currentRecipeToYaml: (createYamlOptions = {}) => {
        const currentRecipe = get().currentRecipe;
        if (!currentRecipe) {
          return '';
        }
        const { nodes, links } = currentRecipe;
        return createYamlFromNodesAndLinks(nodes, links, createYamlOptions);
      },
      deleteCustomRecipes: async (ids: string[]) => {
        if (!Array.isArray(ids)) {
          return;
        }

        for (const id of ids) {
          const isInDb = await db?.builderRecipes?.get(id);
          if (isInDb) {
            await db?.builderRecipes?.delete(id);
          }
          get().fetchCustomRecipes();
          if (get().currentRecipe?.id === id) {
            set((state) => ({ ...state, currentRecipe: null }));
          }
        }
        set((state) => ({ ...state, lastDeleteCount: ids?.length || 0 }));
      },
      fetchCustomRecipes: async () => {
        const customRecipes = (await db?.builderRecipes?.toArray()) || [];
        set({ customRecipes });
      },
      loadCustomRecipe: withLoading(async (id: string) => {
        if (!id) {
          return;
        }

        if (id !== get().currentRecipe?.id) {
          store.temporal.getState().clear();
        }

        const recipe = await db?.builderRecipes?.get(id);
        // update localStorage and sessionStorage with the recipe id
        sessionStorage.setItem('customRecipeId', id);
        localStorage.setItem('customRecipeId', id);
        const newRecipe = { ...recipe, links: cleanLinks(recipe?.links) };

        set((state) => ({
          ...state,
          currentRecipe: newRecipe
        }));
      }),
      loadCustomRecipeFromPersistedState: withLoading(async () => {
        const idFromSessionStorage = sessionStorage.getItem('customRecipeId');
        const idFromLocalStorage = localStorage.getItem('customRecipeId');
        const id = idFromSessionStorage || idFromLocalStorage;
        if (id) {
          await get().loadCustomRecipe(id);
        }
      }),
      loadRecipeFromDataTemporarily: withLoading(
        async (name, id, nodes, links) => {
          set((state) => ({
            ...state,
            currentRecipe: {
              name,
              id,
              description: nodes.find((n) => n.id === ROOT_NODE_ID)?.val
                ?.description,
              nodes,
              links
            }
          }));
          await get().updateSelectedRecipe(ROOT_NODE_ID);
        }
      ),
      loadRecipeFromData: withLoading(
        async (name, id, nodes, links, forceSave = false) => {
          const newRecipe = {
            name,
            id,
            nodes,
            links
          };
          await get().saveCustomRecipe(newRecipe, forceSave);
          await get().updateSelectedRecipe(ROOT_NODE_ID);
        }
      ),
      createNewRecipe: (name, id, description) => {
        return {
          name,
          id,
          description,
          nodes: [
            {
              isCustomRecipe: true,
              id: ROOT_NODE_ID,
              name,
              val: {
                name,
                id,
                tags: [],
                totalRecipes: 0,
                options: []
              }
            }
          ],
          links: []
        };
      },
      startNewRecipe: async () => {
        const newRecipe = get().createNewRecipe('', '', '');
        await set((state) => ({
          ...state,
          currentRecipe: newRecipe
        }));
        await get().updateSelectedRecipe(ROOT_NODE_ID);
        set({ showNewRecipeDialog: true });
      },
      removeRecipeNode: withLoading(removeNode),
      reset: () => {
        const {
          currentRecipe: _, // omit will cause infinite rerender loop
          customRecipes: __, // omit after reading from db these don't change unless we are intentional
          builderLoading: ___, // omit, we don't want reset controlling this
          builderSaving: ____, // omit, we don't want reset controlling this
          router: _____,
          ...resetables
        } = builderStateDefaults;
        set((state) => ({ ...state, ...resetables }));
      },
      resetTreeExpandedItems: () => {
        set({ treeExpandedItems: ['0'] });
      },
      saveCustomRecipe: async (
        recipe,
        forceSave = true,
        createCheckpoint = true
      ) => {
        if (get().isReplay && !get().showOverrideRecipeDialog) {
          forceSave = false;
          await set({ isReplay: false });
        }
        await set((state) => ({
          ...state,
          currentRecipeDirtyState: recipe,
          builderSaving: true,
          showOverrideRecipeDialog: false
        }));

        if (!recipe?.id) {
          return;
        }

        if (!forceSave) {
          const shouldDeferToPrompt = await promptForRecipeOverride(recipe.id);
          if (shouldDeferToPrompt) {
            return await set({ builderSaving: false });
          }
        }

        const newRecipe = { ...recipe, links: cleanLinks(recipe?.links) };

        if (createCheckpoint) {
          setWithCheckpoint({
            currentRecipe: newRecipe,
            currentRecipeDirtyState: null,
            builderSaving: false,
            builderLoading: false
          });
        } else {
          set((state) => ({
            ...state,
            currentRecipe: newRecipe,
            currentRecipeDirtyState: null,
            builderSaving: false,
            builderLoading: false
          }));
        }
        // The db save is done after set to avoid flicker caused by db / state mismatch
        if (forceSave) {
          const isInDb = await db?.builderRecipes?.get(recipe.id);
          if (isInDb) {
            await db?.builderRecipes?.delete(recipe.id);
          }
        }
        await db?.builderRecipes?.put(newRecipe);
        await get().fetchCustomRecipes();

        if (get().router) {
          await get().router.push('/builder');
        }
      },
      treeExpandAll: () => {
        set({
          treeExpandedItems: get().currentRecipe?.nodes.map((n) => String(n.id))
        });
      },
      treeCollapseAll: () => {
        set({ treeExpandedItems: [] });
      },
      updateCurrentRecipeDetails: withLoading(updateCurrentRecipeDetails),
      updateSelectedRecipeOptions: withLoading(updateSelectedNodeOptions),
      updateRootRecipe: (nodes, links, selectedNodeId = ROOT_NODE_ID) => {
        set((state) => ({
          ...state,
          currentRecipe: {
            ...get().currentRecipe,
            nodes,
            links
          }
        }));

        get().updateSelectedRecipe(selectedNodeId);
      },
      updateSelectedRecipe: (selectedRecipeNodeId) => {
        let selectedRecipeNode;
        let recipeCount = 0;
        let preconditionCount = 0;
        let preconditionChildNodeIds = [];

        get().currentRecipe?.nodes.forEach((node) => {
          if (node.id === selectedRecipeNodeId) {
            selectedRecipeNode = node;
          }

          if (node.isPrecondition) {
            preconditionCount += 1;
            preconditionChildNodeIds = [
              ...new Set([
                ...preconditionChildNodeIds,
                ...findDescendantNodeIds(node.id, get().currentRecipe?.links)
              ])
            ];
          } else {
            if (preconditionChildNodeIds.includes(node.id)) {
              preconditionCount += 1;
            } else {
              // exclude the root node from the list count
              if (ROOT_NODE_ID !== node.id) {
                recipeCount += 1;
              }
            }
          }
        });

        const descendantNodeIds =
          selectedRecipeNode?.id === ROOT_NODE_ID
            ? get().currentRecipe?.nodes.reduce(
                (result, node) => [...result, node.id],
                []
              )
            : findDescendantNodeIds(
                selectedRecipeNodeId,
                get().currentRecipe?.links
              );

        set((state) => ({
          ...state,
          selectedRecipeNode,
          descendantNodeIds,
          recipeCount,
          preconditionCount
        }));
      }
    };
  },
  ['currentRecipe']
);
