import {
  GlobalKeysContext,
  type GlobalKeysContextState,
  type KeyBinding
} from '@bonsai-components/react-global-keys';
import React, { Component, type JSX, type ReactNode } from 'react';
import { CommandCheatSheet } from '../components/command-palette/command-cheatsheet.component';
import { CommandPalette } from '../components/command-palette/command-palette.component';
import {
  commandMenuIndexForShortcutSettings,
  searchCommandShortcutSettings
} from '../components/settings/manage-appearance/keyboard-shortcuts-settings';
import { LOCAL_STORAGE_USER_PREFERENCES } from '../constants/general';
import { getObjectFromLocalStorage } from '../helpers/local-storage.helper';
import { excludeActionFromInputMode } from '../hooks/use-command-palette.hooks';
import type { UserPreferences } from './user.context';

type CommandWithKeyBindingWithKey = {
  key: string;
  modifier?: KeyBinding['modifier'];
  includeInputMode?: boolean;
};

type CommandWithKeyBindingWithCode = {
  code: string;
  modifier?: KeyBinding['modifier'];
  includeInputMode?: boolean;
};

export type CommandWithKeyBinding =
  | CommandWithKeyBindingWithKey
  | CommandWithKeyBindingWithCode;

export const isCommandWithKeyBindingWithKey = (
  command: CommandWithKeyBinding
): command is CommandWithKeyBindingWithKey => {
  return (command as CommandWithKeyBindingWithKey).key !== undefined;
};

type CommandPaletteContextProps = {
  children: ReactNode[] | ReactNode;
};

export type CommandPaletteItem = {
  label: string;
  description?: string;
  keyboardShortcuts?: CommandWithKeyBinding | CommandWithKeyBinding[];
  adminOnly?: boolean;
  action: (args?) => void;
};

type CommandPaletteContextState = {
  commands: CommandPaletteItem[];
  showPalette: boolean;
  showCheatSheet: boolean;
  togglePalette: () => void;
  toggleCheatSheet: () => void;
  addCommands: (commands: CommandPaletteItem | CommandPaletteItem[]) => void;
  removeCommands: (commands: string | string[]) => void;
};

const initialState: CommandPaletteContextState = {
  commands: [],
  showPalette: false,
  showCheatSheet: false,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: initial state
  togglePalette: () => {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: initial state
  toggleCheatSheet: () => {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: initial state
  addCommands: (_commands: CommandPaletteItem | CommandPaletteItem[]) => {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: initial state
  removeCommands: (_commands: string | string[]) => {}
};

export const CommandPaletteContext = React.createContext(initialState);

export class CommandPaletteProvider extends Component<
  CommandPaletteContextProps,
  CommandPaletteContextState
> {
  static contextType = GlobalKeysContext;
  context: GlobalKeysContextState;
  readonly state: CommandPaletteContextState = {
    ...initialState,
    togglePalette: () => {
      this.setState(() => ({
        showPalette: !this.state.showPalette,
        showCheatSheet: false
      }));
    },
    toggleCheatSheet: () => {
      this.setState(() => ({
        showPalette: false,
        showCheatSheet: !this.state.showCheatSheet
      }));
    },
    addCommands: (commands) => {
      const newCommands = Array.isArray(commands) ? commands : [commands];

      this.setState((prevState) => ({
        commands: [
          ...prevState.commands,
          ...newCommands.filter(
            (newCmd) =>
              !prevState.commands.some((cmd) => cmd.label === newCmd.label)
          )
        ].sort((a, b) => a.label.localeCompare(b.label))
      }));
    },
    removeCommands: (commands) => {
      const newCommands = Array.isArray(commands) ? commands : [commands];

      this.setState((prevState) => ({
        commands: prevState.commands.filter(
          (cmd) => !newCommands.some((newCmd) => newCmd === cmd.label)
        )
      }));
    }
  };

  /**
   * On mount set the global key bindings based on the user preferences
   */
  componentDidMount(): void {
    // Grab user preferences from local storage directly (this.context in use for global keys)
    const localPreferences = getObjectFromLocalStorage<UserPreferences>(
      LOCAL_STORAGE_USER_PREFERENCES,
      {}
    );

    const preferredSetting =
      localPreferences?.commandMenuShortcut ||
      commandMenuIndexForShortcutSettings;

    const shortcut = searchCommandShortcutSettings[preferredSetting];

    shortcut?.bindings?.forEach((binding) => {
      this.context.addKeyBinding({
        ...binding,
        action: binding.includeInputMode
          ? this.state.togglePalette
          : (e) => {
              excludeActionFromInputMode(e, this.state.togglePalette);
            }
      });
    });

    this.context.addKeyBinding({
      key: '?',
      modifier: {
        shift: true
      },
      action: (e) => {
        excludeActionFromInputMode(e, this.state.toggleCheatSheet);
      }
    });
  }

  render(): JSX.Element {
    return (
      <CommandPaletteContext.Provider value={this.state}>
        {this.props.children}
        <CommandPalette />
        <CommandCheatSheet />
      </CommandPaletteContext.Provider>
    );
  }
}
