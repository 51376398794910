import type { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { LOCAL_STORAGE_USER_PREFERENCES } from '../constants/general';
import type { CategorySortingAlgorithm } from '../helpers/category.helper';
import { getObjectFromLocalStorage } from '../helpers/local-storage.helper';
import { createPersistedStore } from './store-creators';

export type BuilderTheme = 'dark' | 'light';
export const BUILDER_THEME_DEFAULT: BuilderTheme = 'light';

type UserPreferenceState = {
  showAllResults: boolean;
  builderLayout: '2D' | '3D';
  hasTopologyAsMainContent: boolean;
  show3DGraph: boolean;
  disableKeyboardShortcuts: boolean;
  recipeResultColumnVisibility: GridColumnVisibilityModel;
  dismissedAgentUpdates: string[];
  previousSearchQueries: string[];
  categorySortingAlgorithm: CategorySortingAlgorithm;
  builderTheme: BuilderTheme;
};

const initialization = (obj) => {
  if (!globalThis?.window?.localStorage) {
    return obj;
  }
  const old = getObjectFromLocalStorage<UserPreferenceState>(
    LOCAL_STORAGE_USER_PREFERENCES
  );

  if (!old) {
    return obj;
  }
  const {
    showAllResults,
    disableKeyboardShortcuts,
    recipeResultColumnVisibility,
    dismissedAgentUpdates,
    previousSearchQueries
  } = old;
  delete old.showAllResults;
  delete old.disableKeyboardShortcuts;
  delete old.recipeResultColumnVisibility;
  delete old.dismissedAgentUpdates;
  delete old.previousSearchQueries;

  localStorage.setItem(LOCAL_STORAGE_USER_PREFERENCES, JSON.stringify(old));
  return {
    ...obj,
    showAllResults,
    disableKeyboardShortcuts,
    recipeResultColumnVisibility,
    dismissedAgentUpdates,
    previousSearchQueries
  };
};

export const useUserPreferenceStore = createPersistedStore<UserPreferenceState>(
  () => ({
    categorySortingAlgorithm: 'Total recipe count',
    builderLayout: '3D',
    hasTopologyAsMainContent: true,
    show3DGraph: true,
    showAllResults: true,
    disableKeyboardShortcuts: false,
    previousSearchQueries: [],
    recipeResultColumnVisibility: {
      worker: false,
      astLoading: false,
      infoMarkers: false,
      warningMarkers: false,
      errorMarkers: false,
      debugMarkers: false,
      details: false,
      recipeRun: false,
      dependencyResolution: false,
      lastUpdated: false
    },
    dismissedAgentUpdates: [],
    builderTheme: BUILDER_THEME_DEFAULT
  }),
  {
    name: 'user-preference'
  },
  initialization
);
