import { List, Stack } from '@mui/material';
import {
  Children,
  type FunctionComponent,
  ReactNode,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { ConfigurationContext } from '../../../contexts/config.context';
import { AccountMenu } from '../../account-menu/account-menu.component';
import { CustomerBranding } from '../left-nav-items/customer-branding.component';
import { LeftNavigationRail } from '../left-nav-items/left-navigation.styled';
import { MoreMenuItem } from '../left-nav-items/more-menu-item.component';

type OverflowNavigationRailProps = {
  children: ReactNode;
};

export const OverflowNavigationRail: FunctionComponent<
  OverflowNavigationRailProps
> = ({ children }) => {
  const { config } = useContext(ConfigurationContext);
  const navRef = useRef<HTMLDivElement>(null);

  const allItems = Children.toArray(children);

  const [overflowIndex, setOverflowIndex] = useState<number>(allItems.length);

  // Calculate the divider index based on available nav height and item heights.
  useLayoutEffect(() => {
    const updateOverflowIndex = () => {
      const nav = navRef.current;
      if (!nav) {
        return;
      }

      const element = nav.children?.[1] as HTMLElement;
      if (!element) {
        return;
      }

      const elementHeight = element.offsetHeight + 8; // 8 px padding between items
      let totalHeight = elementHeight;
      const navHeight = nav.offsetHeight;
      const minElements = 1;

      let divider = allItems.length;

      // Add elements
      for (let i = 0; i < minElements && i < allItems.length; i++) {
        totalHeight += elementHeight;
      }
      // try until out of space
      for (let i = minElements; i < allItems.length; i++) {
        totalHeight += elementHeight;
        if (totalHeight > navHeight) {
          divider = i;
          break;
        }
      }
      setOverflowIndex(divider);
    };

    updateOverflowIndex();
    window.addEventListener('resize', updateOverflowIndex);
    return () => {
      window.removeEventListener('resize', updateOverflowIndex);
    };
  }, [allItems]);

  const visibleItems = useMemo(
    () => allItems.slice(0, overflowIndex),
    [allItems, overflowIndex]
  );
  const overflowItems = useMemo(
    () => allItems.slice(overflowIndex),
    [allItems, overflowIndex]
  );

  return (
    <LeftNavigationRail
      id="nav-destinations"
      sx={{
        background: 'white',
        borderColor: (theme) => `${theme.palette.newGreys[100]}`
      }}
    >
      <List
        ref={navRef}
        sx={{
          overflowY: 'hidden',
          overflowX: 'hidden',
          flexGrow: 1
        }}
      >
        {visibleItems.map((Item) => Item)}
        {overflowItems.length > 0 ? (
          <MoreMenuItem>{overflowItems.map((Item) => Item)}</MoreMenuItem>
        ) : null}
      </List>
      <Stack id="nav-account" gap={0.5}>
        <CustomerBranding config={config} />
        <AccountMenu />
      </Stack>
    </LeftNavigationRail>
  );
};
