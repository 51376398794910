import {
  Autocomplete,
  Box,
  Dialog,
  InputAdornment,
  TextField,
  Typography,
  dialogClasses
} from '@mui/material';
import { type FunctionComponent, useContext } from 'react';

import {
  CommandPaletteContext,
  type CommandPaletteItem
} from '../../contexts/command-palette.context';
import { CommandPaletteIcon } from '../../icons/icons';
import { backgroundColor } from '../../themes/moderne-theme';
import { KeyboardShortcuts } from '../keyboard-shortcuts/keyboard-shortcuts.component';
import { CenteredOpposingBoxes } from '../styled-components/layouts/layouts.styled';

export const CommandPalette: FunctionComponent = () => {
  const { commands, showPalette, togglePalette } = useContext(
    CommandPaletteContext
  );

  const handleChange = (e, newValue) => {
    newValue.action(e);
    togglePalette();
  };

  return (
    <Dialog
      open={showPalette}
      onClose={togglePalette}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          position: 'absolute',
          top: 50
        }
      }}
    >
      <Autocomplete
        sx={{ width: 500 }}
        options={commands}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            size="medium"
            fullWidth
            color="primary"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            sx={{ backgroundColor: backgroundColor('high') }}
            slotProps={{
              input: {
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <CommandPaletteIcon />
                  </InputAdornment>
                )
              }
            }}
          />
        )}
        renderOption={(props, option: CommandPaletteItem) => (
          <Box component="li" {...props}>
            <CenteredOpposingBoxes
              sx={{
                width: '100%'
              }}
            >
              <Typography variant="inherit">{option.label}</Typography>
              {option?.keyboardShortcuts && (
                <Box>
                  <KeyboardShortcuts shortcuts={option.keyboardShortcuts} />
                </Box>
              )}
            </CenteredOpposingBoxes>
          </Box>
        )}
        autoHighlight
      />
    </Dialog>
  );
};
