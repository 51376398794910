import { Box, Container, Divider, styled } from '@mui/material';

export const UncenteredContainer = styled(Container)`
  margin: unset
`;

export const OpposingBoxes = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'space-between'
}));

export const CenteredOpposingBoxes = styled(OpposingBoxes)(() => ({
  alignItems: 'center'
}));

export const CenteredBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center'
}));

export const InfoBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap'
}));

export const FlexBox = styled('div')(() => ({
  display: 'flex'
})) as typeof Box; //@see https://mui.com/material-ui/migration/upgrade-to-v6/#box

export const FullHeightBox = styled(Box)(() => ({
  minHeight: 'calc(100vh - 500px)'
}));

export const RoundedFieldSet = styled('fieldset')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.grey[400],
  borderStyle: 'solid',
  borderWidth: 1,
  textAlign: 'left'
}));

export const RoundedBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.grey[400],
  borderStyle: 'solid',
  borderWidth: 1,
  textAlign: 'left'
}));

export const SpacedDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));
