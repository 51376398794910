import pkceChallenge from 'pkce-challenge';

import type { PureFunction } from '@bonsai-components/utility-types';
import { encodeJSONBase64 } from './encoding.helper';
import { cleanUrlParams } from './link.helper';
import { getTenantName } from './next.helpers';

type AuthorizationStateBase = {
  redirectUri: string;
  origin: string;
  clientId: string;
};

type GitHubAuthzState = AuthorizationStateBase & {
  provider: 'github';
};

type GitLabAuthzState = AuthorizationStateBase & {
  provider: 'gitlab';
  codeVerifier: string;
};

type BitbucketCloudAuthzState = AuthorizationStateBase & {
  provider: 'bitbucket-cloud';
};

type AzureDevOpsAuthzState = AuthorizationStateBase & {
  provider: 'azure-devops';
  tenantId: string;
};

export type OAuthState =
  | GitHubAuthzState
  | GitLabAuthzState
  | BitbucketCloudAuthzState
  | AzureDevOpsAuthzState;

export const currentAuthorizationUrl = (queryString?: {
  [key: string]: string;
}): string => {
  const urlParams = new URLSearchParams(cleanUrlParams());
  if (queryString) {
    Object.entries(queryString).forEach(([key, value]) =>
      urlParams.set(key, value)
    );
  }
  if (urlParams.entries().next().done) {
    return `${window.location.origin}${window.location.pathname}`;
  } else {
    return `${window.location.origin}${window.location.pathname}?${urlParams}`;
  }
};

type GitHubOAuthRedirect = {
  clientId: string;
  origin: string;
};

export const getGitHubAuthorizationOptions: PureFunction<
  GitHubOAuthRedirect,
  URLSearchParams
> = ({ clientId, origin }): URLSearchParams => {
  if (!clientId) {
    throw new Error(
      `No GitHub OAuth client ID found for tenant ${getTenantName()}`
    );
  }

  const clientScopes = [
    'read:org',
    'read:user',
    'public_repo',
    'user:email',
    'workflow',
    'repo'
  ];

  return new URLSearchParams({
    client_id: clientId,
    redirect_uri: currentAuthorizationUrl({ origin }),
    state: encodeJSONBase64<OAuthState>({
      redirectUri: currentAuthorizationUrl(),
      provider: 'github',
      origin,
      clientId
    }),
    scope: clientScopes.join(',')
  });
};

type GitLabOAuthRedirect = {
  clientId: string;
  origin: string;
};
export const getGitLabAuthorizationOptions: PureFunction<
  GitLabOAuthRedirect,
  URLSearchParams
> = ({ clientId, origin }) => {
  if (!clientId) {
    throw new Error(
      `No GitLab OAuth client ID found for tenant ${getTenantName()}`
    );
  }

  const clientScopes = [
    'api',
    'read_user',
    'profile',
    'email',
    'write_repository'
  ];

  // Seed it, seed it _real_ good
  const { code_challenge, code_verifier } = pkceChallenge(128);

  /**
   * GitLab has some stringent rules around redirect_uri, so we need to only
   * use the TLD. We'll encode the actual current page and origin, to pass to
   * GraphQL so SCM can find the agent that has the right configuration, as
   * apart of the state which we can decode in `authorization.component.tsx`
   */
  const searchParameters = {
    client_id: clientId,
    redirect_uri: window.location.origin,
    response_type: 'code',
    state: encodeJSONBase64<OAuthState>({
      provider: 'gitlab',
      redirectUri: currentAuthorizationUrl(),
      origin,
      clientId,
      codeVerifier: code_verifier
    }),
    // Annoying that it's space and not comma like GitHub
    scope: clientScopes.join(' '),
    code_verifier,
    code_challenge,
    code_challenge_method: 'S256'
  };

  return new URLSearchParams(searchParameters);
};

type BitbucketCloudOAuthRedirect = {
  clientId: string;
  origin: string;
};

export const getBitbucketCloudAuthorizationOptions: PureFunction<
  BitbucketCloudOAuthRedirect,
  URLSearchParams
> = ({ clientId, origin }) => {
  if (!clientId) {
    throw new Error(
      `No Bitbucket OAuth client ID found for tenant ${getTenantName()}`
    );
  }

  const clientScopes = ['repository', 'repository:write', 'account', 'email'];
  const searchParameters = {
    client_id: clientId,
    redirect_uri: window.location.origin,
    response_type: 'code',
    state: encodeJSONBase64<OAuthState>({
      provider: 'bitbucket-cloud',
      redirectUri: currentAuthorizationUrl(),
      origin,
      clientId
    }),
    scopes: clientScopes.join(',')
  };

  return new URLSearchParams(searchParameters);
};

type AzureDevOpsOAuthRedirect = {
  clientId: string;
  tenantId: string;
  origin: string;
};

export const getAzureDevOpsAuthorizationOptions: PureFunction<
  AzureDevOpsOAuthRedirect,
  URLSearchParams
> = ({ clientId, tenantId, origin }) => {
  if (!clientId) {
    throw new Error(
      `No Azure DevOps OAuth clientId or tenantId found for tenant ${getTenantName()}`
    );
  }

  const clientScopes = [
    'https://app.vssps.visualstudio.com/vso.code_manage',
    'https://app.vssps.visualstudio.com/vso.graph'
  ];
  const searchParameters = {
    client_id: clientId,
    redirect_uri: window.location.origin,
    response_type: 'code',
    scope: clientScopes.join(' '),
    state: encodeJSONBase64<AzureDevOpsAuthzState>({
      provider: 'azure-devops',
      redirectUri: currentAuthorizationUrl(),
      origin,
      clientId,
      tenantId
    })
  };

  return new URLSearchParams(searchParameters);
};
