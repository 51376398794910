import { Breadcrumbs, Typography, breadcrumbsClasses } from '@mui/material';
import type { FunctionComponent, JSX } from 'react';

import { useCommonBreakpoints } from '../../../hooks/use-breakpoints.hooks';
import { ModerneMarkdown } from '../../utilities/markdown/markdown.component';
import { Link } from '../../utilities/moderne-link/moderne-link.component';

type BreadcrumbLink = {
  label: string;
  href?: string;
};

export type Breadcrumb = BreadcrumbLink | JSX.Element;

/**
 * Typeguard to decide whether a given object is a BreadcrumbLink.
 */
function isBreadCrumbLink(link: Breadcrumb): link is BreadcrumbLink {
  return (link as BreadcrumbLink).label !== undefined;
}

export const BreadcrumbsComponent: FunctionComponent<{
  links: Breadcrumb[];
}> = ({ links }) => {
  const { isMobileDevice } = useCommonBreakpoints();
  const defaultBreadcrumbs = []; //[{ label: 'Home', href: '/' }];
  const breadcrumbs = [...defaultBreadcrumbs, ...links];

  return breadcrumbs.length > 1 ? (
    <Breadcrumbs
      maxItems={isMobileDevice ? 2 : undefined}
      separator="&rsaquo;"
      aria-label="breadcrumb"
      sx={{
        [`& .${breadcrumbsClasses.separator}`]: {
          color: 'grey.600'
        }
      }}
    >
      {breadcrumbs.map((breadcrumb, index) => {
        return isBreadCrumbLink(breadcrumb) ? (
          <Typography
            key={index}
            variant="caption"
            sx={{
              color: 'grey.800'
            }}
          >
            {!breadcrumb?.href ? (
              <ModerneMarkdown>{breadcrumb.label}</ModerneMarkdown>
            ) : (
              <Link href={breadcrumb.href} key={index} underline="none">
                <ModerneMarkdown>{breadcrumb.label}</ModerneMarkdown>
              </Link>
            )}
          </Typography>
        ) : (
          breadcrumb
        );
      })}
    </Breadcrumbs>
  ) : null;
};
