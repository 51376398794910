import { usePopupState } from 'material-ui-popup-state/hooks';

import {
  SvgIcon,
  listItemClasses,
  listItemIconClasses,
  listItemTextClasses
} from '@mui/material';

import type { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import { useRouter } from 'next/router';
import { type FunctionComponent } from 'react';
import { type ReactNode } from 'react';
import { MoreIcon } from '../../../icons/icons';
import { MenuItemWithSubMenu } from './menu-with-sub-menu.component';

type NewType = {
  children: ReactNode;
};

export const MoreMenuItem: FunctionComponent<NewType> = ({ children }) => {
  const popoverMenu = usePopupState({
    variant: 'popover',
    popupId: 'more-menu'
  });
  const { pathname } = useRouter();

  const menuItem = {
    icon: <SvgIcon component={MoreIcon} inheritViewBox />,
    label: 'More',
    description: 'More',
    href: '#'
  };
  const selectedMenuItemRegex = /\/admin/i;

  return (
    <MenuItemWithSubMenu
      menuItem={menuItem}
      selected={selectedMenuItemRegex.test(pathname)}
      popoverState={popoverMenu}
      slotProps={{
        menuProps: {
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          sx: {
            left: '0.5rem',
            [`& .${listItemClasses.root}`]: {
              padding: 0
            },
            [`& .${listItemTextClasses.primary}`]: {
              fontWeight: 500,
              fontSize: '1rem',
              lineHeight: '1.5rem'
            },
            [`& .${listItemIconClasses.root}`]: {
              minWidth: 36,
              color: 'common.black'
            }
          }
        }
      }}
    >
      {children}
    </MenuItemWithSubMenu>
  );
};
