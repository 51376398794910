import { useRouter } from 'next/router';
import type { FunctionComponent } from 'react';

import { Button, Snackbar, Stack, snackbarContentClasses } from '@mui/material';

export const UpdateApplication: FunctionComponent = () => {
  const { reload } = useRouter();
  return (
    <Snackbar
      open
      autoHideDuration={120000}
      message={
        <Stack
          sx={{
            gap: 1
          }}
        >
          A new version of Moderne is available
          <Button
            sx={{ color: 'common.white' }}
            variant="outlined"
            color="inherit"
            fullWidth
            size="small"
            onClick={reload}
          >
            Reload
          </Button>
        </Stack>
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      sx={{
        [`& .${snackbarContentClasses.root}`]: {
          borderRadius: 0,
          boxShadow: 'none'
        }
      }}
    />
  );
};
