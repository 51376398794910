import { useApolloClient } from '@apollo/client';
import { Box, formControlLabelClasses } from '@mui/material';
import { signOut, useSession } from 'next-auth/react';
import { FunctionComponent, MouseEventHandler, useContext } from 'react';

import type { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import { useRouter } from 'next/router';
import { ACCOUNT_SETTINGS_PATH } from '../../constants/general';
import { SIGN_OUT } from '../../constants/messages';
import { CommandPaletteContext } from '../../contexts/command-palette.context';
import { UserContext } from '../../contexts/user.context';
import { useCommandPalette } from '../../hooks/use-command-palette.hooks';
import { AiSearchFeatureControl } from '../feature-controls/ai-search.component';
import { searchCommandShortcutSettings } from '../settings/manage-appearance/keyboard-shortcuts-settings';
import { CenteredOpposingBoxes } from '../styled-components/layouts/layouts.styled';
import { MenuBase } from '../utilities/menus/menu-base/menu-base.component';
import {
  MenuDivider,
  NavigationMenuItem,
  NavigationMenuItemButton
} from './account-menu.styled';
import { UserAvatarButton } from './user-avatar.component';
import { UserCard } from './user-card.component';

export const AccountMenu: FunctionComponent = () => {
  const client = useApolloClient();
  const { push } = useRouter();
  const { data: session } = useSession();
  const { togglePalette } = useContext(CommandPaletteContext);
  const {
    preferences: { commandMenuShortcut }
  } = useContext(UserContext);

  const handleLogout: MouseEventHandler<HTMLButtonElement> = async () => {
    /**
     * Use `clearStore` to avoid re-executing active queries
     * @see https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.clearStore
     */
    await client.clearStore();

    /**
     * We use an api route to do the keycloak logout redirect
     * It is important this is done before the signOut call so session id hint
     * is still available for the api route.
     */
    window.location.href = '/api/auth/keycloak/logout';
    await signOut({
      redirect: false
    });
  };

  const commandShortcut = searchCommandShortcutSettings[commandMenuShortcut];

  useCommandPalette([
    {
      label: 'Sign Out',
      action: handleLogout
    },
    {
      label: 'Go to Account settings',
      action: () => push(ACCOUNT_SETTINGS_PATH),
      keyboardShortcuts: {
        key: '~',
        modifier: {
          shift: true
        }
      }
    }
  ]);

  return (
    session?.user && (
      <MenuBase
        menuId="account-menu"
        MenuProps={{
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom'
          },
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top'
          },
          sx: {
            top: '.25rem',
            left: '0.5rem'
          }
        }}
        actionItem={<UserAvatarButton />}
      >
        <UserCard />
        <MenuDivider />
        <NavigationMenuItem>
          <NavigationMenuItemButton
            data-testid="account-menu-settings-button"
            href={ACCOUNT_SETTINGS_PATH}
          >
            Account settings
          </NavigationMenuItemButton>
        </NavigationMenuItem>
        <FeatureFlag>
          <AiSearchFeatureControl />
        </FeatureFlag>
        <NavigationMenuItem>
          <NavigationMenuItemButton
            data-testid="account-menu-command-button"
            onClick={togglePalette}
          >
            <CenteredOpposingBoxes>
              <Box
                sx={{
                  mr: 2
                }}
              >
                Command menu
              </Box>

              {!commandShortcut.disabled && (
                <Box
                  sx={{
                    fontSize: '.75rem'
                  }}
                >
                  {commandShortcut.shortcut}
                </Box>
              )}
            </CenteredOpposingBoxes>
          </NavigationMenuItemButton>
        </NavigationMenuItem>
        <MenuDivider />
        <NavigationMenuItem>
          <NavigationMenuItemButton
            data-testid="account-menu-logout-button"
            aria-label={SIGN_OUT}
            onClick={handleLogout}
          >
            Sign out
          </NavigationMenuItemButton>
        </NavigationMenuItem>
      </MenuBase>
    )
  );
};

const FeatureFlag: FunctionComponentWithChildren = ({ children }) => (
  <Box
    component="li"
    sx={{
      color: 'inherit',
      [`& .${formControlLabelClasses.root}`]: {
        justifyContent: 'space-between',
        width: '90%'
      },
      [`& .${formControlLabelClasses.label}`]: {
        fontWeight: 'fontWeightLight',
        fontSize: (theme) => theme.typography.pxToRem(14)
      }
    }}
  >
    {children}
  </Box>
);
