import { useLazyQuery } from '@apollo/client';
import { FunctionComponent, useContext, useEffect } from 'react';
import { GRAPHQL_POLLING_INTERVAL } from '../../constants/general';
import {
  type JobManifestRecord,
  JobNotificationContext,
  terminalFunctions
} from '../../contexts/job-notification.context';
import { showNotification } from '../../helpers/notification.helper';

type PollingJobProps = {
  job: JobManifestRecord;
};

/**
 * Responsible for using hooks to poll a job until it is complete and then
 * create a notification.  This component does not render anything to the dom
 * and is typically only used by the JobNotificationProvider.
 */
export const PollingJob: FunctionComponent<PollingJobProps> = ({ job }) => {
  const { removeJob } = useContext(JobNotificationContext);

  const [_loadData, { data, startPolling, stopPolling, error }] = useLazyQuery(
    ...job.polledQueryParameters
  );

  /**
   * Start polling when the component mounts and cleans up polling on unmount
   *
   * biome-ignore lint/correctness/useExhaustiveDependencies: only run once on mount
   */
  useEffect(() => {
    startPolling(GRAPHQL_POLLING_INTERVAL);

    return () => {
      stopPolling();
      removeJob(job.id);
    };
  }, []);

  /**
   * Stop polling when the job is complete
   */
  useEffect(() => {
    if (terminalFunctions[job.terminalFunctionKey]?.call(this, data) || error) {
      stopPolling();
      removeJob(job.id);
      showNotification('Job done', job.message, job.url);
    }
  }, [
    data,
    error,
    job.id,
    job.message,
    job.url,
    job.terminalFunctionKey,
    stopPolling,
    removeJob
  ]);

  return null;
};
