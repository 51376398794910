import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import kebabcase from 'lodash.kebabcase';
import React, { type FunctionComponent } from 'react';
import {
  type ScmConfiguration,
  useScmConfigurationsQuery
} from '../../__generated__/apollo-hooks';
import { accountSettingsUrl } from '../../helpers/link.helper';
import { useScmAuthorization } from '../../hooks/use-authorization.hooks';
import { ScmDisconnectIcon } from '../../icons/icons';
import { ScmBadge } from '../styled-components/badges/badges.styled';
import { Link } from '../utilities/moderne-link/moderne-link.component';
import { ScmConfigurationIcon } from '../utilities/scm-type-icon/scm-type-icon.component';

export const ScmConnectionButton: FunctionComponent<{
  configuration: Pick<
    ScmConfiguration,
    '__typename' | 'resourceId' | 'isAuthorized'
  >;
  disableAction?: boolean;
}> = ({ configuration, disableAction = false }) => {
  const { __typename, isAuthorized, resourceId } = configuration;

  const { handleAuthorization } = useScmAuthorization();

  const handleClick = (resourceId: string) => {
    handleAuthorization(resourceId);
  };

  return disableAction ? (
    <ScmBadge
      overlap="circular"
      color={isAuthorized ? 'success' : 'default'}
      variant="standard"
      badgeContent={
        !isAuthorized && <ScmDisconnectIcon color="error" fontSize="inherit" />
      }
    >
      <ScmConfigurationIcon configuration={configuration} />
    </ScmBadge>
  ) : (
    <Tooltip
      title={
        isAuthorized
          ? `Connected to ${configuration.resourceId}`
          : `Authorize your current session with ${configuration.resourceId}`
      }
    >
      <ScmBadge
        overlap="circular"
        color={isAuthorized ? 'success' : 'default'}
        variant="standard"
        badgeContent={
          !isAuthorized && (
            <ScmDisconnectIcon color="error" fontSize="inherit" />
          )
        }
      >
        <IconButton
          data-testid={`global-scm-connection-${kebabcase(__typename)}-button`}
          size="small"
          color="inherit"
          disabled={isAuthorized || disableAction}
          onClick={() => handleClick(resourceId)}
        >
          <ScmConfigurationIcon configuration={configuration} />
        </IconButton>
      </ScmBadge>
    </Tooltip>
  );
};

export const ScmConnections: FunctionComponent = () => {
  const { data } = useScmConfigurationsQuery();

  const hasOverflow = data?.scms?.length && data?.scms?.length > 3;

  if (!data?.scms?.length) {
    return (
      <Typography variant="caption" color="primary">
        No SCMs at present!
      </Typography>
    );
  }

  return (
    // Generated by Copilot
    <React.Fragment>
      {[...(data?.scms || [])]
        ?.sort((a, b) => a.resourceId?.localeCompare(b.resourceId))
        .filter((scm) => scm.valid)
        .sort((a, b) => {
          if (a.isAuthorized === b.isAuthorized) {
            return a.resourceId?.localeCompare(b.resourceId);
          }
          return a.isAuthorized ? -1 : 1;
        })
        .slice(0, 3)
        .map((configuration, i) => {
          const { __typename, resourceId } = configuration;

          return (
            <Box
              key={[__typename, resourceId, i].join('-')}
              sx={{
                marginRight: 0.66
              }}
            >
              <ScmConnectionButton configuration={configuration} />
            </Box>
          );
        })}
      {hasOverflow && (
        <IconButton
          href={accountSettingsUrl('connection')}
          disableRipple
          LinkComponent={Link}
        >
          <Chip
            label={`+${data?.scms?.length - 3}`}
            size="small"
            sx={{
              color: 'primary.main',
              backgroundColor: '#F2F4F8'
            }}
          />
        </IconButton>
      )}
    </React.Fragment>
  );
};
