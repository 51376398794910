import type { PopupState } from 'material-ui-popup-state/hooks';
import { createStore } from './store-creators';

type PopoverStoreState = {
  popover: PopupState;
};

export const usePopoverStore = createStore<PopoverStoreState>(() => ({
  popover: null
}));
