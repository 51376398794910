import { Box, Stack, Typography } from '@mui/material';
import { type FunctionComponent } from 'react';
import { useAuthorization } from '../../hooks/use-authorization.hooks';
import { useCommonBreakpoints } from '../../hooks/use-breakpoints.hooks';
import { Link } from '../utilities/moderne-link/moderne-link.component';

type DeploymentBannerProps = {
  isAdministrator: boolean;
};

export const DeploymentBanner: FunctionComponent<DeploymentBannerProps> = ({
  isAdministrator
}) => {
  const { isMobileDevice } = useCommonBreakpoints();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      bgcolor="violet.700"
      height={{ xs: '24px', sm: '30px' }}
    >
      <Stack
        alignItems="center"
        direction="row"
        color="white"
        gap={0.5}
        height={{ xs: '24px', sm: '30px' }}
      >
        {/* TODO: Design still deciding on this icon */}
        {/* <InfoIcon fontSize="inherit" color="inherit" /> */}
        <Box>
          <Typography
            display="inline"
            fontWeight={{ xs: 'medium', sm: 'bold' }}
            fontSize={12}
            marginRight={0.5}
          >
            Platform update in progress{isMobileDevice ? '' : ':'}
          </Typography>
          {!isMobileDevice && (
            <Typography display={'inline'} variant="caption">
              Some features may be briefly affected. Thanks for bearing with us!
            </Typography>
          )}
          {isAdministrator && (
            <Typography display={'inline'} variant="caption">
              {' '}
              See status{' '}
              <Link href="/admin/status" color="inherit" underline="always">
                here
              </Link>{' '}
              for updates.
            </Typography>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export const DeploymentBannerWithAuth = () => {
  const { isAdministrator } = useAuthorization();
  return <DeploymentBanner isAdministrator={isAdministrator} />;
};
