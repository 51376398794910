import {
  Button,
  type ButtonProps,
  Divider,
  type DividerProps,
  MenuItem,
  styled
} from '@mui/material';
import { Link } from '../utilities/moderne-link/moderne-link.component';

export const NavigationMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: 0,
  padding: 0,
  fontsize: theme.typography.pxToRem(14)
}));

export const NavigationMenuItemButton = styled(
  (props: ButtonProps & { external?: boolean }) => (
    <Button size="large" fullWidth LinkComponent={Link} {...props} />
  )
)(({ theme }) => ({
  color: 'inherit',
  fontSize: 'inherit',
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightLight,
  justifyContent: 'flex-start',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(0.75),
  paddingBottom: theme.spacing(0.75),
  '& :hover': {
    textDecoration: 'none'
  }
}));

export const MenuDivider = (props: DividerProps) => (
  <Divider flexItem variant="fullWidth" sx={{ marginY: 1 }} {...props} />
);
