import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import type { FunctionComponent, ReactNode } from 'react';

import {
  CopyPasta,
  type CopyPastaProps
} from '../../components/utilities/copy-pasta/copy-pasta.component';
import { backgroundColor } from '../../themes/moderne-theme';

export type CodeSnippetProps = {
  children: string;
  color?: 'dark' | 'light';
  copyButtonPlacement?: 'top' | 'center';
  marginLeft?: number | string;
  removeCopyPasteButton?: boolean;
  size?: 'medium' | 'tiny';
  disableFlexGrow?: boolean;
  slots?: {
    footer?: ReactNode;
  };
  slotProps?: {
    copyButton?: Pick<CopyPastaProps, 'text'>;
  };
};

const useResponsiveFontSize = (size: 'medium' | 'tiny') => {
  const {
    typography: { pxToRem }
  } = useTheme();
  switch (size) {
    case 'medium':
      return {
        xs: pxToRem(10),
        md: pxToRem(12)
      };
    default:
      return pxToRem(10);
  }
};

export const CodeSnippet: FunctionComponent<CodeSnippetProps> = ({
  children,
  color = 'dark',
  copyButtonPlacement = 'center',
  marginLeft = 1,
  removeCopyPasteButton = false,
  size = 'medium',
  slots = {},
  slotProps = {}
}) => {
  const isMedium = size === 'medium';
  const isDark = color === 'dark';
  const fontSize = useResponsiveFontSize(size);
  const padding = isMedium ? 1 : 0.5;

  return (
    <Card variant="outlined">
      <CardContent
        sx={{
          padding,
          ['&:last-child']: { paddingBottom: padding },
          marginBottom: 0,
          color: isDark ? 'white' : 'grey.700',
          backgroundColor: isDark ? 'grey.700' : backgroundColor('high')
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems:
              copyButtonPlacement === 'center' ? 'center' : 'flex-start'
          }}
        >
          <Typography
            component="code"
            sx={{
              flexGrow: 1,
              marginLeft: marginLeft,
              wordBreak: 'break-all',
              whiteSpace: 'pre-wrap',
              fontFamily: 'fontFamilyMonospace',
              fontSize
            }}
          >
            {children}
          </Typography>
          {!removeCopyPasteButton && (
            <Box
              sx={{
                marginLeft: 2
              }}
            >
              <CopyPasta text={children} {...slotProps?.copyButton} />
            </Box>
          )}
        </Box>
      </CardContent>
      {slots?.footer}
    </Card>
  );
};
