import isDeepEqual from 'fast-deep-equal';
import { type TemporalState, temporal } from 'zundo';
import { type StateCreator, type StoreApi, create } from 'zustand';
import { type PersistOptions, persist } from 'zustand/middleware';

const PERSISTED_STORE_KEY_PREFIX = 'moderne:';

type StoreUpdater<T> = {
  update: (update: Partial<T>) => void;
};

export type Store<T> = T & StoreUpdater<T>;

type PersistedStore<T> = [['zustand/persist', T]];
type TemporalStore<T> = [['temporal', StoreApi<TemporalState<T>>]];
type TemporalStateCreator<T> = StateCreator<
  T,
  [['zustand/devtools', never], ['temporal', StoreApi<TemporalState<T>>]]
>;

type Write<T, U> = Omit<T, keyof U> & U;
type WithDevtools<S> = Write<S, [['zustand/devtools', never]]>;

type ReturnedTemporalStore<T> = Write<
  WithDevtools<StoreApi<Store<T>>>,
  { temporal: StoreApi<TemporalState<T>> }
>;

export function createTemporalStore<T>(
  obj: TemporalStateCreator<T>,
  fields: (keyof T)[]
) {
  return create<Store<T>, TemporalStore<Store<T>>>(
    temporal(
      (set, get, store: ReturnedTemporalStore<T>) => {
        return {
          ...obj(set, get, store),
          update: (update) => {
            store.temporal.getState().pause();
            set((state) => ({ ...state, ...update }) as Store<T>);
          }
        };
      },
      {
        partialize: (state) => {
          return fields.reduce(
            (acc, key) => {
              acc[key] = state[key];
              return acc;
            },
            {} as Partial<T>
          );
        },
        equality: isDeepEqual,
        limit: 10
      }
    )
  );
}

export function createPersistedStore<T>(
  obj: StateCreator<T>,
  options: PersistOptions<Store<T>>,
  initialization?: (o: T) => T
) {
  return create<Store<T>, PersistedStore<Store<T>>>(
    persist(
      (set, get, state) => {
        const migratedState = initialization?.(obj(set, get, state));

        return {
          ...(migratedState || obj(set, get, state)),
          update: (update) =>
            set((state) => ({ ...state, ...update }) as Store<T>)
        };
      },
      {
        ...options,
        name: `${PERSISTED_STORE_KEY_PREFIX}${options.name}`
      }
    )
  );
}

export function createStore<T>(obj: StateCreator<T>) {
  return create<Store<T>>((set, get, store) => {
    return {
      ...obj(set, get, store),
      update: (update) => set((state) => ({ ...state, ...update }) as Store<T>)
    };
  });
}
