import type { PureFunction } from '@bonsai-components/utility-types';
import {
  ADMIN_ACCESS_TOKENS_PATH,
  AUDIT_LOGS_PATH,
  INTEGRATIONS_PATH,
  QUARANTINED_DASHBOARD_PATH,
  REPORTS_PATH,
  USERS_PATH,
  WORKERS_DASHBOARD_PATH
} from '../../../constants/general';
import {
  AccessTokenIcon,
  AgentsIcon,
  AuditLogsIcon,
  QuarantinedIcon,
  ReportsIcon,
  WorkerIcon
} from '../../../icons/icons';
import { ModOrganizationsIcon } from '../../../icons/moderne-icons';
import { NavItem } from '../left-navigation/left-navigation.component';

export const getLeftNavAdminMenuItems: PureFunction<
  void,
  Array<NavItem>
> = () => [
  {
    label: 'Agents',
    href: INTEGRATIONS_PATH,
    icon: <AgentsIcon />,
    description: 'View connected agents',
    shortcut: 'g'
  },
  {
    label: 'Audit logs',
    href: AUDIT_LOGS_PATH,
    icon: <AuditLogsIcon />,
    description: 'View audit logs',
    shortcut: 'l'
  },
  {
    label: 'Workers',
    href: WORKERS_DASHBOARD_PATH,
    icon: <WorkerIcon />,
    description: 'View connected workers',
    shortcut: 'w'
  },
  {
    label: 'Quarantine',
    href: QUARANTINED_DASHBOARD_PATH,
    icon: <QuarantinedIcon />,
    description: 'View repositories in quarantined state',
    shortcut: 'q'
  },
  {
    label: 'Reports',
    href: REPORTS_PATH,
    icon: <ReportsIcon />,
    description: 'Download usage reports',
    shortcut: 'p'
  },
  {
    label: 'Access tokens',
    href: ADMIN_ACCESS_TOKENS_PATH,
    icon: <AccessTokenIcon />,
    description: 'Users with access tokens',
    shortcut: 't'
  },
  {
    label: 'Users',
    href: USERS_PATH,
    icon: <ModOrganizationsIcon />,
    description: 'Users of the platform',
    shortcut: 'u'
  }
];
