import dd from 'dedent';
import camelCase from 'lodash.camelcase';
import words from 'lodash.words';

export const findWords = (input: string): string[] => words(input);

export const findCustomWords = (input: string, pattern: RegExp): string[] =>
  words(input, pattern);

export const capitalize = (input: string): string =>
  input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();

/**
 * Jonathan's preferred casing
 *
 * Example:
 * sentenceCase('This Is An Example') -> 'This is an example'
 */
export const sentenceCase = (str: string): string => {
  return findWords(str)
    .map((word, i) => (i === 0 ? capitalize(word) : word.toLowerCase()))
    .join(' ');
};

/**
 * Pascal cases the provided string. Example: ThisIsPascalCase
 */
export const pascalCase = (str: string): string => {
  const camel = camelCase(str);
  return camel.charAt(0).toUpperCase() + camel.slice(1);
};

export const printArrayStringOption = (arr: string[]): string =>
  `[${arr.join(',')}]`;

// biome-ignore lint/suspicious/noExplicitAny: migration from eslint
export const parseJwt = (token: string): any => {
  const base64Url = token?.split('.')?.at(1);
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
};

/**
 * Removes a trailing comma from a string if one exists
 */
export const removeTrailingComma = (str: string): string =>
  str.replace(/,$/g, '');

export const pluralize = (str: string, count: number): string => {
  if (count === 1) {
    return str;
  }
  return `${str}s`;
};

export const repeatChar = (n: number, str: string): string =>
  Array(n + 1).join(str);

// function to remove all non-alpha-numeric characters and lowercase
export const cleanString = (str: string): string => {
  return str?.replace(/[^0-9a-z]/gi, '-').toLowerCase();
};

export const screamingSnakeToSentenceCase = (str: string): string => {
  return capitalize(str).replaceAll('_', ' ');
};

// function to convert pascal case to sentence case
export const pascalToSentenceCase = (str: string): string => {
  return sentenceCase(
    str
      ?.replace(/([A-Z])/g, ' $1')
      .trim()
      .toLowerCase()
  );
};

// dedent function that returns the dedented version of a string a returns as the generic type
export const dedent = <T extends string>(str: string): T => {
  return dd(str) as T;
};

/**
 * Identifies words in the string and truncates the middle of the string
 *
 * lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet 1
 * lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet 2
 *
 * lorem ipsum dolor...sit amet 1
 * lorem ipsum dolor...sit amet 2
 */
export const truncateMiddle = (str: string) => {
  const words = findWords(str);
  if (words.length > 8) {
    return `${words.at(0)} ${words.at(1)} ${words.at(2)} ... ${words.at(
      -3
    )} ${words.at(-2)} ${words.at(-1)}`;
  } else {
    return str;
  }
};

export const convertToMarkdownLink = (
  text: string,
  target: string,
  url: string
): string => {
  const regex = new RegExp(`(?<!\\[)${target}(?!\\]\\([^)]*\\))`, 'g');
  return text.replace(regex, `[${target}](${url})`);
};
