import { Dialog } from '@mui/material';
import type { GridRowId } from '@mui/x-data-grid-pro';
import type { FunctionComponent } from 'react';
import { repositoryIdToRepositoryFragment } from '../../../helpers/repository.helpers';
import { ClosableDialogTitle } from '../../utilities/dialog/closable-dialog-title.component';
import type { OrganizationRowData } from '../organizations.rows';
import { UserOrganizationEditor } from './user-organization-editor.component';

type UserOrganizationDialogProps = {
  orgRowData?: OrganizationRowData;
  open: boolean;
  onClose: (e) => void;
  repositoryIds?: GridRowId[];
  copy?: boolean;
  fullScreen?: boolean;
};

export const UserOrganizationDialog: FunctionComponent<
  UserOrganizationDialogProps
> = ({
  orgRowData,
  open = false,
  onClose,
  repositoryIds,
  copy,
  fullScreen = true
}) => {
  const dialogTitle =
    orgRowData && !copy
      ? `Edit user organization: ${orgRowData?.name}`
      : 'New user organization';

  return (
    <Dialog fullWidth fullScreen={fullScreen} open={open} onClose={onClose}>
      <ClosableDialogTitle onClose={onClose} title={dialogTitle} />
      <UserOrganizationEditor
        copy={copy}
        id={orgRowData?.id}
        toggleDialog={onClose}
        immutableRepositories={
          repositoryIds
            ? repositoryIds.map(repositoryIdToRepositoryFragment)
            : undefined
        }
      />
    </Dialog>
  );
};
