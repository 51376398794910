import type { ThemeOptions } from '@mui/material';
import {
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
  CheckboxUncheckedIcon
} from '../../icons/icons';

export const ModMuiCheckbox: ThemeOptions['components']['MuiCheckbox'] = {
  defaultProps: {
    checkedIcon: <CheckboxCheckedIcon />,
    icon: <CheckboxUncheckedIcon />,
    indeterminateIcon: <CheckboxIndeterminateIcon />
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.grey['700'],
      '&.MuiCheckbox-indeterminate': {
        color: theme.palette.grey['700']
      },
      '&.Mui-checked': {
        color: theme.palette.grey['700']
      },
      '&.Mui-checked.Mui-disabled': {
        color: theme.palette.grey['500']
      },
      '&.Mui-disabled': {
        color: theme.palette.grey['500']
      }
    })
  }
};
