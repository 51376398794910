import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material';
import kebabcase from 'lodash.kebabcase';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FunctionComponent } from 'react';
import { determineHref } from '../../../helpers/link.helper';
import {
  excludeActionFromInputMode,
  useCommandPalette
} from '../../../hooks/use-command-palette.hooks';
import { NavItem } from '../left-navigation/left-navigation.component';

type LeftNavigationMenuItemProps = NavItem & {
  onClick?: () => void;
  registerCommand?: boolean;
};
export const LeftNavigationMenuItem: FunctionComponent<
  LeftNavigationMenuItemProps
> = ({
  href,
  shortcut,
  label,
  description,
  icon,
  onClick,
  registerCommand = false
}) => {
  const { asPath, push } = useRouter();
  const selectedMenuItemRegex = new RegExp(`^${href}`, 'i');

  const shouldRegisterCommand = registerCommand && shortcut;

  useCommandPalette(
    shouldRegisterCommand
      ? [
          {
            label: `Go to ${label}`,
            description: description,
            action: (e) =>
              excludeActionFromInputMode(e, (_e) => push(determineHref(href))),
            keyboardShortcuts: shortcut
              ? {
                  key: shortcut
                }
              : undefined
          }
        ]
      : [],
    [href, shortcut, label, description, icon, onClick, registerCommand]
  );

  return (
    <Tooltip title={description} placement="right" arrow>
      <ListItem>
        <ListItemButton
          data-testid={`global-nav-${kebabcase(label)}-button`}
          selected={selectedMenuItemRegex.test(asPath)}
          href={determineHref(href)}
          onClick={onClick}
          LinkComponent={Link}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};
