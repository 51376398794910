import type { FunctionComponent } from 'react';
import {
  type PossibleScmConfiguration,
  ScmConfigurationIcon
} from '../../utilities/scm-type-icon/scm-type-icon.component';

type ScmOriginCellProps = {
  origin: string;
  scmType: PossibleScmConfiguration['__typename'];
};

export const ScmOriginCell: FunctionComponent<ScmOriginCellProps> = ({
  origin,
  scmType
}) => (
  <ScmConfigurationIcon
    configuration={{
      __typename: scmType
    }}
    SvgIconProps={{
      titleAccess: origin,
      fontSize: 'small'
    }}
  />
);
