import { Link, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { useRouter } from 'next/router';
import { HOME_PAGE_PATH } from '../../../constants/general';
import {
  excludeActionFromInputMode,
  useCommandPalette
} from '../../../hooks/use-command-palette.hooks';

export const HomeMenuItem = () => {
  const { push } = useRouter();
  useCommandPalette({
    label: 'Go to Home',
    action: () => (e) =>
      excludeActionFromInputMode(e, () => push(HOME_PAGE_PATH)),
    keyboardShortcuts: {
      key: 'h'
    }
  });

  return (
    <ListItem
      sx={{
        // using margin to compensate for the lack of a text label on the
        // moderne icon
        marginBottom: 1
      }}
    >
      <ListItemButton href={HOME_PAGE_PATH} LinkComponent={Link}>
        <ListItemIcon>
          <img src="/moderne-icon.svg" alt="Moderne" height={24} />
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
};
