import type { PureFunction } from '@bonsai-components/utility-types';
import {
  type DataGridProProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellCheckboxRenderer,
  type GridColDef
} from '@mui/x-data-grid-pro';
import { filterlessSortlessGridCol } from '../../helpers/data-grid.helper';
import {
  type OrganizationActionColumnProps,
  organizationActionColumn
} from './organizations-actions-column';
import {
  MY_ORGANIZATIONS_GROUPING,
  type OrganizationRowData
} from './organizations.rows';

type OrganizationColumnProps = {
  ActionColumnProps: OrganizationActionColumnProps;
};
export const getOrganizationColumns: PureFunction<
  OrganizationColumnProps,
  GridColDef<OrganizationRowData>[]
> = ({ ActionColumnProps }) => [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    minWidth: 40,
    width: 40,
    resizable: false,
    renderHeader: () => null,
    renderCell: (params) =>
      params.row?.id !== MY_ORGANIZATIONS_GROUPING ? (
        <GridCellCheckboxRenderer
          {...params}
          // @ts-expect-error - type is limited to html input when it should take a generic
          inputProps={{
            tabIndex: 0,
            'aria-label': params.row?.id
          }}
        />
      ) : null
  },
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    ...filterlessSortlessGridCol
  },
  {
    field: 'isOrganization',
    type: 'boolean'
  },
  organizationActionColumn(ActionColumnProps)
];

export const groupingColDef: DataGridProProps['groupingColDef'] = {
  headerName: 'Organization',
  minWidth: 200,
  flex: 1,
  disableColumnMenu: true,
  hideDescendantCount: true,
  filterable: false,
  sortable: false,
  pinnable: false,
  sortingOrder: ['asc', 'desc', null]
};
