import type { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Tab,
  type TabProps,
  Tabs,
  type TabsProps,
  Tooltip,
  styled,
  tabClasses
} from '@mui/material';
import type { FunctionComponent, ReactNode } from 'react';
import { Link } from '../../utilities/moderne-link/moderne-link.component';

export const ModerneTabs: FunctionComponentWithChildren<TabsProps> = ({
  children,
  ...rest
}) => (
  <TabContext value={rest.value}>
    <Tabs {...rest}>{children}</Tabs>
  </TabContext>
);

const BaseModerneTab = styled((props: ModerneTabProps) => (
  <Tab LinkComponent={Link} {...props} />
))(({ theme, variant = 'standard' }) => ({
  color: theme.palette.indigo[600],
  [`&.${tabClasses.selected}`]: {
    backgroundColor: theme.palette.background.paper,
    ...(variant === 'standard'
      ? {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          border: '1px solid',
          borderColor: theme.palette.divider,
          color: theme.palette.indigo[600]
        }
      : {})
  },
  ...(variant === 'no-outline'
    ? {
        paddingBottom: theme.spacing(1.5),
        [`&.${tabClasses.root}`]: {
          justifyContent: 'left'
        }
      }
    : {})
}));

export type ModerneTabProps = {
  href?: string;
  /**
   * The text to be displayed in a tooltip when the tab is disabled.
   */
  disabledTitle?: string | ReactNode;
  /**
   * The data test id to be applied to the tab.
   */
  ['data-testid']: string;
  variant?: 'standard' | 'no-outline';
} & TabProps;

/**
 * `ModerneTab` serves a few purposes:
 * - Adds a tooltip to the tab when it is disabled.
 * - Applies a Moderne-specific style to the selected tab.
 * - Provides a work-around for a type error with a styled-component instance
 * of `Tab` that has a `href` for a prop
 */
export const ModerneTab: FunctionComponent<ModerneTabProps> = ({
  disabledTitle,
  ...props
}) =>
  props.disabled && disabledTitle ? (
    <Tooltip title={disabledTitle} arrow>
      <span>
        <BaseModerneTab {...props} />
      </span>
    </Tooltip>
  ) : (
    <BaseModerneTab {...props} />
  );

export const ModerneTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: 0,
  paddingTop: theme.spacing(2),
  backgroundColor: theme.palette.background.paper
}));
