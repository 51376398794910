import type {
  FunctionComponent,
  MouseEventHandler,
  ReactNode,
  TouchEvent
} from 'react';

import {
  Button,
  type ButtonProps,
  IconButton,
  type SxProps,
  type Theme,
  Tooltip
} from '@mui/material';

import { CollapseIcon, ExpandIcon } from '../../../icons/icons';

type ExpandCollapseProps = {
  variant?: 'icon' | 'iconWithText';
  disableRipple?: boolean;
  expandText?: string;
  collapseText?: string;
  collapseIcon?: ReactNode;
  expandIcon?: ReactNode;
  isExpanded: boolean;
  color?: ButtonProps['color'];
  onClick: MouseEventHandler<Element>;
  onTouchStart?: (event: TouchEvent<Element>) => void;
  'aria-controls'?: string;
  'aria-describedby'?: string;
  'aria-haspopup'?: boolean;
  sx?: SxProps<Theme>;
};

export const ExpandCollapse: FunctionComponent<ExpandCollapseProps> = ({
  variant = 'icon',
  expandText = 'Show',
  collapseText = 'Hide',
  collapseIcon = <CollapseIcon />,
  expandIcon = <ExpandIcon />,
  isExpanded,
  color = 'secondary',
  onClick,
  ...rest
}) => {
  const testId = `${isExpanded ? 'collapse' : 'expand'}-button`;
  if (variant === 'iconWithText') {
    return (
      <Button
        data-testid={testId}
        color={color}
        startIcon={isExpanded ? collapseIcon : expandIcon}
        onClick={onClick}
        size="small"
        {...rest}
      >
        {isExpanded ? collapseText : expandText}
      </Button>
    );
  } else {
    return (
      <Tooltip placement="bottom" title="Expand all">
        <IconButton
          data-testid={testId}
          color={color}
          onClick={onClick}
          size="small"
          {...rest}
        >
          {isExpanded ? collapseIcon : expandIcon}
        </IconButton>
      </Tooltip>
    );
  }
};
