import type { FunctionComponent, MouseEventHandler, ReactNode } from 'react';

import {
  ButtonGroup,
  DialogTitle,
  type DialogTitleProps,
  IconButton,
  SxProps
} from '@mui/material';

import { CloseIcon } from '../../../icons/icons';
import { CenteredOpposingBoxes } from '../../styled-components/layouts/layouts.styled';

export const ClosableDialogTitle: FunctionComponent<
  {
    title: string;
    onClose: MouseEventHandler;
    secondaryButton?: ReactNode;
    closeButtonOverrides?: SxProps;
  } & DialogTitleProps
> = ({
  title,
  secondaryButton,
  onClose,
  closeButtonOverrides = {},
  ...titleProps
}) => (
  <CenteredOpposingBoxes>
    <DialogTitle {...titleProps}>{title}</DialogTitle>
    <ButtonGroup sx={{ margin: 1, ...closeButtonOverrides }}>
      {secondaryButton}
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </ButtonGroup>
  </CenteredOpposingBoxes>
);
