import { type FunctionComponent, useEffect, useState } from 'react';

import { Box, Button, ButtonGroup } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { FlexBox } from '../../styled-components/layouts/layouts.styled';

export const CursorPagination: FunctionComponent<{
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  loading: boolean;
}> = ({ hasNextPage, hasPreviousPage, loading }) => {
  const apiRef = useGridApiContext();
  const {
    current: { state }
  } = apiRef;

  const [pageRange, setPageRange] = useState<
    undefined | { startIndex: number; endIndex: number }
  >();
  const totalCount = state?.pagination?.rowCount;
  const { pageSize, page } = state?.pagination?.paginationModel ?? {};

  useEffect(() => {
    if (pageSize && totalCount) {
      const startIndex = page * pageSize + 1;
      const endIndex = Math.min(startIndex + pageSize - 1, totalCount);
      setPageRange({ startIndex, endIndex });
    }
  }, [totalCount, pageSize, page]);

  const handleNextPage = () => {
    const nextPage = state.pagination.paginationModel.page + 1;
    apiRef.current.setPage(nextPage);
  };

  const handlePreviousPage = () => {
    const prevPage = state.pagination.paginationModel.page - 1;
    apiRef.current.setPage(prevPage > 0 ? prevPage : 0);
  };

  return (
    <Box
      sx={{
        mx: 2
      }}
    >
      <FlexBox
        sx={{
          alignItems: 'center'
        }}
      >
        {pageRange && (
          <Box
            sx={{
              mr: 1
            }}
          >
            {pageRange.startIndex} - {pageRange.endIndex} of {totalCount}
          </Box>
        )}
        <ButtonGroup size="small" variant="contained">
          {hasPreviousPage && (
            <Button onClick={handlePreviousPage} disabled={loading}>
              Previous
            </Button>
          )}
          {hasNextPage && (
            <Button onClick={handleNextPage} disabled={loading}>
              Next
            </Button>
          )}
        </ButtonGroup>
      </FlexBox>
    </Box>
  );
};
