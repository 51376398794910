import { Box, type BoxProps } from '@mui/material';
import type { FunctionComponent } from 'react';

export const EMOJIS = {
  accessDenied: '🙈',
  beeeeeez: '🐝',
  chat: '💬',
  cloudy: '🌤',
  down: '👇',
  facepalm: '🤦',
  fox: '🦊',
  info: 'ℹ️',
  left: '👈',
  medal: '🏅',
  okay: '👍',
  peace: '✌️',
  racoon: '🦝',
  rainy: '🌧',
  right: '👉',
  robot: '🤖',
  rocket: '🚀',
  sad: '😿',
  safety_bear: '🧸',
  squid: '🦑',
  star: '⭐️',
  success: '🎉',
  sunny: '☀️',
  tag: '🏷️',
  thinking: '🤔',
  up: '👆',
  wave: '👋',
  whale: '🐳',
  whisper: '🤫'
};

type EmojiComponentProps = {
  name?: keyof typeof EMOJIS;
  random?: boolean;
  noMargin?: boolean;
} & BoxProps;

/**
 * Return an ARIA compliant Emoji character
 */
export const Emoji: FunctionComponent<EmojiComponentProps> = ({
  name,
  random,
  noMargin = false,
  sx = {},
  ...rest
}) => {
  const selectedEmoji =
    !random && name
      ? name
      : Object.keys(EMOJIS)[
          Math.floor(Math.random() * Object.keys(EMOJIS).length)
        ];

  return (
    <Box
      component="span"
      role="img"
      aria-label={selectedEmoji}
      sx={{ marginRight: !noMargin ? 1 : 0, ...sx }}
      {...rest}
    >
      {EMOJIS[selectedEmoji]}
    </Box>
  );
};
