import React, { Component, type JSX, type ReactNode } from 'react';
import type { StoredDownloadJob } from '../@types/repository-groups';
import type { RepositoryFragment } from '../__generated__/apollo-hooks';
import { DownloadManager } from '../components/download-manager/download-manager.component';
import { DOHERTY_THRESHOLD } from '../constants/general';
import { db } from '../helpers/indexedDb.helper';

import Drawer from '@mui/material/Drawer';
import { CenteredBox } from '../components/styled-components/layouts/layouts.styled';
import { requestNotificationPermission } from '../helpers/notification.helper';
import { type UUID, uuid } from '../helpers/uuid.helper';
import { UserContext, type UserContextState } from './user.context';

type DownloadsContextProps = {
  children: ReactNode[] | ReactNode;
};

export type DownloadState = 'PENDING' | 'SUCCESS' | 'FAILURE' | 'DOWNLOADING';

export const REMOVE_DOWNLOAD_DELAY = DOHERTY_THRESHOLD * 6;

export enum ManifestKind {
  DATA_TABLE = 0,
  AUTHENTICATED = 1,
  AUTHORIZED = 2
}

export type DownloadManifestIndexDb = {
  indexDbId?: UUID;
  state?: DownloadState;
  startTimestamp?: number;
};

export type DownloadDataTableManifest = DownloadManifestIndexDb & {
  id: string;
  kind: ManifestKind.DATA_TABLE;
  fileName: string;
  format: string;
  onDownloadEnd?: () => void;
};

type GenericDownloadManifest = {
  id: string;
  fileName: string;
  sourcePath?: string;
  onDownloadStart?: () => Promise<string>;
  onDownloadEnd?: () => void;
};

export type AuthenticatedDownloadManifest = GenericDownloadManifest & {
  kind: ManifestKind.AUTHENTICATED;
};

export type AuthorizedDownloadManifest = GenericDownloadManifest & {
  kind: ManifestKind.AUTHORIZED;
  repository: RepositoryFragment;
};

export type DownloadManifest = (
  | DownloadDataTableManifest
  | AuthenticatedDownloadManifest
  | AuthorizedDownloadManifest
) &
  DownloadManifestIndexDb;

type DownloadsContextState = {
  showDownloads: boolean;
  minimized: boolean;
  downloads: DownloadManifest[];
  manageDownload: (download: DownloadManifest) => void;
  updateDownloadState: (downloadJobId: UUID, state: DownloadState) => void;
  removeDownload: (fileName: string) => void;
  toggleMinimize: () => void;
  resetState: () => void;
};

const initialState: DownloadsContextState = {
  showDownloads: false,
  minimized: false,
  downloads: [],
  // biome-ignore lint/suspicious/noEmptyBlockStatements: initial state
  manageDownload: () => {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: initial state
  updateDownloadState: () => {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: initial state
  removeDownload: () => {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: initial state
  toggleMinimize: () => {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: initial state
  resetState: () => {}
};

export const DownloadsContext = React.createContext(initialState);
/**
 * Right now we only will use this to download data tables but this component
 * is intended to be a general purpose download manager.
 */
export class DownloadsProvider extends Component<
  DownloadsContextProps,
  DownloadsContextState
> {
  static contextType?: React.Context<UserContextState> = UserContext;
  context: UserContextState;

  readonly state: DownloadsContextState = {
    ...initialState,
    manageDownload: async (download: DownloadManifest) => {
      await requestNotificationPermission();
      const downloadsFromIndexedDb = await this.getDownloadJobsFromIndexedDb();

      download.indexDbId = uuid();
      download.startTimestamp = Date.now();
      download.state = 'PENDING';

      this.setState((prevState) => {
        const downloads = this.dedupeDownloads([
          ...prevState.downloads,
          ...downloadsFromIndexedDb,
          download
        ]);

        return {
          showDownloads: true,
          downloads,
          minimized: false
        };
      });
    },
    updateDownloadState: async (downloadJobId: UUID, state: DownloadState) => {
      this.setState((prevState) => {
        const downloads = [...prevState.downloads];
        const download = downloads.find((d) => d.indexDbId === downloadJobId);

        if (!download || download.state === state) {
          return;
        }

        download.state = state;

        return {
          downloads
        };
      });
    },
    removeDownload: (fileName: string) => {
      this.setState((prevState) => {
        const downloads = prevState.downloads.filter(
          (d) => d.fileName !== fileName
        );
        const showDownloads = downloads.length > 0;

        if (!showDownloads) {
          this.context.setPreferences({
            minimizeDownloadManager: initialState.minimized
          });
        }

        return {
          downloads,
          showDownloads
        };
      });
    },
    resetState: () => {
      this.setState(() => ({
        downloads: initialState.downloads,
        showDownloads: initialState.showDownloads,
        minimized: initialState.minimized
      }));
      this.context.setPreferences({
        minimizeDownloadManager: initialState.minimized
      });
    },
    toggleMinimize: () => {
      this.setState((prevState) => {
        const minimized = !prevState.minimized;
        this.context.setPreferences({ minimizeDownloadManager: minimized });

        return {
          minimized
        };
      });
    }
  };

  private visibilityHandler = async () => {
    if (document.visibilityState === 'visible') {
      const downloadsFromIndexedDb = await this.getDownloadJobsFromIndexedDb();
      const { minimizeDownloadManager } =
        this.context.getDefaultsFromLocalStorage();

      this.setState((prevState) => {
        const downloads = this.dedupeDownloads([
          ...prevState.downloads,
          ...downloadsFromIndexedDb
        ]);

        return {
          downloads,
          minimized: minimizeDownloadManager,
          showDownloads: downloads.length > 0
        };
      });
    }
  };

  async componentDidMount(): Promise<void> {
    const downloadsFromIndexedDb = await this.getDownloadJobsFromIndexedDb();

    this.setState((prevState) => {
      const downloads = this.dedupeDownloads([
        ...prevState.downloads,
        ...downloadsFromIndexedDb
      ]);

      return {
        minimized: this.context.preferences.minimizeDownloadManager,
        downloads,
        showDownloads: downloads.length > 0
      };
    });

    document.addEventListener(
      'visibilitychange',
      this.visibilityHandler,
      false
    );
  }

  async componentDidUpdate(
    _prevProps,
    prevState: DownloadsContextState
  ): Promise<void> {
    if (prevState.downloads !== this.state.downloads) {
      await this.updateIndexedDB();
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      'visibilitychange',
      this.visibilityHandler,
      false
    );
  }

  private dedupeDownloads = (
    downloads: DownloadManifest[]
  ): DownloadManifest[] =>
    downloads.reduce((acc, download) => {
      if (acc.some((d) => d.fileName === download.fileName)) {
        return acc;
      } else {
        return [...acc, download];
      }
    }, []);

  // #region IndexedDB
  private async updateIndexedDB(): Promise<void> {
    await db.downloadJobs.clear();
    await db.downloadJobs.bulkAdd(
      this.state.downloads
        .filter((download) => download.kind === ManifestKind.DATA_TABLE)
        .map(this.mapDownloadManifestToDownloadJob)
    );
  }

  private async getDownloadJobsFromIndexedDb(): Promise<DownloadManifest[]> {
    const jobs: StoredDownloadJob[] = await db.downloadJobs.toArray();
    return jobs?.map(this.mapDownloadJobDownloadManifest);
  }

  private mapDownloadManifestToDownloadJob = (
    downloadManifest: DownloadManifest
  ): StoredDownloadJob => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onDownloadEnd, ...manifest } = downloadManifest;

    return {
      id: manifest.indexDbId,
      manifest: JSON.stringify(manifest)
    };
  };

  private mapDownloadJobDownloadManifest = (
    job: StoredDownloadJob
  ): DownloadManifest => {
    const manifest = JSON.parse(job.manifest) as DownloadManifest;
    return manifest;
  };
  // #endregion

  /**
   * We render a snackbar within the provider so that we can have the
   * DownloadManager component can use hooks to access the context.
   */
  render(): JSX.Element {
    return (
      <>
        <DownloadsContext.Provider value={this.state}>
          {this.props.children}
          <Drawer
            anchor="bottom"
            variant="persistent"
            sx={{
              ['& .MuiDrawer-paper']: {
                background: 'transparent',
                border: 'none',
                paddingTop: (theme) => theme.spacing(3),
                pointerEvents: 'none'
              }
            }}
            open={this.state.showDownloads}
          >
            <CenteredBox>
              <DownloadManager downloadManifests={this.state.downloads} />
            </CenteredBox>
          </Drawer>
        </DownloadsContext.Provider>
      </>
    );
  }
}
