import type { PureFunction } from '@bonsai-components/utility-types';
import { getCurrentLocale } from '../helpers/i18n.helper';

type i18nUtilities = {
  formatNumber: PureFunction<
    { value: number; humanReadable?: boolean },
    string
  >;
};

/**
 * Keeping this non-exported until such a use case arises.
 */
const currentUserLocale = getCurrentLocale();
const localeNumberFormatter = Intl.NumberFormat(currentUserLocale);
const humanReadableLocaleNumberFormatter = Intl.NumberFormat(
  currentUserLocale,
  {
    notation: 'compact',
    maximumSignificantDigits: 3
  }
);

export const useI18n = (): i18nUtilities => {
  const formatNumber: i18nUtilities['formatNumber'] = ({
    value,
    humanReadable = false
  }) => {
    return humanReadable
      ? humanReadableLocaleNumberFormatter.format(value)
      : localeNumberFormatter.format(value);
  };

  return { formatNumber };
};
