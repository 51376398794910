import type { GridFilterOperator } from '@mui/x-data-grid-pro';
import { DateRangeFilter } from '../../date-range-filter/date-range-filter.component';

export const dateRangeFilterOperator: GridFilterOperator = {
  label: 'Date range',
  value: 'dateRange',
  InputComponent: DateRangeFilter,

  getApplyFilterFn: () => {
    return null;
  }
};
