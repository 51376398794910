import { type RecentRecipeDBEntry, db } from '../helpers/indexedDb.helper';
import { createStore } from './store-creators';

const RECENT_RECIPE_RETENTION_LIMIT = 25;

type RecentRecipeStore = {
  recentRecipes: Set<RecentRecipeDBEntry>;
  addRecipe: (id: string, name: string, description: string) => Promise<void>;
  hasRecipe: (id: string) => boolean;
  getRecentRecipes: () => RecentRecipeDBEntry[];
  initializeStore: () => Promise<void>;
  updateRecentRecipes: (
    recentRecipes: Set<RecentRecipeDBEntry>
  ) => Promise<void>;
};

export const useRecentRecipeStore = createStore<RecentRecipeStore>(
  (set, get) => ({
    recentRecipes: new Set<RecentRecipeDBEntry>(),

    addRecipe: async (id: string, name: string, description: string) => {
      const recipe: RecentRecipeDBEntry = {
        id,
        name,
        description,
        createdAt: Date.now()
      };
      const recentRecipes = get().recentRecipes;

      recentRecipes.add(recipe);
      await db.recentRecipes.put(recipe);

      const sortedRecipes = Array.from(recentRecipes).sort(
        (a, b) => b.createdAt - a.createdAt
      );

      // If there are more than the limit of recipes, remove the oldest recipes
      if (sortedRecipes.length > RECENT_RECIPE_RETENTION_LIMIT) {
        const limitedRecipes = sortedRecipes.slice(
          0,
          RECENT_RECIPE_RETENTION_LIMIT
        );
        get().updateRecentRecipes(new Set(limitedRecipes));
      } else {
        get().updateRecentRecipes(new Set(sortedRecipes));
      }
    },

    hasRecipe: (id: string) => {
      for (const obj of get().recentRecipes) {
        if (obj.id === id) {
          return true;
        }
      }
      return false;
    },

    getRecentRecipes: () => Array.from(get().recentRecipes),

    updateRecentRecipes: async (recentRecipes: Set<RecentRecipeDBEntry>) => {
      await db.recentRecipes.clear();
      await db.recentRecipes.bulkPut(Array.from(recentRecipes));
      set({ recentRecipes });
    },

    initializeStore: async () => {
      const allRecipes = await db.recentRecipes.toArray();
      const recipeSet = new Set(allRecipes);
      set({ recentRecipes: recipeSet });
    }
  })
);
