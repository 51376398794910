import { createSvgIcon } from '@mui/material';
import AccessTokenIcon from '../public/moderne-icons/AccessTokenIcon.svg';
import AddColumnLeftIcon from '../public/moderne-icons/AddColumnLeftIcon.svg';
import AddColumnRightIcon from '../public/moderne-icons/AddColumnRightIcon.svg';
import AgentsIcon from '../public/moderne-icons/AgentsIcon.svg';
import AiIcon from '../public/moderne-icons/AiIcon.svg';
import AppleIcon from '../public/moderne-icons/AppleIcon.svg';
import ArrowCircleDown from '../public/moderne-icons/ArrowCircleDown.svg';
import ArrowCircleUp from '../public/moderne-icons/ArrowCircleUpIcon.svg';
import ArrowUpFilledIcon from '../public/moderne-icons/ArrowUpFilledIcon.svg';
import AuditLogIcon from '../public/moderne-icons/AuditLogIcon.svg';
import BasicRecipeIcon from '../public/moderne-icons/BasicRecipeIcon.svg';
import BatchChangesIcon from '../public/moderne-icons/BatchChangesIcon.svg';
import BitbucketIcon from '../public/moderne-icons/BitbucketIcon.svg';
import BranchIcon from '../public/moderne-icons/BranchIcon.svg';
import BuilderIcon from '../public/moderne-icons/Builder.svg';
import CancelIcon from '../public/moderne-icons/CancelIcon.svg';
import CanceledIcon from '../public/moderne-icons/CanceledIcon.svg';
import CheckboxCheckedIcon from '../public/moderne-icons/CheckboxCheckedIcon.svg';
import CheckboxIndeterminateIcon from '../public/moderne-icons/CheckboxIndeterminateIcon.svg';
import CheckboxUncheckedIcon from '../public/moderne-icons/CheckboxUncheckedIcon.svg';
import CheckedOutlineIcon from '../public/moderne-icons/CheckedOutlineIcon.svg';
import ChevronDownIcon from '../public/moderne-icons/ChevronDownIcon.svg';
import ChevronRightIcon from '../public/moderne-icons/ChevronRightIcon.svg';
import CollapseAllIcon from '../public/moderne-icons/CollapseAllIcon.svg';
import CollapseIcon from '../public/moderne-icons/CollapseIcon.svg';
import ColumnsIcon from '../public/moderne-icons/ColumnsIcon.svg';
import CommandPalletIcon from '../public/moderne-icons/CommandPalletIcon.svg';
import ComposeRecipeIcon from '../public/moderne-icons/ComposeRecipeIcon.svg';
import CreateNewIcon from '../public/moderne-icons/CreateNewIcon.svg';
import CsvIcon from '../public/moderne-icons/CsvIcon.svg';
import DatatablesIcon from '../public/moderne-icons/DatatablesIcon.svg';
import DeleteIcon from '../public/moderne-icons/DeleteIcon.svg';
import DeployRecipesIcon from '../public/moderne-icons/DeployRecipesIcon.svg';
import DevCenterIcon from '../public/moderne-icons/DevCenterIcon.svg';
import DiscordIcon from '../public/moderne-icons/DiscordIcon.svg';
import DoubleArrowReset from '../public/moderne-icons/DoubleArrowReset.svg';
import DragHandleIcon from '../public/moderne-icons/DragHandleIcon.svg';
import EditIcon from '../public/moderne-icons/EditIcon.svg';
import EllipsisIcon from '../public/moderne-icons/EllipsisIcon.svg';
import EmailIcon from '../public/moderne-icons/EmailIcon.svg';
import ErrorIcon from '../public/moderne-icons/ErrorIcon.svg';
import ExcelIcon from '../public/moderne-icons/ExcelIcon.svg';
import ExpandAllIcon from '../public/moderne-icons/ExpandAllIcon.svg';
import ExportIcon from '../public/moderne-icons/ExportIcon.svg';
import ExternalLinkIcon from '../public/moderne-icons/ExternalLinkIcon.svg';
import FileAddedIcon from '../public/moderne-icons/FileAddedIcon.svg';
import FileModifiedIcon from '../public/moderne-icons/FileModifiedIcon.svg';
import FileRemovedIcon from '../public/moderne-icons/FileRemovedIcon.svg';
import FileRenamedIcon from '../public/moderne-icons/FileRenamedIcon.svg';
import FilterListIcon from '../public/moderne-icons/FilterListIcon.svg';
import GitHubIcon from '../public/moderne-icons/GitHubIcon.svg';
import GitLabIcon from '../public/moderne-icons/GitLabIcon.svg';
import GraphQLIcon from '../public/moderne-icons/GraphQLIcon.svg';
import HelpIcon from '../public/moderne-icons/HelpIcon.svg';
import HistoryIcon from '../public/moderne-icons/HistoryIcon.svg';
import ImportIcon from '../public/moderne-icons/ImportIcon.svg';
import InfoIcon from '../public/moderne-icons/InfoIcon.svg';
import JsonIcon from '../public/moderne-icons/JSONIcon.svg';
import LeftClickRotateIcon from '../public/moderne-icons/LeftClickRotateIcon.svg';
import LegendHideIcon from '../public/moderne-icons/LegendHideIcon.svg';
import LegendShowIcon from '../public/moderne-icons/LegendShowIcon.svg';
import LinkIcon from '../public/moderne-icons/LinkIcon.svg';
import LinuxIcon from '../public/moderne-icons/LinuxIcon.svg';
import MarketplaceIcon from '../public/moderne-icons/MarketplaceIcon.svg';
import MinusFilledIcon from '../public/moderne-icons/MinusFilledIcon.svg';
import MinusIcon from '../public/moderne-icons/MinusIcon.svg';
import ModerneLogo from '../public/moderne-icons/ModerneLogo.svg';
import MoonIcon from '../public/moderne-icons/MoonIcon.svg';
import OrganizationsIcon from '../public/moderne-icons/OrganizationsIcon.svg';
import QuarantinedIcon from '../public/moderne-icons/QuarantinedIcon.svg';
import RainyIcon from '../public/moderne-icons/RainyIcon.svg';
import RecommendedSortIcon from '../public/moderne-icons/RecommendedSortIcon.svg';
import ReloadIcon from '../public/moderne-icons/ReloadIcon.svg';
import RepositoryIcon from '../public/moderne-icons/RepositoryIcon.svg';
import RightClickPanIcon from '../public/moderne-icons/RightClickPanIcon.svg';
import SaveFloppyIcon from '../public/moderne-icons/SaveFloppyIcon.svg';
import SaveToBrowser from '../public/moderne-icons/SaveToBrowser.svg';
import ScrollWheelIcon from '../public/moderne-icons/ScrollWheelIcon.svg';
import SearchIcon from '../public/moderne-icons/SearchIcon.svg';
import SettingsIcon from '../public/moderne-icons/SettingsIcon.svg';
import ShareIcon from '../public/moderne-icons/ShareIcon.svg';
import SlackIcon from '../public/moderne-icons/SlackIcon.svg';
import SortAZAlphaIcon from '../public/moderne-icons/SortAZAlphaIcon.svg';
import SortZAAlphaIcon from '../public/moderne-icons/SortZAAlphaIcon.svg';
import StopCircleIcon from '../public/moderne-icons/StopCircleIcon.svg';
import SunnyIcon from '../public/moderne-icons/SunnyIcon.svg';
import SunnySpellsIcon from '../public/moderne-icons/SunnySpellsIcon.svg';
import TagIcon from '../public/moderne-icons/TagIcon.svg';
import UndoIcon from '../public/moderne-icons/UndoIcon.svg';
import UnlinkIcon from '../public/moderne-icons/UnlinkIcon.svg';
import UploadIcon from '../public/moderne-icons/UploadIcon.svg';
import WarningIcon from '../public/moderne-icons/WarningIcon.svg';
import WindowsIcon from '../public/moderne-icons/WindowsIcon.svg';
import WorkerIcon from '../public/moderne-icons/WorkerIcon.svg';

export const ModAccessTokenIcon = createSvgIcon(
  AccessTokenIcon(),
  'AccessTokenIcon'
);

export const ModAiIcon = createSvgIcon(AiIcon(), 'ModAiIcon');
export const ModdyIcon = createSvgIcon(AgentsIcon(), 'ModdyIcon');
export const ModAppleIcon = createSvgIcon(AppleIcon(), 'ModAppleIcon');
export const ModArrowCircleDown = createSvgIcon(
  ArrowCircleDown(),
  'ModArrowCircleDown'
);
export const ModArrowCircleUp = createSvgIcon(
  ArrowCircleUp(),
  'ModArrowCircleUp'
);
export const ModArrowUpFilledIcon = createSvgIcon(
  ArrowUpFilledIcon(),
  'ModArrowUpFilledIcon'
);
export const ModAuditLogIcon = createSvgIcon(AuditLogIcon(), 'ModAuditLogIcon');
export const ModBasicRecipeIcon = createSvgIcon(
  BasicRecipeIcon(),
  'ModBasicRecipeIcon'
);
export const ModBitbucketIcon = createSvgIcon(
  BitbucketIcon(),
  'ModBitbucketIcon'
);
export const ModBranchIcon = createSvgIcon(BranchIcon(), 'ModBranchIcon');

export const ModBuilderIcon = createSvgIcon(BuilderIcon(), 'ModBuilderIcon');
export const ModCancelIcon = createSvgIcon(CancelIcon(), 'ModCancelIcon');
export const ModCanceledIcon = createSvgIcon(CanceledIcon(), 'ModCanceledIcon');

export const ModCheckboxCheckedIcon = createSvgIcon(
  CheckboxCheckedIcon(),
  'ModCheckboxCheckedIcon'
);

export const ModCheckboxIndeterminateIcon = createSvgIcon(
  CheckboxIndeterminateIcon(),
  'ModCheckboxIndeterminateIcon'
);

export const ModCheckboxUncheckedIcon = createSvgIcon(
  CheckboxUncheckedIcon(),
  'ModCheckboxUncheckedIcon'
);

export const ModCheckedOutlineIcon = createSvgIcon(
  CheckedOutlineIcon(),
  'ModCheckedOutlineIcon'
);

export const ModChevronRightIcon = createSvgIcon(
  ChevronRightIcon(),
  'ModChevronRightIcon'
);

export const ModCollapseIcon = createSvgIcon(CollapseIcon(), 'ModCollapseIcon');
export const ModColumnsIcon = createSvgIcon(ColumnsIcon(), 'ModColumnsIcon');
export const ModCollapseAllIcon = createSvgIcon(
  CollapseAllIcon(),
  'ModCollapseAllIcon'
);

export const ModCommandPalletIcon = createSvgIcon(
  CommandPalletIcon(),
  'ModCommandPalletIcon'
);
export const ModComposeRecipeIcon = createSvgIcon(
  ComposeRecipeIcon(),
  'ModComposeRecipeIcon'
);

export const ModCsvIcon = createSvgIcon(CsvIcon(), 'ModCsvIcon');
export const ModDatatablesIcon = createSvgIcon(
  DatatablesIcon(),
  'ModDatatablesIcon'
);

export const ModDeleteIcon = createSvgIcon(DeleteIcon(), 'ModDeleteIcon');
export const ModDeployRecipesIcon = createSvgIcon(
  DeployRecipesIcon(),
  'ModDeployRecipesIcon'
);
export const ModDevCenterIcon = createSvgIcon(DevCenterIcon(), 'DevCenterIcon');

export const ModDiscordIcon = createSvgIcon(DiscordIcon(), 'ModDiscordIcon');
export const ModDoubleArrowIcon = createSvgIcon(
  DoubleArrowReset(),
  'ModDoubleArrowIcon'
);

export const ModDragHandleIcon = createSvgIcon(
  DragHandleIcon(),
  'ModDragHandleIcon'
);

export const ModErrorIcon = createSvgIcon(ErrorIcon(), 'ModErrorIcon');
export const ModExcelIcon = createSvgIcon(ExcelIcon(), 'ModExcelIcon');
export const ModJsonIcon = createSvgIcon(JsonIcon(), 'ModJsonIcon');
export const ModExpandAllIcon = createSvgIcon(
  ExpandAllIcon(),
  'ModExpandAllIcon'
);
export const ModChevronDownIcon = createSvgIcon(
  ChevronDownIcon(),
  'ModChevronDownIcon'
);
export const ModExportIcon = createSvgIcon(ExportIcon(), 'ModExportIcon');
export const ModExternalLinkIcon = createSvgIcon(
  ExternalLinkIcon(),
  'ModExternalLinkIcon'
);

export const ModFileAddedIcon = createSvgIcon(
  FileAddedIcon(),
  'ModFileAddedIcon'
);
export const ModFileModifiedIcon = createSvgIcon(
  FileModifiedIcon(),
  'ModFileModifiedIcon'
);
export const ModFileRemovedIcon = createSvgIcon(
  FileRemovedIcon(),
  'ModFileRemovedIcon'
);
export const ModFileRenamedIcon = createSvgIcon(
  FileRenamedIcon(),
  'ModFileRenamedIcon'
);

export const ModFilterListIcon = createSvgIcon(
  FilterListIcon(),
  'ModFilterListIcon'
);

export const ModGitHubIcon = createSvgIcon(GitHubIcon(), 'ModGitHubIcon');
export const ModGitLabIcon = createSvgIcon(GitLabIcon(), 'ModGitLabIcon');

export const ModGraphQLIcon = createSvgIcon(GraphQLIcon(), 'ModGraphQLIcon');
export const ModHelpIcon = createSvgIcon(HelpIcon(), 'ModHelpIcon');
export const ModHistoryIcon = createSvgIcon(HistoryIcon(), 'ModHistoryIcon');
export const ModEmailIcon = createSvgIcon(EmailIcon(), 'ModEmailIcon');
export const ModImportIcon = createSvgIcon(ImportIcon(), 'ModImportIcon');
export const ModInfoIcon = createSvgIcon(InfoIcon(), 'ModInfoIcon');
export const ModLegendHideIcon = createSvgIcon(
  LegendHideIcon(),
  'ModLegendHideIcon'
);
export const ModLegendShowIcon = createSvgIcon(
  LegendShowIcon(),
  'ModLegendShowIcon'
);
export const ModLinuxIcon = createSvgIcon(LinuxIcon(), 'ModLinuxIcon');

export const ModMarketplaceIcon = createSvgIcon(
  MarketplaceIcon(),
  'ModMarketplaceIcon'
);
export const ModMinusIcon = createSvgIcon(MinusIcon(), 'ModMinusIcon');
export const ModMinusFilledIcon = createSvgIcon(
  MinusFilledIcon(),
  'ModMinusFilledIcon'
);

export const ModEditIcon = createSvgIcon(EditIcon(), 'ModEditIcon');
export const ModEllipsisIcon = createSvgIcon(EllipsisIcon(), 'ModEllipsisIcon');

export const ModMoonIcon = createSvgIcon(MoonIcon(), 'ModMoonIcon');

export const ModOrganizationsIcon = createSvgIcon(
  OrganizationsIcon(),
  'ModOrganizationsIcon'
);

export const ModQuarantinedIcon = createSvgIcon(
  QuarantinedIcon(),
  'ModQuarantinedIcon'
);

export const ModRainyIcon = createSvgIcon(RainyIcon(), 'ModRainyIcon');

export const ModRecommendedSortIcon = createSvgIcon(
  RecommendedSortIcon(),
  'ModRecommendedSortIcon'
);
export const ModReloadIcon = createSvgIcon(ReloadIcon(), 'ModReloadIcon');

export const ModRepositoryIcon = createSvgIcon(
  RepositoryIcon(),
  'ModRepositoryIcon'
);

export const ModSaveFloppyIcon = createSvgIcon(
  SaveFloppyIcon(),
  'ModSaveFloppyIcon'
);
export const ModSaveToBrowser = createSvgIcon(
  SaveToBrowser(),
  'ModSaveToBrowser'
);
export const ModSearchIcon = createSvgIcon(SearchIcon(), 'ModSearchIcon');

export const ModSettingsIcon = createSvgIcon(SettingsIcon(), 'ModSettingsIcon');
export const ModShareIcon = createSvgIcon(ShareIcon(), 'ModShareIcon');
export const ModSlackIcon = createSvgIcon(SlackIcon(), 'ModSlackIcon');
export const ModSortAZAlphaIcon = createSvgIcon(
  SortAZAlphaIcon(),
  'ModSortAZAlphaIcon'
);
export const ModSortZAAlphaIcon = createSvgIcon(
  SortZAAlphaIcon(),
  'ModSortZAAlphaIcon'
);

export const ModStopCircleIcon = createSvgIcon(
  StopCircleIcon(),
  'ModStopCircleIcon'
);

export const ModSunnyIcon = createSvgIcon(SunnyIcon(), 'ModSunnyIcon');
export const ModSunnySpellsIcon = createSvgIcon(
  SunnySpellsIcon(),
  'ModSunnySpellsIcon'
);

export const ModUploadIcon = createSvgIcon(UploadIcon(), 'ModUploadIcon');

export const ModWarningIcon = createSvgIcon(WarningIcon(), 'ModWarningIcon');
export const ModWindowsIcon = createSvgIcon(WindowsIcon(), 'ModWindowsIcon');
export const ModWorkerIcon = createSvgIcon(WorkerIcon(), 'ModWorkerIcon');

export const ModCreateNewIcon = createSvgIcon(
  CreateNewIcon(),
  'ModCreateNewIcon'
);

export const ModTagIcon = createSvgIcon(TagIcon(), 'ModTagIcon');

export const ModScrollWheelIcon = createSvgIcon(
  ScrollWheelIcon(),
  'ModScrollWheelIcon'
);
export const ModRightClickPanIcon = createSvgIcon(
  RightClickPanIcon(),
  'ModScrollWheelIcon'
);
export const ModLeftClickRotateIcon = createSvgIcon(
  LeftClickRotateIcon(),
  'ModScrollWheelIcon'
);

export const ModUndoIcon = createSvgIcon(UndoIcon(), 'ModUndoIcon');

export const ModAddColumnLeftIcon = createSvgIcon(
  AddColumnLeftIcon(),
  'AddColumnLeft'
);
export const ModAddColumnRightIcon = createSvgIcon(
  AddColumnRightIcon(),
  'AddColumnRight'
);

export const ModLinkIcon = createSvgIcon(LinkIcon(), 'LinkIcon');
export const ModUnlinkIcon = createSvgIcon(UnlinkIcon(), 'UnlinkIcon');

export const ModModerneLogoIcon = createSvgIcon(
  ModerneLogo(),
  'ModerneLogoIcon'
);

export const ModBatchChangesIcon = createSvgIcon(
  BatchChangesIcon(),
  'ModBatchChangesIcon'
);
