import type { FunctionComponent } from 'react';

import { CopyConfirmedIcon, CopyIcon } from '../../../icons/icons';

type ClipBoardIconProps = {
  isCopied: boolean;
};

export const ClipBoardIcon: FunctionComponent<ClipBoardIconProps> = ({
  isCopied
}) => {
  return !isCopied ? (
    <CopyIcon fontSize="inherit" />
  ) : (
    <CopyConfirmedIcon fontSize="inherit" color="success" />
  );
};
