import kebabcase from 'lodash.kebabcase';

import type { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import {
  Dialog,
  type DialogProps,
  ListItem,
  ListItemButton,
  type ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  type MenuProps,
  Popover,
  Tooltip
} from '@mui/material';
import {
  type PopupState,
  bindDialog,
  bindPopover,
  bindTrigger
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { NavItem } from '../left-navigation/left-navigation.component';

type MenuItemWithSubMenuProps = {
  selected?: boolean;
  popoverState: PopupState;
  menuItem: NavItem;
  slotProps?: {
    menuProps?: Partial<MenuProps>;
    listItemButtonProps?: Partial<ListItemButtonProps>;
  };
};

export const MenuItemWithSubMenu: FunctionComponentWithChildren<
  MenuItemWithSubMenuProps
> = ({ menuItem, popoverState, slotProps, children, selected }) => {
  return (
    <React.Fragment>
      <Tooltip title={menuItem.description} placement="right" arrow>
        <ListItem>
          <ListItemButton
            selected={selected}
            data-testid={`global-nav-${kebabcase(menuItem.label)}-button`}
            {...bindTrigger(popoverState)}
            {...slotProps?.listItemButtonProps}
          >
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
            <ListItemText primary={menuItem.label} />
          </ListItemButton>
        </ListItem>
      </Tooltip>

      <Popover
        {...bindPopover(popoverState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        {...slotProps?.menuProps}
      >
        {children}
      </Popover>
    </React.Fragment>
  );
};
// Generated by Copilot
type MenuItemWithDialogProps = {
  selected?: boolean;
  popoverState: PopupState;
  menuItem: NavItem;
  slotProps?: {
    dialog: Partial<DialogProps>;
  };
};

export const MenuItemWithDialog: FunctionComponentWithChildren<
  MenuItemWithDialogProps
> = ({ menuItem, popoverState, slotProps, children, selected }) => {
  return (
    <React.Fragment>
      <Tooltip title={menuItem.description} placement="right" arrow>
        <ListItem>
          <ListItemButton
            selected={selected}
            data-testid={`global-nav-${kebabcase(menuItem.label)}-button`}
            {...bindTrigger(popoverState)}
          >
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
            <ListItemText primary={menuItem.label} />
          </ListItemButton>
        </ListItem>
      </Tooltip>

      <Dialog {...bindDialog(popoverState)} {...slotProps?.dialog}>
        {children}
      </Dialog>
    </React.Fragment>
  );
};
