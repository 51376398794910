import { GlobalKeysContext } from '@bonsai-components/react-global-keys';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { type FunctionComponent, useContext, useEffect, useRef } from 'react';

import { UserContext } from '../../../contexts/user.context';
import { excludeActionFromInputMode } from '../../../hooks/use-command-palette.hooks';
import { SearchIcon } from '../../../icons/icons';
import { AutoCompleteSearch } from '../../global-search/global-search.component';
import { searchCommandShortcutSettings } from '../../settings/manage-appearance/keyboard-shortcuts-settings';
import { MenuItemWithSubMenu } from './menu-with-sub-menu.component';

export const SearchMenuItem: FunctionComponent = () => {
  const popoverState = usePopupState({
    variant: 'popover',
    popupId: 'global-search'
  });

  const menuRef = useRef<HTMLDivElement>(null);

  const {
    preferences: { searchMenuShortcut }
  } = useContext(UserContext);

  const { addKeyBinding } = useContext(GlobalKeysContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run once on mount
  useEffect(() => {
    const shortcutSetting = searchCommandShortcutSettings[searchMenuShortcut];

    shortcutSetting?.bindings.forEach((binding) => {
      addKeyBinding({
        ...binding,
        action: (originalEvent) =>
          excludeActionFromInputMode(originalEvent, (event) => {
            originalEvent.preventDefault();
            originalEvent.stopPropagation();
            popoverState.setAnchorEl(menuRef.current);
            popoverState.open(event);
          })
      });
    });
  }, []);

  return (
    <MenuItemWithSubMenu
      popoverState={popoverState}
      menuItem={{
        icon: <SearchIcon />,
        label: 'Search',
        description: 'Search for recipes'
      }}
      slotProps={{
        listItemButtonProps: {
          ref: menuRef
        },
        menuProps: {
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          slotProps: {
            paper: {
              sx: {
                boxShadow: (theme) => theme.shadows[10],
                maxWidth: { xs: '90vw', md: '70vw' }
              }
            }
          }
        }
      }}
    >
      <AutoCompleteSearch toggleMenu={popoverState.close} />
    </MenuItemWithSubMenu>
  );
};
