import {
  FormControlLabel,
  type FormControlLabelProps,
  Tooltip,
  formControlLabelClasses,
  styled
} from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-pro';

import type { ReactNode } from 'react';
import { ResponsiveDataGrid } from '../../data-grid/responsive-data-grid/responsive-data-grid.component';

export const ModerneDataGrid = styled(ResponsiveDataGrid)(({ theme }) => ({
  [`& .${gridClasses.cell}`]: {
    wordBreak: 'break-word',
    hyphens: 'auto'
  },
  [`& .monospace .${gridClasses.cell}, & .monospace.${gridClasses.cell}`]: {
    fontFamily: theme.typography.fontFamilyMonospace
  },
  [theme.breakpoints.down('lg')]: {
    [`& .${gridClasses.row}:not(.${gridClasses['row--dynamicHeight']})>.${gridClasses.cell}`]:
      {
        overflow: 'inherit',
        whiteSpace: 'break-spaces'
      },
    [`& .${gridClasses.cell}`]: {
      overflow: 'unset',
      textOverflow: 'unset'
    }
  }
}));

export const ResultsDataGrid = styled(ModerneDataGrid)(({ theme }) => ({
  // fix offset of header checkbox with result status border
  [`& .${gridClasses['columnHeader--pinnedLeft']}:first-of-type`]: {
    paddingLeft: 8
  },
  [`& .${gridClasses['columnHeader--withRightBorder']}`]: {
    marginLeft: 4
  },
  [`& .${gridClasses['filler--pinnedLeft']}`]: {
    marginLeft: 4
  },
  [`& .${gridClasses['columnHeader--pinnedRight']} .${gridClasses.row}`]: {
    borderStyle: 'unset'
  },
  [`& .${gridClasses.row}`]: {
    border: 0,
    borderLeft: 4,
    borderStyle: 'solid',
    borderColor: 'white'
  },
  ['& .status-canceled']: {
    borderColor: theme.palette.grey[600]
  },
  ['& .status-created']: {
    borderColor: theme.palette.yellow[400]
  },
  ['& .status-error, & .status-failed']: {
    borderColor: theme.palette.error.main
  },
  ['& .status-finished, & .status-success, & .status-successful']: {
    borderColor: theme.palette.success.main
  },
  ['& .status-loading']: {
    borderColor: theme.palette.blue[600]
  },
  ['& .status-queued']: {
    borderColor: theme.palette.yellow[400]
  },
  ['& .status-running']: {
    borderColor: theme.palette.blue[600]
  },
  ['& .status-timeout']: {
    borderColor: theme.palette.grey[600]
  },
  ['& .status-unavailable']: {
    borderColor: theme.palette.grey[600]
  },
  ['& .status-yielded']: {
    borderColor: theme.palette.blue[600]
  },
  ['& .status-no-lst']: {
    borderColor: theme.palette.grey[600]
  }
}));

export const GridToolbarSwitch = styled(
  (props: FormControlLabelProps & { tooltip?: ReactNode | string }) =>
    props.tooltip ? (
      <Tooltip title={props.tooltip}>
        <FormControlLabel
          slotProps={{
            typography: {
              variant: 'button',
              color: 'secondary'
            }
          }}
          {...props}
        />
      </Tooltip>
    ) : (
      <FormControlLabel
        slotProps={{
          typography: {
            variant: 'button',
            color: 'secondary'
          }
        }}
        {...props}
      />
    )
)(({ theme }) => ({
  [`& .${formControlLabelClasses.label}`]: {
    fontSize: theme.typography.pxToRem(13),
    whiteSpace: 'nowrap'
  }
}));
