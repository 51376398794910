import { usePopupState } from 'material-ui-popup-state/hooks';
import { FunctionComponent } from 'react';
import { useCommandPalette } from '../../../hooks/use-command-palette.hooks';
import { useSelectedOrganization } from '../../../hooks/use-organizations.hooks';
import { OrganizationIcon } from '../../../icons/icons';
import { OrganizationsTable } from '../../organizations/organizations.table.component';
import { MenuItemWithDialog } from './menu-with-sub-menu.component';

export const OrganizationSelectorMenuItem: FunctionComponent = () => {
  const { selectedOrganization } = useSelectedOrganization();

  const popoverState = usePopupState({
    variant: 'popover',
    popupId: 'organization-selector'
  });

  useCommandPalette([
    {
      label: 'Open organization selector',
      action: (e) => {
        e.stopPropagation();
        e.preventDefault();
        popoverState.open();
      },
      keyboardShortcuts: {
        key: 'o'
      }
    }
  ]);

  return (
    <MenuItemWithDialog
      popoverState={popoverState}
      menuItem={{
        icon: <OrganizationIcon />,
        label: 'Organization',
        description: selectedOrganization?.name ?? 'Select an organization'
      }}
      slotProps={{
        dialog: {
          fullWidth: true,
          maxWidth: 'lg'
        }
      }}
    >
      <OrganizationsTable onClose={popoverState.close} />
    </MenuItemWithDialog>
  );
};
