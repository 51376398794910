import { SvgIcon } from '@mui/material';
import {
  GridActionsCellItem,
  type GridActionsColDef
} from '@mui/x-data-grid-pro';

import type { PureFunction } from '@bonsai-components/utility-types';
import { actionsGridCol } from '../../helpers/data-grid.helper';
import {
  ConfigureIcon,
  DeleteIcon,
  DownloadIcon,
  DuplicateIcon
} from '../../icons/icons';
import {
  MY_ORGANIZATIONS_GROUPING,
  type OrganizationRowData
} from './organizations.rows';

export type OrganizationActionColumnProps = {
  copyOrganization: (orgRowData: OrganizationRowData) => void;
  editOrganization: (orgRowData: OrganizationRowData) => void;
  removeOrganizationModal: (
    orgRowData: OrganizationRowData,
    description: string
  ) => () => void;
  exportOrganization: (name: string) => void;
};

export const organizationActionColumn: PureFunction<
  OrganizationActionColumnProps,
  GridActionsColDef<OrganizationRowData>
> = ({
  copyOrganization,
  editOrganization,
  removeOrganizationModal,
  exportOrganization
}) => ({
  field: 'actions',
  ...actionsGridCol,
  width: 40,
  minWidth: 40,
  resizable: false,
  renderHeader: () => null,
  getActions: ({ row }) => {
    if (row.id === MY_ORGANIZATIONS_GROUPING || !row.isUserOrganization) {
      return [];
    }
    return [
      <GridActionsCellItem
        showInMenu
        key={`update-${row.id}`}
        title="Update organization"
        icon={<SvgIcon component={ConfigureIcon} inheritViewBox />}
        onClick={() => editOrganization(row)}
        label="Update organization"
      />,
      <GridActionsCellItem
        showInMenu
        key={`clone-${row.id}`}
        title="Copy user organization"
        icon={<SvgIcon component={DuplicateIcon} />}
        onClick={() => copyOrganization(row)}
        label="Copy user organization"
      />,
      <GridActionsCellItem
        showInMenu
        key={`delete-${row.id}`}
        title="Delete user organization"
        icon={
          <SvgIcon
            component={DeleteIcon}
            inheritViewBox
            sx={{
              'li:hover &': {
                color: 'red.500'
              }
            }}
          />
        }
        onClick={removeOrganizationModal(row, row.description)}
        label="Delete user organization"
      />,
      <GridActionsCellItem
        showInMenu
        key={`update-${row.id}`}
        title="Export"
        icon={<SvgIcon component={DownloadIcon} inheritViewBox />}
        onClick={() => exportOrganization(row.id)}
        label="Export user organization"
        disabled={row?.id?.toLowerCase() === 'all'}
      />
    ];
  }
});
