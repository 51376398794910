import { metaKeyInBrowser } from '@bonsai-components/meta-key';
import type { FunctionComponent } from 'react';

import {
  type CommandWithKeyBinding,
  isCommandWithKeyBindingWithKey
} from '../../contexts/command-palette.context';
import { KeyStroke } from '../styled-components/typography/typography.styled';

export const KeyboardShortcuts: FunctionComponent<{
  shortcuts: CommandWithKeyBinding | CommandWithKeyBinding[];
}> = ({ shortcuts }) => {
  return Array.isArray(shortcuts) ? (
    shortcuts
      .map((shortcut, index) => (
        <KeyboardShortcut key={index} shortcut={shortcut} />
      ))
      .reduce(
        (acc, kbd) =>
          acc === null ? (
            kbd
          ) : (
            <>
              {acc} or {kbd}
            </>
          ),
        null
      )
  ) : (
    <KeyboardShortcut shortcut={shortcuts} />
  );
};

export const KeyboardShortcut: FunctionComponent<{
  shortcut: CommandWithKeyBinding;
}> = ({ shortcut }) => {
  return (
    <>
      {shortcut?.modifier?.meta && (
        <KeyStroke>{metaKeyInBrowser({ windows: 'ctrl' })}</KeyStroke>
      )}
      {shortcut?.modifier?.shift && <KeyStroke>shift</KeyStroke>}
      {shortcut?.modifier?.alt && <KeyStroke>alt</KeyStroke>}
      {shortcut?.modifier?.ctrl && <KeyStroke>ctrl</KeyStroke>}
      {isCommandWithKeyBindingWithKey(shortcut) ? (
        shortcut.key === '˚' ? (
          <>
            <KeyStroke>alt</KeyStroke>
            <KeyStroke>k</KeyStroke>
          </>
        ) : (
          <KeyStroke>{shortcut.key}</KeyStroke>
        )
      ) : (
        <KeyStroke>{shortcut.code.replace('Key', '')}</KeyStroke>
      )}
    </>
  );
};
