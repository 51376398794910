import { useSession } from 'next-auth/react';
import { type FunctionComponent, useCallback } from 'react';

import { Box, Typography } from '@mui/material';

import { getAvatarSource } from '../../helpers/avatar.helper';
import { ScmConnections } from '../scm-connection-status/scm-connection-status.component';
import { ModerneUserAvatar } from '../styled-components/avatars/avatars.styled';
import { FlexBox } from '../styled-components/layouts/layouts.styled';

export const UserCard: FunctionComponent = () => {
  const { data: session } = useSession();
  const avatarSource = getAvatarSource(session);
  const avatarInitials = (
    session?.user?.fullName || session?.user?.userName
  )?.charAt(0);

  const isUserNameUnique = useCallback(() => {
    if (
      session?.user?.email &&
      session?.user?.userName &&
      session?.user?.fullName
    ) {
      const sameAsEmail =
        session.user.email.trim().toLocaleLowerCase() ===
        session.user.userName.trim().toLocaleLowerCase();

      const sameAsFullName =
        session.user.fullName.trim().toLocaleLowerCase() ===
        session.user.userName.trim().toLocaleLowerCase();

      return !(sameAsEmail || sameAsFullName);
    }
  }, [session]);

  return (
    <Box
      sx={{
        margin: 2,
        marginBottom: 1,
        width: '100%'
      }}
    >
      <FlexBox
        sx={{
          alignItems: 'start'
        }}
      >
        <ModerneUserAvatar
          alt={session.user.userName}
          src={avatarSource}
          sx={{
            width: 45,
            height: 45
          }}
        >
          {avatarInitials}
        </ModerneUserAvatar>

        <FlexBox
          sx={{
            flexDirection: 'column',
            marginLeft: 1.5
          }}
        >
          <Typography
            variant="body"
            sx={{
              fontWeight: '600',
              display: 'inline-block',
              lineHeight: 1
            }}
          >
            {session.user.fullName}
          </Typography>

          <Typography
            variant="caption"
            sx={{
              whiteSpace: 'nowrap',
              marginTop: 0.2,
              lineHeight: 1.1,
              color: (theme) => theme.palette.grey[600]
            }}
          >
            {session.user.email}
          </Typography>
          {isUserNameUnique() && (
            <Typography
              variant="caption"
              sx={{
                whiteSpace: 'nowrap',
                lineHeight: 1.1,
                color: (theme) => theme.palette.grey[600]
              }}
            >
              {session.user.userName}
            </Typography>
          )}
          <FlexBox
            sx={{
              marginTop: 1
            }}
          >
            <ScmConnections />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Box>
  );
};
