import type { UnknownRecord } from '@bonsai-components/utility-types';
import { Button, type ButtonProps, Popover } from '@mui/material';
import type { DocumentNode } from 'graphql';
import {
  bindPopover,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { type JSX } from 'react';
import { DropDownArrowIcon, GraphQLIcon } from '../../icons/icons';
import { GRAPHQL_PINK } from '../../themes/moderne-theme';
import { FlexBox } from '../styled-components/layouts/layouts.styled';
import {
  GraphQLExplorer,
  type GraphQLExplorerOperationVariables
} from './graphql-explorer.component';

export type QueryExample<T = UnknownRecord> = {
  label: string;
  operationDocument: DocumentNode;
  operationVariables: GraphQLExplorerOperationVariables<T>;
  endpointUrl?: string;
};

type SeeHowToRunGraphQLProps<T = UnknownRecord> = {
  examples?: QueryExample<T>[];
  endpointUrl?: string;
  fullScreen?: boolean;
  buttonProps?: ButtonProps;
};

export const SeeHowToRunGraphQL = <T extends UnknownRecord>({
  endpointUrl,
  fullScreen = false,
  examples = [],
  buttonProps = {}
}: SeeHowToRunGraphQLProps<T>): JSX.Element => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'graphql' });

  if (examples.length === 0) {
    return null;
  }
  return examples.length === 1 ? (
    <GraphQLExplorer
      key={examples.at(0).label}
      graphQLProps={{
        endpointUrl: endpointUrl,
        operationDocument: examples.at(0).operationDocument,
        operationVariables: examples.at(0).operationVariables
      }}
      dialogProps={{
        fullScreen
      }}
      buttonProps={{
        color: 'inherit',
        size: 'small',
        variant: 'outlined',
        startIcon: (
          <GraphQLIcon
            sx={{
              '& *': {
                fill: GRAPHQL_PINK
              }
            }}
          />
        ),
        sx: {
          justifyContent: 'flex-start',
          borderColor: '#CECECE'
        },
        ...buttonProps
      }}
    >
      API example
    </GraphQLExplorer>
  ) : (
    <React.Fragment>
      <Button
        {...bindTrigger(popupState)}
        variant="outlined"
        color="inherit"
        size="small"
        sx={{ borderColor: '#CECECE' }}
        startIcon={
          <GraphQLIcon
            sx={{
              '& *': {
                fill: GRAPHQL_PINK
              }
            }}
          />
        }
        endIcon={<DropDownArrowIcon />}
      >
        API examples
      </Button>
      <Popover {...bindPopover(popupState)}>
        <FlexBox
          sx={{
            flexDirection: 'column',
            alignItems: 'start'
          }}
        >
          {examples.map(
            ({
              label,
              operationDocument,
              operationVariables,
              endpointUrl: exampleEndpointUrl
            }) => (
              <GraphQLExplorer
                key={label}
                graphQLProps={{
                  endpointUrl: exampleEndpointUrl ?? endpointUrl,
                  operationDocument,
                  operationVariables
                }}
                dialogProps={{
                  fullScreen
                }}
                buttonProps={{
                  color: 'inherit',
                  fullWidth: true,
                  sx: {
                    justifyContent: 'flex-start'
                  },
                  ...buttonProps
                }}
              >
                {label}
              </GraphQLExplorer>
            )
          )}
        </FlexBox>
      </Popover>
    </React.Fragment>
  );
};
