import { Box, type BoxProps, Paper, type PaperProps } from '@mui/material';
import type { FunctionComponent } from 'react';
import { TOP_BANNER_HEIGHT } from '../../constants/general';

export const ModernePaperBox: FunctionComponent<BoxProps> = ({
  children,
  m = 2,
  ...rest
}) => (
  <Paper variant="outlined">
    <Box
      {...rest}
      sx={[
        {
          m: m
        },
        ...(Array.isArray(rest.sx) ? rest.sx : [rest.sx])
      ]}
    >
      {children}
    </Box>
  </Paper>
);

export const StickyPaper: FunctionComponent<
  { top?: number | number[] } & PaperProps
> = ({ children, sx, top = TOP_BANNER_HEIGHT }) => {
  return (
    <Paper
      sx={{
        position: 'sticky',
        zIndex: (theme) => theme.zIndex.appBar,
        marginBottom: 1,
        top,
        ...sx
      }}
    >
      {children}
    </Paper>
  );
};

export const StickyBox: FunctionComponent<
  { top?: number | number[] } & BoxProps
> = ({ children, sx, top = TOP_BANNER_HEIGHT, ...rest }) => {
  return (
    <Box
      sx={{
        position: 'sticky',
        zIndex: (theme) => theme.zIndex.appBar,
        marginBottom: 1,
        top,
        ...sx
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
