import { ListItemIcon, Stack, Typography } from '@mui/material';
import type { FunctionComponent } from 'react';
import { CategoryImageSelector } from '../categories/category-image-selector/category-image-selector.component';
import { ModerneMarkdown } from '../utilities/markdown/markdown.component';

type RecipeSearchResultProps = {
  id: string;
  name: string;
};

/**
 * Renders a compact version of a recipe search result that is intended to be
 * rendered inside of a ListItemButton
 */
export const CompactRecipeSearchResult: FunctionComponent<
  RecipeSearchResultProps
> = ({ id, name }) => {
  return (
    <>
      <ListItemIcon sx={{ minWidth: 22, paddingRight: 1 }}>
        <CategoryImageSelector
          name={id}
          height={'1.2em'}
          shadow={false}
          transparentBackground
        />
      </ListItemIcon>
      <Stack
        direction="row"
        sx={{
          gap: 0.5,
          alignItems: 'center',
          flexGrow: 1
        }}
      >
        <Stack
          direction={'row'}
          sx={{
            alignItems: 'center',
            gap: 1
          }}
        >
          <Typography
            variant="bodySm"
            sx={{
              whiteSpace: 'break-spaces'
            }}
          >
            <ModerneMarkdown disableCodeFontColor>{name}</ModerneMarkdown>
          </Typography>
        </Stack>
        <Typography
          variant="bodySm"
          sx={{
            color: 'textSecondary'
          }}
        >
          ({id.split('.').at(2)})
        </Typography>
      </Stack>
    </>
  );
};
