import { ConfirmProvider } from 'material-ui-confirm';
import { SessionProvider, useSession } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import { useRouter } from 'next/router';
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  type FunctionComponent
} from 'react';

import { ApolloProvider } from '@apollo/client';
import { GlobalKeysProvider } from '@bonsai-components/react-global-keys';
import { Box, Container, CssBaseline, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';

import { DeploymentBannerWithAuth } from '../components/deployment-banner/deployment-banner.component';
import '../components/graphql-explorer/global-overrides.css';
import { LeftNavigation } from '../components/navigation/left-navigation/left-navigation.component';
import { SiteMetaData } from '../components/site-meta-data/site-meta-data.component';
import type { GeneralSiteStatusResponse } from '../components/site-status/site-status-types';
import { FlexBox } from '../components/styled-components/layouts/layouts.styled';
import { AuthorizationControl } from '../components/utilities/authorization/authorization.component';
import { ClientSideOnly } from '../components/utilities/client-side-render/client-only.component';
import { AuthErrorBoundary } from '../components/utilities/error-boundary/auth-error-boundary.component';
import { PageErrorBoundary } from '../components/utilities/error-boundary/page-error-boundary.component';
import { UpdateAgent } from '../components/utilities/update-agent/update-agent.component';
import { WithAdministrative } from '../components/with-administrative/with-administrative.component';
import { defaultConfirmationProps } from '../constants/confirmation';
import { NAVIGATION_RAIL_WIDTH } from '../constants/general';
import { KNOWN_SIGN_IN_ERRORS } from '../constants/messages';
import { CommandPaletteProvider } from '../contexts/command-palette.context';
import {
  ConfigurationContext,
  ConfigurationProvider
} from '../contexts/config.context';
import { DownloadsProvider } from '../contexts/downloads.context';
import { JobNotificationProvider } from '../contexts/job-notification.context';
import {
  NotificationContext,
  NotificationProvider
} from '../contexts/notification.context';
import { UserProvider } from '../contexts/user.context';
import { createApolloClient } from '../helpers/apollo-client.helper';
import { REFRESH_TOKEN_EXPIRED } from '../helpers/keycloak.helper';
import { signInWithError } from '../helpers/next.helpers';
import { useFetch } from '../hooks/use-fetch.hooks';
import { useBuilderStore } from '../stores/builder.store';
import { useRecentRecipeStore } from '../stores/recent-recipe.store';
import { useThirdPartyIntegrationStore } from '../stores/third-party-integrations.store';
import { useUserPreferenceStore } from '../stores/user-preference.store';
import { createModerneTheme } from '../themes/moderne-theme';

LicenseInfo.setLicenseKey(
  '1010e77abbeab7404659012aff517b64Tz05NzA4MyxFPTE3NTY0ODMzNTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
);

const inter = Inter({
  subsets: ['latin'],
  display: 'auto'
});

/**
 * Main application component.
 * Used to enforce session management, authentication controls,
 * and other contexts.
 *
 */
function ModerneApp(props: AppProps) {
  const { Component, router } = props;
  const isAuthenticationPage = router.pathname.includes('/auth');
  const isStatusPage = router.pathname.startsWith('/status');
  const theme = createModerneTheme({
    customFontFamily: inter.style.fontFamily
  });
  const { initializeStore } = useRecentRecipeStore();

  useEffect(() => {
    initializeStore();
  }, [initializeStore]);

  if (isAuthenticationPage || isStatusPage) {
    return (
      <ConfigurationProvider pathname={router.pathname}>
        <SiteMetaData />
        <ClientSideOnly>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <FlexBox>
              <Container disableGutters maxWidth={false}>
                <Component {...props} />
              </Container>
            </FlexBox>
          </ThemeProvider>
        </ClientSideOnly>
      </ConfigurationProvider>
    );
  } else {
    return (
      <AuthErrorBoundary>
        <ConfigurationProvider pathname={router.pathname}>
          <SessionProvider
            // Refetch session every 10 minutes
            refetchInterval={60 * 10}
            // Do not fetch new sessions when tab gains focus
            // Seems to cause /api/auth/session to be called twice for some reason
            // TODO: Revisit this setting relative to the similar document visibility handling we've added
            refetchOnWindowFocus={false}
            // Do not refetch when offline.
            // This should avoid intermittent network issues causing the app to log out
            refetchWhenOffline={false}
          >
            <ClientSideOnly>
              <SiteMetaData />
              <UserProvider>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <NotificationProvider>
                    <AuthorizedModerneApp {...props} />
                  </NotificationProvider>
                </ThemeProvider>
              </UserProvider>
            </ClientSideOnly>
          </SessionProvider>
        </ConfigurationProvider>
      </AuthErrorBoundary>
    );
  }
}

/**
 * Components in this component are either responsible for handling
 * authentication or authorization based on the session or depend upon a
 * successfully authenticated / authorized user to render.
 */
const AuthorizedModerneApp: FunctionComponent<AppProps> = ({
  Component,
  pageProps
}) => {
  const router = useRouter();
  const { loadIntegrationsFromIndexedDB } = useThirdPartyIntegrationStore();
  const { fetchCustomRecipes } = useBuilderStore();
  const { data: session } = useSession();
  const [isVisible, setIsVisible] = useState<boolean | undefined>(undefined);
  const { getGraphQlUrl } = useContext(ConfigurationContext);
  const { renderNotification } = useContext(NotificationContext);
  const { disableKeyboardShortcuts } = useUserPreferenceStore();
  const client = useMemo(
    () =>
      createApolloClient({
        accessToken: String(session?.accessToken),
        apiGatewayUrl: getGraphQlUrl(),
        showError: renderNotification
      }),
    [session?.accessToken, getGraphQlUrl, renderNotification]
  );

  const handleVisibilityChange = () => {
    setIsVisible(document.visibilityState === 'visible');
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: run once on mount
  useEffect(() => {
    loadIntegrationsFromIndexedDB();
    fetchCustomRecipes();
    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false
    );
    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  const { data } = useFetch<GeneralSiteStatusResponse>('/api/status');

  let { deployingServices = false } = data || {};
  deployingServices =
    router.query.showDeploymentBanner === 'true' ? true : deployingServices;

  // @see https://next-auth.js.org/v3/tutorials/refresh-token-rotation#client-side
  useEffect(() => {
    if (isVisible === undefined) {
      return;
    }

    const sessionInvalid =
      !session?.accessToken || session?.error === REFRESH_TOKEN_EXPIRED;

    if (isVisible && sessionInvalid && router.isReady) {
      return signInWithError(
        KNOWN_SIGN_IN_ERRORS.sessionExpired,
        router.asPath,
        'sessionExpiredAppVisible'
      );
    }
  }, [session, isVisible, router, router.isReady, router.asPath]);

  /**
   * Conditionally render the application based on the session.
   * If there's no session with an access token, we will be unable to issue
   * any GraphQL requests.
   */
  return session?.accessToken ? (
    <ApolloProvider client={client}>
      <PageErrorBoundary key={Component.name}>
        <AuthorizationControl>
          <JobNotificationProvider>
            <ConfirmProvider defaultOptions={defaultConfirmationProps}>
              <DownloadsProvider>
                <GlobalKeysProvider
                  useCtrlAsMetaAlternative
                  disableKeybindings={disableKeyboardShortcuts}
                >
                  <CommandPaletteProvider>
                    <React.Fragment>
                      {deployingServices ? (
                        <DeploymentBannerWithAuth />
                      ) : undefined}
                      <Box
                        id="moderne-app"
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: `${NAVIGATION_RAIL_WIDTH}px 1fr`,
                          gridTemplateAreas: '"global-nav content"',
                          overflow: 'hidden'
                        }}
                      >
                        <Box
                          style={{
                            gridArea: 'global-nav'
                          }}
                        >
                          <LeftNavigation />
                        </Box>
                        <Box
                          style={{
                            gridArea: 'content'
                          }}
                        >
                          <PageErrorBoundary key={Component.name}>
                            <Component {...pageProps} />
                          </PageErrorBoundary>
                        </Box>
                      </Box>

                      <WithAdministrative suppressWarning>
                        <UpdateAgent />
                      </WithAdministrative>
                    </React.Fragment>
                  </CommandPaletteProvider>
                </GlobalKeysProvider>
              </DownloadsProvider>
            </ConfirmProvider>
          </JobNotificationProvider>
        </AuthorizationControl>
      </PageErrorBoundary>
    </ApolloProvider>
  ) : null;
};

export default ModerneApp;
