import type { PureFunction } from '@bonsai-components/utility-types';
import type { RecipeDetailsFragment } from '../__generated__/apollo-hooks';
import { isValidOptionValue } from './recipe.helper';

type RecipeWithOptions = Pick<RecipeDetailsFragment, 'id' | 'name' | 'options'>;

export const mapRecipeDetailsFragmentToDisplay: PureFunction<
  RecipeWithOptions,
  string | Record<string, Record<string, string | number | boolean | string[]>>
> = (recipe) => {
  const hasOpts = recipe?.options?.length > 0;
  if (hasOpts) {
    const validOptions = Object.fromEntries(
      recipe.options
        .filter(isValidOptionValue)
        .map(({ name, value }) => [name, value])
    );
    if (Object.keys(validOptions)?.length === 0) {
      return recipe?.id;
    } else {
      return {
        [recipe.id]: validOptions
      };
    }
  } else {
    return recipe?.id;
  }
};
