import type { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import React from 'react';

import { useAuthorization } from '../../hooks/use-authorization.hooks';
import { EmptyState } from '../utilities/empty-state/empty-state.component';

export const WithAdministrative: FunctionComponentWithChildren<{
  suppressWarning?: boolean;
}> = ({ children, suppressWarning = false }) => {
  const { isAdministrator } = useAuthorization();

  return !isAdministrator ? (
    <React.Fragment>
      {!suppressWarning ? (
        <EmptyState variant={'warning'}>
          You do not have sufficient permissions to view the contents of this
          page.
        </EmptyState>
      ) : null}
    </React.Fragment>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};
