import type { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/**
 * Wrapper component for enforcing client-side rendering of children
 *
 * @see https://www.apollographql.com/blog/getting-started-with-apollo-client-in-next-js/
 */
export const ClientSideOnly: FunctionComponentWithChildren = ({
  children,
  ...delegated
}) => {
  const { isReady } = useRouter();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(isReady);
  }, [isReady]);

  if (!hasMounted) {
    return null;
  }

  return <div {...delegated}>{children}</div>;
};
