import { Filter } from '@mui/icons-material';
import type { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Autocomplete, Box, Button, Stack, TextField } from '@mui/material';

import { useCloneOrganizationFromQuery } from '../../../__generated__/apollo-hooks';
import { MAX_TEXT_FIELD_CHAR_LENGTH } from '../../../constants/forms';
import { REQUIRED_FIELD_ERROR } from '../../../constants/messages';
import { selectTextOnFocus } from '../../../helpers/form.helper';
import { useCommonBreakpoints } from '../../../hooks/use-breakpoints.hooks';
import { useFocusFirstFocusable } from '../../../hooks/use-focus-first-focusable.hooks';
import { FlexBox } from '../../styled-components/layouts/layouts.styled';
import type { UserOrganizationFormValues } from './user-organization-editor.component';

export const UserOrganizationForm: FunctionComponent<{
  showCopyFrom: boolean;
}> = ({ showCopyFrom = true }) => {
  const { isMobileOrTablet } = useCommonBreakpoints();
  const focusContainerRef = useFocusFirstFocusable();

  return (
    <Box
      ref={focusContainerRef}
      sx={{
        maxWidth: 'sm'
      }}
    >
      <Stack
        direction={'column'}
        sx={{
          rowGap: 1
        }}
      >
        <UserOrganizationFormName />
        <UserOrganizationFormDescription />

        {showCopyFrom && <UserOrganizationFormCloneFrom />}
        {isMobileOrTablet && (
          <FlexBox sx={{ justifyContent: 'end' }}>
            <Button
              startIcon={<Filter />}
              size="small"
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </FlexBox>
        )}
      </Stack>
    </Box>
  );
};

const UserOrganizationFormName: FunctionComponent = () => {
  const {
    register,
    getValues,
    formState: { errors }
  } = useFormContext<UserOrganizationFormValues>();

  return (
    <TextField
      autoComplete="off"
      {...register('name', {
        required: {
          value: true,
          message: REQUIRED_FIELD_ERROR
        },
        maxLength: {
          value: MAX_TEXT_FIELD_CHAR_LENGTH,
          message: `Name must be ${MAX_TEXT_FIELD_CHAR_LENGTH} characters or less.`
        }
      })}
      onFocus={selectTextOnFocus}
      error={Boolean(errors?.name?.message)}
      margin="dense"
      label="Name"
      type="text"
      required
      fullWidth
      variant="outlined"
      helperText={errors?.name?.message ?? ''}
      slotProps={{
        inputLabel: {
          shrink: Boolean(getValues('name'))
        }
      }}
    />
  );
};

const MAX_ORG_DESCRIPTION_LENGTH = MAX_TEXT_FIELD_CHAR_LENGTH * 4;

const UserOrganizationFormDescription: FunctionComponent = () => {
  const {
    register,
    getValues,
    formState: { errors }
  } = useFormContext<UserOrganizationFormValues>();

  return (
    <TextField
      {...register('description', {
        maxLength: {
          value: MAX_ORG_DESCRIPTION_LENGTH,
          message: `Description must be ${MAX_ORG_DESCRIPTION_LENGTH} characters or less.`
        }
      })}
      onFocus={selectTextOnFocus}
      error={Boolean(errors?.description)}
      margin="dense"
      label="Description"
      type="text"
      fullWidth
      multiline
      rows={2}
      variant="outlined"
      helperText={errors?.description?.message || ''}
      slotProps={{
        inputLabel: {
          shrink: Boolean(getValues('description'))
        }
      }}
    />
  );
};

const UserOrganizationFormCloneFrom: FunctionComponent = () => {
  const { data } = useCloneOrganizationFromQuery();

  const { field } = useController<UserOrganizationFormValues>({
    name: 'cloneOrganizationId'
  });

  const handleOnChange = (_event, value) => {
    // early return if value is null
    if (!value) {
      field.onChange(null);
      return;
    }
    // match ids to ensure we're only selecting valid organizations
    const org = data?.organizations.find(
      (o) => o.id.toLowerCase() === value.id.toLowerCase()
    );

    field.onChange(org?.id ?? null);
  };

  return (
    <Autocomplete
      options={(data?.organizations ?? [])
        ?.filter((org) => !org.isUserOrganization)
        .map(({ id, name }) => ({
          id,
          label: name
        }))}
      onChange={handleOnChange}
      renderInput={(params) => (
        <TextField {...params} label="Copy from organization" />
      )}
    />
  );
};
